<template>
  <div id="errorPopup">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 primary">
          Internetfehler!
        </v-card-title>

        <v-card-text>
            Es ist ein Fehler aufgetreten, bitte überprüfen Sie Ihre Internetverbindung.
            Unter Umständen müssen Sie die Seite neu laden, um fortfahren zu können.
            Sollte das Problem öfter auftreten bitte prüfen Sie, ob
            Sie eine stabile und schnelle Internetverbindung besitzen.
            <div v-if="data !=undefined">
            <hr>
            Fehlerhaftes Feld: {{data}}
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false; this.$emit('input', false)"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "errorPopup",
  props: ["on", "data"],

  data() {
      return {
          dialog: false
      }  
  },

  watch: {
    on: function (val) {
      this.dialog = val
    },
  }

};
</script>