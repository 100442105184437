<template>
  <div id="tireCondition">
    <v-form ref="tireForm">
      <v-expansion-panels class="pa-0 ma-0" flat :value="panel">
        <v-expansion-panel expand @click="changePlusIcon()">
          <v-expansion-panel-header disable-icon-rotate class="px-9 py-0">
            <div class="statusPreIcon">
              <v-icon v-if="displayPlus" color="secondary"> mdi-plus </v-icon>
              <v-icon v-else color="secondary"> mdi-minus </v-icon>
            </div>
            <h4 height="32px">Statuserfassung</h4>
            <template v-slot:actions>
              <v-icon color="teal" v-if="bIsFinished == true">
                mdi-check
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid class="pa-0 ma-0 px-2">
              <v-row>
                <v-col :cols="12 - calcButtonWidth">
                  <v-text-field
                    label="Reifenname"
                    v-model="model"
                    required
                    :rules="stringRules"
                    :value="model"
                    v-on:change="validateStringAndPush(model, 'model')"
                  ></v-text-field>
                </v-col>
                <v-col :cols="calcButtonWidth" class="pt-5 px-1">
                  <v-btn
                    @click="fetchData(true)"
                    color="primary"
                    block
                    v-if="dataAvailable"
                    >
                    <v-icon>mdi-content-copy</v-icon>

                    <div v-if="calcButtonWidth > 5">
                      Füllen
                    </div>
                    <div v-else-if="calcButtonWidth > 4">
                      {{
                        dataAvailable
                          ? "(" + tireData.location.name.split(" ")[1] + ")"
                          : ""
                      }}
                    </div>
                  </v-btn>
                  <v-btn
                    @click="fetchData(true)"
                    color="primary"
                    block
                    v-else
                  ><v-icon>mdi-content-copy</v-icon></v-btn>
                </v-col>
              </v-row>
              <p class="pb-0 mb-0">Profiltiefe (in mm)</p>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    single-line
                    required
                    :rules="rules"
                    label="außen"
                    v-model="tireProfileOuter"
                    class="pt-0 mt-0"
                    v-on:change="
                      validateBasicAndPush(tireProfileOuter, 'tireProfileOuter')
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    single-line
                    required
                    :rules="rules"
                    label="mittig"
                    v-model="tireProfileMiddle"
                    class="pt-0 mt-0"
                    v-on:change="
                      validateBasicAndPush(
                        tireProfileMiddle,
                        'tireProfileMiddle'
                      )
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    single-line
                    required
                    :rules="rules"
                    label="innen"
                    v-model="tireProfileInner"
                    class="pt-0 mt-0"
                    v-on:change="
                      validateBasicAndPush(tireProfileInner, 'tireProfileInner')
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <p class="pb-0 mb-0">Reifendimension</p>
              <v-row>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    single-line
                    required
                    :rules="rules"
                    v-model="crossSection"
                    class="pt-0 mt-0 px-0"
                    label="Breite"
                    v-on:change="
                      validateBasicAndPush(crossSection, 'crossSection')
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    single-line
                    required
                    :rules="rules"
                    v-model="width"
                    class="pt-0 mt-0 px-0"
                    label="Flanke"
                    v-on:change="validateBasicAndPush(width, 'width')"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="px-1">
                  <v-container fluid class="pa-0">
                    <v-row>
                      <v-col cols="3" class="px-0 mr-n2">
                        <p class="radiuslabel">R</p>
                      </v-col>
                      <v-col cols="8" class="px-0 pb-0">
                        <v-text-field
                          single-line
                          required
                          :rules="rules"
                          v-model="diameter"
                          class="pt-0 mt-0 radiusselect"
                          label="Radius"
                          v-on:change="
                            validateBasicAndPush(diameter, 'diameter')
                          "
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" class="py-0">
                  <p class="pb-0 mb-0">Lastindex</p>
                </v-col>
                <v-col cols="8" class="py-0">
                  <p class="pb-0 mb-0">DOT Nummer</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" class="pt-0">
                  <v-text-field
                    single-line
                    required
                    :rules="rules"
                    v-model="loadIndex"
                    class="pt-0 mt-0"
                    v-on:change="validateBasicAndPush(loadIndex, 'loadIndex')"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-text-field
                    single-line
                    required
                    v-model="dot1"
                    class="pt-0 mt-0"
                    label="Woche"
                    :rules="dotRules"
                    v-on:change="validateDOTAndPush()"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-text-field
                    single-line
                    required
                    v-model="dot2"
                    class="pt-0 mt-0"
                    label="Jahr"
                    :rules="dotRules"
                    v-on:change="validateDOTAndPush()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="pb-0 mb-0">Winterreifen?</p>
                  <v-slider
                    v-model="bIsWinterTires"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="[(v) => v != 0]"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(bIsWinterTires, 'bIsWinterTires')
                    "
                  ></v-slider>
                </v-col>
              </v-row>
            </v-container>
            <v-divider />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>

    <errorPopup :on="errorOn" :data="'Statuserfassung Reifen'" />
  </div>
</template>

<script>
import errorPopup from "../ErrorPopup.vue";

const axios = require("axios");

export default {
  name: "tireCondition",
  props: ["partGroupID", "refetch"],

  data() {
    return {
      errorOn: false,

      needRefetch: this.refetch,
      dataAvailable: false,
      availableLoc: undefined,
      tireData: [],
      selfFinished: false,

      panel: 0,
      bIsFinished: false,
      displayPlus: false,

      tireConditionID: undefined,
      statusCollectionID: undefined,

      model: undefined,

      tireProfileOuter: undefined,
      tireProfileMiddle: undefined,
      tireProfileInner: undefined,

      crossSection: undefined,
      width: undefined,
      diameter: undefined,

      loadIndex: undefined,

      dot1: undefined,
      dot2: undefined,

      bIsWinterTires: 0,

      rules: [(v) => v != undefined && !isNaN(parseInt(v))],
      dotRules: [(v) => v != undefined && !isNaN(parseInt(v)) && v.length==2],
      stringRules: [(v) => v != undefined && v.length <= 30],
    };
  },

  components: {
    errorPopup,
  },

  computed: {
    dot() {
      return "" + this.dot1 + "" + this.dot2;
    },
    calcButtonWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 4;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },

  watch: {
    refetch: function (val) {
      if (val) {
        this.needRefetch = true;
      }
    },
    needRefetch: function (val) {
      if (val) {
        this.fetchData();
        this.needRefetch = false;
      }
    },
  },

  methods: {
    pushToDB(val, valName) {
      axios //authenticated get request
        .put(
          axios.defaults.baseURL +
            "tireConditions/" +
            this.tireConditionID +
            "/",
          {
            [valName]: val,
            statusToTireConditions: this.statusCollectionID,
            location: this.partGroupID,
          }
        )
        .catch((error) => {
          if (error.response == undefined) {
            this.errorOn = true;
          }
        });
    },
    validateBasicAndPush(val, valName) {
      if (val != undefined && !isNaN(parseInt(val))) {
        this.pushToDB(parseInt(val), valName);
      }
    },
    validateStringAndPush(val, valName) {
      if (val != undefined && val.length <= 30) {
        this.pushToDB(val, valName);
      }
    },
    validateDOTAndPush() {
      if (this.dot1 != undefined && this.dot2 != undefined) {
        this.pushToDB(this.dot, "dot");
      }
    },
    validateBoolAndPush(val, valName) {
      if (val != 0) {
        val == -1
          ? this.pushToDB(true, valName)
          : this.pushToDB(false, valName);
      }
    },
    useData() {
      if (this.tireData) {
        axios //authenticated get request
          .get(
            axios.defaults.baseURL + "tireConditions/" + this.tireData.id + "/"
          )
          .then((response) => {
            this.model = response.data.model;
            this.crossSection = response.data.crossSection;
            this.width = response.data.width;
            this.diameter = response.data.diameter;
            this.loadIndex = response.data.loadIndex;

            if (response.data.dot) {
              this.dot1 = response.data.dot.substring(0, 2);
              this.dot2 = response.data.dot.substring(2);
            }

            if (response.data.bIsWinterTires) this.bIsWinterTires = -1;
            else this.bIsWinterTires = 1;

            this.validateStringAndPush(this.model, "model");
            this.validateBasicAndPush(this.crossSection, "crossSection");
            this.validateBasicAndPush(this.width, "width");
            this.validateBasicAndPush(this.diameter, "diameter");
            this.validateBasicAndPush(this.loadIndex, "loadIndex");
            this.validateDOTAndPush();
            this.validateBoolAndPush(this.bIsWinterTires, "bIsWinterTires");
          })
          .catch((error) => console.log(error));
      }
    },

    fetchData(useData = false) {
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "simpleStatusColByVehicle/?vin=" +
            localStorage.getItem("selectedVehicle")
        )
        .then((response) => {
          this.statusCollectionID = response.data[0].id;

          var dataPre = [];
          var locString = undefined;
          var j;

          for (j = 0; j < response.data[0].tireCollection.length; j++) {
            if (
              response.data[0].tireCollection[j].location.id == this.partGroupID
            ) {
              this.tireConditionID = response.data[0].tireCollection[j].id;
              locString = response.data[0].tireCollection[j].location.name
                .split(" ")[1]
                .charAt(0);
            } else {
              if (response.data[0].tireCollection[j].bIsFinished == true) {
                this.dataAvailable = true;

                this.locString =
                  response.data[0].tireCollection[j].location.name.split(
                    " "
                  )[1];
                dataPre.push(response.data[0].tireCollection[j]);
              }
            }
          }

          if (!this.selfFinished) {
            for (j = 0; j < dataPre.length; j++) {
              if (
                dataPre[j].location.name.split(" ")[1].charAt(0) == locString
              ) {
                if(this.tireData.length > 0)
                  this.tireData.splice(0, 0, dataPre[j]);
                else
                  this.tireData = dataPre[j]
              } else {
                
                if(this.tireData.length > 0)
                  this.tireData.push(dataPre[j]);
                else
                  this.tireData = dataPre[j]
              }
            }

            if(this.tireData.length > 0)
            this.tireData = this.tireData[0];
          } else {
            this.dataAvailable = false;
          }

          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "tireConditions/" +
                this.tireConditionID +
                "/"
            )
            .then((resp) => {
              if (resp.data.bIsWinterTires == undefined)
                this.bIsWinterTires = 0;
              else if (resp.data.bIsWinterTires) this.bIsWinterTires = -1;
              else this.bIsWinterTires = 1;

              this.crossSection = resp.data.crossSection;
              this.diameter = resp.data.diameter;
              this.loadIndex = resp.data.loadIndex;

              if(resp.data.dot){
                this.dot1 = resp.data.dot.substring(0, 2);
                this.dot2 = resp.data.dot.substring(2);
              }

              this.model = resp.data.model;
              this.tireProfileInner = resp.data.tireProfileInner;
              this.tireProfileMiddle = resp.data.tireProfileMiddle;
              this.tireProfileOuter = resp.data.tireProfileOuter;
              this.width = resp.data.width;

              if (resp.data.bIsFinished) this.selfFinished = true;

              if(useData) this.useData()
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    changePlusIcon() {
      this.displayPlus = !this.displayPlus;
    },
  },

  mounted() {
    this.$refs.tireForm.validate();
    this.fetchData();
  },

  updated() {
    if (this.$refs.tireForm.validate() && this.bIsFinished != true) {
      this.selfFinished = true;

      axios //authenticated get request
        .put(
          axios.defaults.baseURL +
            "tireConditions/" +
            this.tireConditionID +
            "/",
          {
            statusToTireConditions: this.statusCollectionID,
            location: this.partGroupID,
            
            loadIndex: this.loadIndex,
            model: this.model,
            bIsWinterTires: this.bIsWinterTires == 1 ? false : true,
            tireProfileInner: this.tireProfileInner,
            tireProfileMiddle: this.tireProfileMiddle,
            tireProfileOuter: this.tireProfileOuter,
            dot: "" + this.dot1 + this.dot2,
            diameter: this.diameter,
          }
        )
        .catch((error) => {
          if (error.response == undefined) {
            this.errorOn = true;
          }})
        .finally(() => {
          setTimeout(() => {
          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "tireConditions/" +
                this.tireConditionID +
                "/"
            )
            .then((resp) => {
              var finished = true

              if(resp.data.loadIndex == null) finished = false
              if(resp.data.model == null) finished = false
              if(resp.data.bIsWinterTires == null) finished = false
              if(resp.data.tireProfileInner == null) finished = false
              if(resp.data.tireProfileMiddle == null) finished = false
              if(resp.data.tireProfileOuter == null) finished = false
              if(resp.data.dot == null) finished = false
              if(resp.data.diameter == null) finished = false

              if(finished == true){
                this.bIsFinished = true
                this.$emit("input", true);

                axios //authenticated get request
                .put(
                  axios.defaults.baseURL +
                    "tireConditions/" +
                    this.tireConditionID +
                    "/",
                  {
                    statusToTireConditions: this.statusCollectionID,
                    location: this.partGroupID,
                    bIsFinished: true
                  }
                )
                .catch((error) => {
                  if (error.response == undefined) {
                    this.errorOn = true;
                  }})
              }
            
            })
            }, 1000);
        });
    } else {
      this.selfFinished = false;
    }
  },
};
</script>