<template>
  <div id="overlay">
    <v-form ref="form">
      <v-row class="py-3 pb-2 text-center">
        <v-spacer />
        <v-col :cols="cols" class="whiteBG rounded-lg">
          <v-text-field
            class="px-4"
            required
            :rules="nameRules"
            label="Name des Dokuments"
            placeholder="Name"
            v-model="name"
            :disabled="title != undefined ? true : false"
          ></v-text-field>

          <v-autocomplete
            :disabled="(selectedDocType != undefined || name == undefined) ? true : false"
            required
            label="Dokumententyp"
            :items="types"
            class="px-4"
            v-model="type"
          ></v-autocomplete>

          <div class="damageDisplay px-4">
            <v-container fluid class="pa-0 ma-0 pb-3">
              <v-row>
                <v-col cols="12">
                  <v-img :src="displayFile" class="align-center" v-if="file">
                    <v-file-input
                      dense
                      hide-input
                      prepend-icon="mdi-camera"
                      :rules="[(value) => !!value]"
                      required
                      capture="camera"
                      accept="image/*"
                      v-model="file"
                      class="fileUploadButton"
                    ></v-file-input>
                  </v-img>
                   <v-img :src="displayFile" class="align-center" v-else>
                    <v-file-input
                      v-if="(type == undefined || name == undefined)"
                      color="accent"
                      dense
                      hide-input
                      prepend-icon="mdi-camera-off"
                      :rules="[(value) => !!value]"
                      required
                      capture="camera"
                      accept="image/*"
                      v-model="file"
                      class="fileUploadButton"
                      disabled
                      ></v-file-input>
                      <v-file-input
                      color="primary"
                      v-else
                      dense
                      hide-input
                      prepend-icon="mdi-camera"
                      :rules="[(value) => !!value]"
                      required
                      capture="camera"
                      accept="image/*"
                      v-model="file"
                      class="fileUploadButton"></v-file-input>
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </div>
          
          </v-col>
        <v-spacer />
      </v-row>

      <!-- <v-row class="py-1">
        <v-spacer />
        <v-col :cols="cols">
          <v-btn @click="save()" x-large color="primary" depressed block>
            Speichern & Schließen</v-btn
          >
        </v-col>
        <v-spacer />
      </v-row> -->
    </v-form>

    <errorPopup :on="errorOn" :data="'Dokumenterstellung'" />
  </div>
</template>

<script>
import errorPopup from "../ErrorPopup.vue";

const axios = require("axios");

export default {
  name: "overlay",
  props: ["data", "selectedDocType", "title"],

  data() {
    return {
      errorOn: false, 

      name: this.title,
      nameRules: [(v) => !!v],

      type: undefined,
      types: [],

      file: undefined,
      displayFile: undefined,
    };
  },

   components: {
    errorPopup,
  },

  watch: {
    file: function () {
      this.displayFile = URL.createObjectURL(this.file);
      if(this.displayFile)
        this.save();
    },
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 9;
        case "md":
          return 7;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        var newDocument = new FormData();
        newDocument.append("name", this.name);
        newDocument.append("last_updated", new Date().toLocaleString());
        newDocument.append("created", new Date().toLocaleString());
        newDocument.append("document", this.file);
        newDocument.append("documentsToVehicle", this.$route.params.vin);
        newDocument.append("documentsToDocumentType", this.type);
        newDocument.append("documentsToUser", localStorage.getItem("id"));

        if (this.data) {
          newDocument.append("id", this.data.id);

          axios //authenticated get request
            .put(axios.defaults.baseURL + "documents/" + this.data.id + "/", newDocument)
            .then(() => {
              this.$emit("input", true);
            })
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
        }
        else {
          axios //authenticated get request
            .post(axios.defaults.baseURL + "documents/new/", newDocument)
            .then(() => {
              this.$emit("input", true);
            })
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            });
        }
      }
    },
  },

  mounted() {
    axios //authenticated get request
      .get(axios.defaults.baseURL + "documentTypes/")
      .then((response) => {
        for (var j = 0; j < response.data.length; j++) {
          this.types.push(response.data[j].name);
        }

        if (this.data) {
            this.name = this.data.name;
            this.type = this.types[this.types.indexOf(this.data.documentsToDocumentType.name)];
        }

        if(this.selectedDocType){
          this.type = this.types[this.types.indexOf(this.selectedDocType)];
        }
      })
      .catch((error) => console.log(error));
  },
};
</script>