import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/Login.vue'
import mainPage from '../views/MainPage.vue'
import admin from '../views/Admin.vue'
import stock from "../views/Stock.vue"
import modelDetail from "../views/ModelDetail.vue"
import modelOverview from "../views/ModelOverview.vue"
import damages from "../views/Damages.vue"
import status from "../views/Status.vue"
import documents from "../views/Documents.vue"
import vehicleImages from "../views/VehicleImages.vue"
import vehicleOverview from "../views/VehicleOverview.vue"
import assistant from "../views/Assistant.vue"
import newVehicle from "../views/NewVehicle.vue"
import searchView from "../views/SearchView.vue"
import assessmentPDF from "../views/AssessmentPDF.vue"
import store from '../store'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: login, beforeEnter(to, from, next) {
    document.title = 'Eberlein Carware' 
    next()} 
  },
  {
    path: '/stock', component: stock, beforeEnter(to, from, next) {
      if (store.state.accessToken) {
        document.title = 'Bestand  - Eberlein Carware'
        next()
      }
      else {
        
        next('/')
      }
    },
    children: [
      {
        path: '',
        component: modelOverview
      },
      {
        path: 'model/:model',
        component: modelDetail
      },
      {
        path: 'search',
        component: searchView
      }
    ]
  },
  {
    path: '/stock/vehicle/:vin/', component: mainPage, beforeEnter(to, from, next) {
      document.title = 'Fahrzeugübersicht - Eberlein Carware' 
      if (store.state.accessToken) {
        next()
      }
      else {
        next('/')
      }
    },
    children: [
      {
        path: 'overview',
        component: vehicleOverview
      },
      {
        path: 'pictures',
        component: vehicleImages
      },
      {
        path: 'assistant',
        component: assistant
      },
      {
        path: 'damages',
        component: damages
      },
      {
        path: 'status',
        component: status
      },
      {
        path: 'documents/:alert?',
        component: documents
      },
    ]
  },
  {
    path: '/stock/new/overview', component: newVehicle, beforeEnter(to, from, next) {
      document.title = 'Neues Fahrzeug - Eberlein Carware' 
      if (store.state.accessToken) {
        next()
      }
      else {
        next('/')
      }
    },
  },
  {
    path: '/documents/:assessmentID/', component: assessmentPDF, beforeEnter(to, from, next) {
      document.title = 'Bewertung' 
      if (store.state.accessToken) {
        next()
      }
      else {
        next('/')
      }
    },
  },
  {
    path: '/admin', component: admin, beforeEnter(to, from, next) {
      document.title = 'Administration - Eberlein Carware'
      if (store.state.accessToken && localStorage.getItem("data") == "true") {
        next()
      }
      else {
        next('/stock')
      }
    },
  },
  { path: "*", component: login }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
})


export default router