<template>
    <div>
        <v-textarea
          auto-grow
          counter
          outlined
          label="Fahrzeugkommentar (wird automatisch gespeichert!)"
          :rules="textBoxRules"
          :value="vehicle != undefined ? vehicle.vehicle_comment : ''"
          v-model="vehicle.vehicle_comment"
          @change="pushToDb()"
        ></v-textarea>
        <p v-if="vehicle.vehicle_comment && vehicle.vehicle_comment.length > 0">Erstellt von {{ vehicle.vehicle_comment_creator || "unbekannt" }} am {{ humanReadableDate(vehicle.vehicle_comment_date) || "unbekannt" }}</p>
    </div>
</template>


<script>
const axios = require("axios");

export default {
  name: "vehicleOverview",

  data() {
    return {
      vehicle: {
          vehicle_comment: ""
      },
      textBoxRules: [v =>  v == undefined || v.length <= 2000 || "Maximal 2000 Zeichen!"]
    };
  },

  computed: {
    
  },

  methods: {
    humanReadableDate(date){
      var dateArray = date.split("T");
      var dateString = dateArray[0].split("-");
      var timeString = dateArray[1].split(":");

      return dateString[2]+"."+dateString[1]+"."+dateString[0]+", "+timeString[0]+":"+timeString[1];
    },
    pushToDb(){
        if(this.vehicle.vehicle_comment && this.vehicle.vehicle_comment.length > 2000){
            this.vehicle.vehicle_comment = this.vehicle.vehicle_comment.substr(0, 2000)
        }

        var date = new Date().toLocaleString();
        var dateArray = date.split(", ");
        var dateString = [], timeString = []

        dateString = ("" + dateArray[0]).split('.'); 
        timeString = dateArray[1].split(":");
        
        date = dateString[2]+"-"+dateString[1]+"-"+dateString[0]+"T"+timeString[0]+":"+timeString[1]

        let finalVehicle = {
            vin: this.vehicle.vin,
            vehicle_comment: this.vehicle.vehicle_comment,
            vehicle_comment_date: date,
            vehicle_comment_creator: localStorage.getItem('name'),
            vehiclesToModel: this.vehicle.vehiclesToModel,
            vehiclesToUser: this.vehicle.vehiclesToUser
        }
        
        axios //authenticated get request
          .put(axios.defaults.baseURL + "simpleVehicle/" + this.$route.params.vin+ "/", finalVehicle)
          .then(resp => {
            console.log(resp)
            this.vehicle = resp.data
          })
          .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
        })

    }
  },

  mounted() {
    axios //authenticated get request
        .get(axios.defaults.baseURL + "simpleVehicle/" + this.$route.params.vin)
        .then((r) => {
          this.vehicle = r.data;
        })
  },

};
</script>
