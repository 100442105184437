<template>
  <div id="stockPage">
    <headerComponent :titleProp="pageTitle" :bHasSearchBar="bHasSearchBar" />
    <br />
    <v-tabs
      class="mainNavBar pb-5"
      color="primary"
      background-color="accent"
      slider-color="rgba(0,0,0,0)"
      fixed-tabs
      height="2em"
    >
      <v-tab class="mainNavBar" ripple>Stammdaten</v-tab>
      <v-tab class="mainNavBar" ripple disabled>Fotos</v-tab>
      <v-tab class="mainNavBar" ripple disabled>Bewertung</v-tab>
      <v-tab class="mainNavBar" ripple disabled>Schadensliste</v-tab>
      <v-tab class="mainNavBar" ripple disabled>Dokumente</v-tab>
    </v-tabs>

    <div class="whiteBG mx-6">
      <v-container fluid>
        <v-row>
          <v-col :cols="textCols">
            <v-img
              class="mb-6 white--text align-center text-center font-weight-medium rounded-lg"
              height="25vh"
              :src="
                leftImageDisplay ||
                require('../assets/images/placeholders/ph_left.jpg')
              "
              :gradient="
                leftImageDisplay
                  ? ''
                  : 'to bottom, rgba(0,0,0,.3), rgba(0,0,0,.5)'
              "
            >
            <v-file-input
                v-if="!leftImageDisplay"
                class="fileUploadButton"
                prepend-icon="mdi-plus"
                dense
                hide-input
                capture="camera"
                accept="image/*"
                v-model="leftImage"
              ></v-file-input>
              <v-file-input
                v-else
                class="fileUploadButton"
                prepend-icon="mdi-circle-edit-outline"
                dense
                hide-input
                capture="camera"
                accept="image/*"
                v-model="leftImage"
              ></v-file-input>
            </v-img>
          </v-col>

          <v-col :cols="textCols">
            <v-img
              class="mb-6 white--text align-center text-center font-weight-medium rounded-lg"
              height="25vh"
              :src="
                paper1ImageDisplay ||
                require('../assets/images/placeholders/ph_paper1.jpg')
              "
              :gradient="
                paper1ImageDisplay
                  ? ''
                  : 'to bottom, rgba(0,0,0,.3), rgba(0,0,0,.5)'
              "
            >
              <v-file-input
                v-if="!paper1ImageDisplay"
                class="fileUploadButton"
                prepend-icon="mdi-plus"
                dense
                hide-input
                capture="camera"
                accept="image/*"
                v-model="paper1Image"
              ></v-file-input>
              <v-file-input
                v-else
                class="fileUploadButton"
                prepend-icon="mdi-circle-edit-outline"
                dense
                hide-input
                capture="camera"
                accept="image/*"
                v-model="paper1Image"
              ></v-file-input>
            </v-img>
          </v-col>
          <v-col :cols="textCols">
            <v-img
              class="mb-6 white--text align-center text-center font-weight-medium rounded-lg"
              height="25vh"
              :src="
                paper2ImageDisplay ||
                require('../assets/images/placeholders/ph_paper2.jpg')
              "
              :gradient="
                paper2ImageDisplay
                  ? ''
                  : 'to bottom, rgba(0,0,0,.3), rgba(0,0,0,.5)'
              "
            >
              <v-file-input
                v-if="!paper2ImageDisplay"
                class="fileUploadButton"
                prepend-icon="mdi-plus"
                dense
                hide-input
                capture="camera"
                accept="image/*"
                v-model="paper2Image"
              ></v-file-input>
              <v-file-input
                v-else
                class="fileUploadButton"
                prepend-icon="mdi-circle-edit-outline"
                dense
                hide-input
                capture="camera"
                accept="image/*"
                v-model="paper2Image"
              ></v-file-input>
            </v-img>
          </v-col>
        </v-row>

        <v-form ref="form">
          <v-row class="px-6">
            <v-col :cols="textCols">
              <v-autocomplete
                required
                :value="vehicle.vehicleManufacturerModelsToVehicleManufacturer"
                label="Hersteller*"
                :items="manufacturerDisplayNames"
                class="px-4"
                v-model="selectedManufacturer"
              ></v-autocomplete>

              

            <v-container fluid class="pa-0">
            <v-row>
              <v-col>
                <v-text-field
                required
                :rules="vinRules"
                :value="vehicle.vin"
                label="Fahrgestellnummer*"
                class="px-4"
                v-model="vehicle.vin"
                @change="bVinExists = false"
              >
              </v-text-field>
              </v-col>
              <v-col class="mb-0 pb-0" cols="auto">
                <v-icon @click="vehicle.crashVehicle = false;" v-if="vehicle.crashVehicle == true" class="icon-on mb-n6 px-0 mr-4">mdi-alpha-u-box-outline</v-icon>
                <v-icon @click="changePopUp = true" v-else class="icon-off mb-n6 px-0 mr-4">mdi-alpha-u-box-outline</v-icon>
              </v-col>
            </v-row>
          </v-container>

              <v-combobox
                required
                label="Fahrzeugmodell*"
                :items="modelDisplayNames"
                class="px-4"
                :rules="modelRules"
                @update:search-input="fetchModels"
                v-model="selectedModel"
              ></v-combobox>

              <br v-show="addDivider" />
              <v-divider v-show="addDivider"></v-divider>
            </v-col>

            <v-col :cols="textCols">
              <v-text-field
                label="Kundenname"
                class="px-4"
                v-model="vehicle.currentOwner"
              ></v-text-field>

              <v-text-field
                label="Kilometerstand"
                class="px-4"
                v-model="vehicle.mileage"
              ></v-text-field>

              <v-text-field
                label="Kennzeichen (AB-CD 123 o.ä.)"
                class="px-4"
                v-model="vehicle.plate"
                :rules="plateRules"
              ></v-text-field>

              <br v-show="addDivider" />
              <v-divider v-show="addDivider"></v-divider>
            </v-col>
            <v-col :cols="textCols">
              <v-text-field
                :rules="priceRules"
                label="Ankaufspreis"
                class="px-4"
                v-model="vehicle.purchasePrice"
              ></v-text-field>

              <v-text-field
                disabled
                value="'--Bewertung nicht abgeschlossen!--'"
                label="Reperaturkosten"
                class="px-4"
              ></v-text-field>

              <v-text-field
                disabled
                value="'--Bewertung nicht abgeschlossen!--'"
                label="Verkaufspreis"
                class="px-4"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-spacer />
            <v-col cols="5">
              <v-btn
                v-if="!bVinExists"
                block
                depressed
                large
                color="primary"
                elevation="2"
                @click="pushVehicle"
                >Fahrzeug anlegen
              </v-btn>
              <v-btn
                v-else
                @click="toVehicle(vehicle.vin)"
                block
                depressed
                large
                color="primary"
                elevation="2"
              >
                Zum Fahrzeug wechseln
              </v-btn>
            </v-col>
            <v-spacer />
          </v-row>
        </v-form>
      </v-container>
    </div>

    <v-overlay :value="loading == 0 ? false : true" opacity="0.1">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>

    <errorPopup :on="errorOn" :data="errorMessage" />

    <v-dialog
      v-model="changePopUp"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Fahrzeug als Unfallfahrzeug markieren?
        </v-card-title>

        <v-card-text class="pa-4">
          Diese Aktion kann NICHT rückgängig gemacht werden, nachdem das Fahrzeug angelegt wurde. Wirklich fortfahren?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="vehicle.crashVehicle = false; changePopUp = false"
          >
            Doch nicht
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="vehicle.crashVehicle = true; changePopUp = false"
          >
            Fortfahren!
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
const axios = require("axios");

import headerComponent from "../components/Header.vue";
import errorPopup from "../components/ErrorPopup.vue";

export default {
  name: "stock",

  data() {
    return {
      errorOn: false,
      pageTitle: "Modellübersicht",
      bHasSearchBar: false,
      loading: 0,

      vehicle: {
        vin: undefined,
        currentOwner: "",
        mileage: "",
        purchasePrice: "",
        crashVehicle: undefined
      },

      leftImage: undefined,
      paper1Image: undefined,
      paper2Image: undefined,

      paper1Overlay: false,
      paper2Overlay: false,

      leftImageDisplay: undefined,
      paper1ImageDisplay: undefined,
      paper2ImageDisplay: undefined,

      models: [],
      modelDisplayNames: [],
      selectedModel: undefined,
      manufacturers: [],
      manufacturerDisplayNames: [],
      selectedManufacturer: "Ferrari",

      modelRules: [
        (v) => !!v || "Modell auswählen!",
        (v) =>
          this.modelDisplayNames.indexOf(v) != -1 || "Modell nicht vorhanden!",
      ],
      priceRules: [
        (v) =>
          (v >= 0 && v <= 1000000000) ||
          "Ankaufspreis angeben!",
      ],
      vinRules: [
        (v) => !!v || "Fahrgestellnummer angeben!",
        (v) =>
          (!!v && v.length <= 17 && v.length > 0) ||
          "Fahrgestellnummer ungültig!",
        () => !this.bVinExists || "Fahrgestellnummer existiert bereits!",
      ],
      plateRules: [(v) => !v || v.length <= 10],

      bVinExists: false,
      changePopUp: false,
    };
  },

  watch: {
    leftImage: function (val) {
      if (val) {
        this.leftImageDisplay = URL.createObjectURL(this.leftImage);
      }
    },
    paper1Image: function (val) {
      if (val) {
        this.paper1ImageDisplay = URL.createObjectURL(this.paper1Image);
      }
    },
    paper2Image: function (val) {
      if (val) {
        this.paper2ImageDisplay = URL.createObjectURL(this.paper2Image);
      }
    },
  },

  components: {
    headerComponent,
    errorPopup,
  },

  computed: {
    errorMessage(){
      return "Fahrzeug anlegen, " + this.vehicle.vin
    },
    textCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        default:
          return 4;
      }
    },
    addDivider() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    pushVehicle() {
      if (this.$refs.form.validate()) {
        axios //authenticated get request
          .get(axios.defaults.baseURL + "vehicles/" + this.vehicle.vin + "/")
          .then(() => {
            this.bVinExists = true;
            this.$refs.form.validate();
          })
          .catch((error) => {
            if (error.response == undefined) {
              this.errorOn = true
            }
            if (error.response.status == 404) {
              this.CalcAndPushToDB();
            }
          });
      }
    },
    CalcAndPushToDB() {
      this.loading = 1;
      var modelID = this.models[
        this.modelDisplayNames.indexOf(this.selectedModel)
      ].id;

      this.formatPlate()

      const finalVehicle = {
        crashVehicle: this.vehicle.crashVehicle,
        vin: this.vehicle.vin,
        repairPrice: 0,
        currentOwner: this.vehicle.currentOwner,
        purchasePrice: parseInt(this.vehicle.purchasePrice) || null,
        plate: this.vehicle.plate,
        mileage: parseInt(this.vehicle.mileage),
        bInHouse: true,
        bFinishedReport: false,
        bFinishedPricing: false,
        vehiclesToModel: modelID,
        vehiclesToUser: localStorage.getItem("id"),
      };

      axios //authenticated get request
        .post(
          axios.defaults.baseURL + "vehicles/new/",
          finalVehicle
        )
        .then(() => {

          if (this.leftImage != undefined) {
            this.postImage("left", this.leftImage);
          }
          if (this.paper1Image != undefined) {
            this.postDocument("FZS vorn", "FS vorn", this.paper1Image, finalVehicle.vin);
          }
          if (this.paper2Image != undefined) {
            this.postDocument("FZS hinten", "FS hinten", this.paper2Image, finalVehicle.vin);
          }

          setTimeout(() => {
              this.loading = 0;
              this.$router.push("/stock/vehicle/" + finalVehicle.vin + "/overview");
            }, 4000);
          
        })

        .catch((error) => {
          console.log(error)
        });
    },
    postImage(slot, file) {
      var data = new FormData(); // creates a new FormData object

      //append image file
      data.append("imageFile", file);
      data.append("slot", slot);
      data.append("bIsKeyVisual", true);
      data.append("vehiclePhotosToVehicle", this.vehicle.vin);

      axios //authenticated get request
        .post(axios.defaults.baseURL + "vehiclePhotos/", data)
        .then(() => {
          this.loading--;
        })
        .catch((error) => {
          console.log(error);
          this.loading--;
        });
    },
    postDocument(name, type, file, vin) {
      var newDocument = new FormData();
        newDocument.append("name", name);
        newDocument.append("last_updated", new Date().toLocaleString());
        newDocument.append("created", new Date().toLocaleString());
        newDocument.append("document", file);
        newDocument.append("documentsToVehicle", vin);
        newDocument.append("documentsToDocumentType", type);
        newDocument.append("documentsToUser", localStorage.getItem("id"));

        axios //authenticated get request
          .post(axios.defaults.baseURL + "documents/new/", newDocument)
          .then(() => {
            this.loading--;
          })
          .catch((error) => {
            this.loading--;
            if (error.response == undefined) {
             this.errorOn = true
            }
        })
    },
    backToModel() {
      if (this.$route.params.vin)
        this.$router.push(
          "/stock/model/" + localStorage.getItem("selectedModel")
        );
      else this.$router.push("/stock");
    },
    toVehicle(val) {
      this.$router.push("/stock/vehicle/" + val + "/overview");
    },
    fetchModels(val) {
      if (val && val.length > 0) {
        axios //authenticated get request
          .get(
            axios.defaults.baseURL +
              "model/search/?search=" +
              val +
              "&manuf=" +
              this.selectedManufacturer
          )
          .then((response) => {
            this.models = response.data;
            this.modelDisplayNames = [];

            for (var i = 0; i < this.models.length; i++) {
              this.modelDisplayNames.push(this.models[i].name);
            }

            //this.$forceUpdate()
          })
          .catch((error) => console.log(error));
      }
    },
    formatPlate(){
      if(this.vehicle.plate && this.vehicle.plate.length > 0){
        var plateArray = this.vehicle.plate.split(/–| |-/)
        var result = plateArray[0] + "-"

        for(var i = 1; i < plateArray.length; i++){
          result += plateArray[i] + " "
        }
        
        result = result.toUpperCase()
        this.vehicle.plate = result
      }
    },
    
  },

  mounted() {
    axios //authenticated get request
      .get(axios.defaults.baseURL + "vehicleManufacturers/")
      .then((response) => {
        this.manufacturers = response.data;

        for (var i = 0; i < this.manufacturers.length; i++) {
          this.manufacturerDisplayNames.push(this.manufacturers[i].name);
        }
      })
      .catch((error) => console.log(error));
  },
};
</script>