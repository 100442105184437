<template>
  <div id="login">
    <v-app>
      <v-container fluid class="ma-auto">
        <v-row md="auto" justify="center" no-gutters>
          <v-col>
            <v-card
              class="pa-2"
              color="background"
              outlined
              tile
              align="center"
            >
              <v-img
                id="loginLogo"
                src="../assets/images/EberleinLogo.png"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>

        <v-row md="auto" justify="center" no-gutters>
          <v-col>
            <v-card
              class="pa-2"
              color="background"
              outlined
              tile
              align="center"
            >
              <h1>Eberlein Carware</h1>
              <p>Die besten Fahrzeugbewertungen mit Eberlein Carware</p>
            </v-card>
          </v-col>
        </v-row>

        <v-form ref="form" lazy-validation>
          <v-row md="auto" justify="center" no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-card
                class="pa-2"
                color="background"
                outlined
                tile
                align="center"
              >
                <v-text-field
                  id="email"
                  :rules="emailRules"
                  label="E-Mail"
                  v-model="email"
                  required
                ></v-text-field>
                <v-text-field
                  id="password"
                  :rules="passwordRules"
                  :type="showEyeOpen ? 'password' : 'regular'"
                  label="Passwort"
                  v-model="password"
                  required
                  :append-icon="showEyeOpen ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="toggleShowPassword"
                >
                </v-text-field>
              </v-card>
            </v-col>
          </v-row>

          <v-row md="auto" justify="center" no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-card
                class="pa-2"
                color="background"
                outlined
                tile
                align="center"
              >
                <v-btn
                  @click="submit"
                  block
                  depressed
                  large
                  color="primary"
                   elevation="2"
                  >Einloggen</v-btn
                >
              </v-card>
            </v-col>
          </v-row>
        </v-form>

        <v-row md="auto" justify="center" no-gutters>
          <v-col cols="12" sm="6" md="3">
            <v-card
              class="pa-2"
              color="background"
              outlined
              tile
              align="center"
            >
              <a :href="url">Password vergessen?</a>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
const axios = require("axios")

export default {
  name: "login",

  data() {
    return {
      isValid: true,
      email: "",
      emailRules: [
        (v) => (v && this.isValid) || "E-Mail oder Passwort falsch!",
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [
        (v) => (v && this.isValid) || "E-Mail oder Passwort falsch!",
      ],
      url: axios.defaults.baseURL + 'recover/',
      showEyeOpen: true,
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    submit() {
      this.email = this.email.toLowerCase();
      const formData = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch("loginUser", formData)
      .catch((err => {
        if(err.response.status == 401){
          this.isValid = false
          this.$refs.form.validate()
        }
      }));
    },
    toggleShowPassword(){
      this.showEyeOpen = !this.showEyeOpen;
    },
    login(e){
      if(e.key == "Enter") this.submit()
    }
  },
  mounted() {
    window.addEventListener("keypress", this.login )
  },

  beforeDestroy() {
    window.removeEventListener("keypress", this.login)
  }
};
</script>
