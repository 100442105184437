<template>
  <div id="overlay">
    <v-card
      color="rgba(0,0,0,0)"
      class="rounded-lg overflow-y-auto"
      :class="{ 'overflow-y-auto': hasOverflow }"
      max-height="90vh"
      flat
      :width="calcWidth"
      light
    >
      <v-container fluid>
        <v-row class="py-6 pb-3">
          <v-spacer />

          <v-col
            :cols="cols"
            class="whiteBG text-center font-weight-bold text-h5 rounded-lg"
          >
            <v-card flat>
            <v-container fluid class="ma-0 pa-0 fill-height">
              <v-row justify="center" align="center">
                <v-col cols="10">
              <div v-if="type=='damage'">
                <h5>{{ title }}</h5>
                {{ data[0].name }}
                </div>
              <div v-else>
                {{ title }} 
                </div>
                </v-col>
                <v-col cols="2">
              <v-btn
                class="ma-0 pa-0"
                fab
                color="white"
                small
                elevation="0"
                @click="disable"
              >
                <v-icon center id="sortIcon" x-large>mdi-close</v-icon>
              </v-btn>
                </v-col>
                
              </v-row>
                </v-container>
                </v-card
            >
          </v-col>
          <v-spacer />
        </v-row>

        <pricingOverlay v-if="type == 'price'" :data="data" v-model="close" />
        <damageOverlay
          v-if="type == 'damage'"
          :title="title"
          :data="data"
          v-model="close"
        />
        <documentOverlay
          v-if="type == 'document'"
          :data="data == undefined ? undefined : (data.length > 1 ? data[0] : data)"
          v-model="close"
          :title="data == undefined ? undefined : (data.length > 2 ? data[2] : '')"
          :selectedDocType="data == undefined ? undefined : (data.length > 1? data[1] : undefined)"
        />
      </v-container>
    </v-card>
  </div>
</template>

<script>
import pricingOverlay from "./Overlay_Pricing.vue";
import damageOverlay from "./Overlay_Damage.vue";
import documentOverlay from "./Overlay_Document.vue";

export default {
  name: "overlay",
  props: ["title", "type", "data"],

  data() {
    return {
      close: false,
    };
  },

  watch: {
    close: function () {
      this.disable();
    },
  },

  components: {
    pricingOverlay,
    damageOverlay,
    documentOverlay,
  },

  computed: {
    calcWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "75vw";
        case "sm":
          return "75vw";
        case "md":
          return "65vw";
        case "lg":
          return "55vw";
        case "xl":
          return "50vw";
        default:
          return "5vw";
      }
    },

    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 9;
        case "md":
          return 7;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
    hasOverflow() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
  },

  methods: {
    disable() {
      this.$emit("input", false);
    },
  },
};
</script>