<template>
  <div id="images" class="mx-6">
    <v-container class="pt-0" fluid>
      <v-row v-for="(row, rowIndex) in rows" :key="rowIndex">
        <v-col class="pa-1" :cols=12/row.length v-for="(key, keyIndex) in row" :key="keyIndex">
          <v-card v-if="images[calcIndex(rowIndex, keyIndex)]==key">
            <v-img 
              class="white--text align-center text-center font-weight-medium"
              :height="calcHeight(row.length)"
              :src="require(`../assets/images/placeholders/ph_${key}.jpg`)"
              :alt="key"
              gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.7)"
            >
            
            <v-container fluid v-if="!hideInputIcons">
              <v-row>
                <v-col>
              <v-file-input
              class="fileUploadButton"
              dense
              hide-input
              prepend-icon="mdi-plus"
              capture="camera"
              accept="image/*"
              v-model="inputs[calcIndex(rowIndex, keyIndex)]"
               @click="() => {bNewImage=true; changeIndex = calcIndex(rowIndex, keyIndex)}"
              ></v-file-input>
              </v-col>
              </v-row>
              <v-row>
                <v-col>
              {{getHumanReadableName(key)}} <br> fotografieren
                </v-col>
              </v-row>
             </v-container>
            </v-img>
          </v-card>
          <v-card v-else>
            <v-img
              class="white--text text-right"
              :height="calcHeight(row.length)"
              :src="images[calcIndex(rowIndex, keyIndex)]"
              :alt="key"
              gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.1)"
            >
           
            <v-icon @click="openInNewWindow(`${images[calcIndex(rowIndex, keyIndex)]}`)"> mdi-crop-free </v-icon>
            <v-icon @click="saveImage(`${images[calcIndex(rowIndex, keyIndex)]}`)"> mdi-download </v-icon>
              
            <v-container fluid fill-height class="pa-0 pb-15" v-if="!hideInputIcons">
             <v-row>
                <v-col>
            <v-file-input
              class="fileUploadButton"
              dense
              hide-input
              prepend-icon="mdi-circle-edit-outline"
              capture="camera"
              accept="image/*"
              v-model="inputs[calcIndex(rowIndex, keyIndex)]"
              @click="() => {bNewImage=false; changeIndex = calcIndex(rowIndex, keyIndex)}"
            ></v-file-input>
             </v-col>
              </v-row>
              
            </v-container>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <errorPopup :on="errorOn" :data="'Bild Upload'" />
  </div>
</template>

<script>
const axios = require("axios");
import json from "../assets/data/slotData.json";

import errorPopup from "../components/ErrorPopup.vue";

export default {
  name: "images",
  props: ["rowScaling"],

  data() {
    return {
      errorOn: false, 

      slots: ["front", "left", "trunk", "engine", "inside", "right", "back"],
      changeIndex: -1,
      bNewImage: false,

      images: ["front", "left", "trunk", "engine", "inside", "right", "back"], //files only
      rawImages: [],
      hasImages: [false, false, false, false, false, false, false],
      scale: this.rowScaling ? this.rowScaling : 0.99,

      report: undefined,

      inputs: [],
      hideInputIcons: false,

      vehicleImages: [] //used only to update images for vehicle
    };
  },

  watch: {
    inputs: function () {
      if (this.bNewImage) {
        this.postToDB(
          this.slots[this.changeIndex],
          this.inputs[this.changeIndex],
         
        );
         this.hasImages[this.changeIndex] = true
      } else {
        this.putToDB(
          this.slots[this.changeIndex],
          this.inputs[this.changeIndex]
          
        );
        this.hasImages[this.changeIndex] = true
      }
    },
  },

    components: {
    errorPopup,
  },


  computed: {
    rows() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return [
            ["front"],
            ["left"],
            ["trunk"],
            ["engine"],
            ["inside"],
            ["right"],
            ["back"],
          ];
        default:
          return [
            ["front", "left"],
            ["trunk", "engine", "inside"],
            ["right", "back"],
          ];
      }
    },
  },

  methods: {
    async saveImage(url) {
      //window.open(source, "_blank");
      await this.downloadImage(url)
    },
    async downloadImage(url) {
      var base64 = await axios
        .get(url, {
          responseType: "arraybuffer"
        })
        .then(response =>
          Buffer.from(response.data, "binary").toString("base64")
        );
      var img = new Image();
      img.src = "data:image/jpeg;base64, " + base64;
      

      var fileLink = document.createElement('a');
   
      fileLink.href = img.src;

      var vin = this.$route.params.vin 
      fileLink.download = vin + "_Schaden.jpg"
   
      fileLink.click();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    calcHeight(value) {
      if (this.$vuetify.breakpoint.height > this.$vuetify.breakpoint.width) {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "25vh";
          default:
            break;
        }
      } else {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "60vh";
          case "sm":
            return "60vh";
          default:
            break;
        }
      }

      var temp = (12 / (value * 2)) * 10 * this.scale;
      temp = Math.max(12, temp)

      return "" + temp + "vh";
    },
    openInNewWindow(source) {
      window.open(source, "_blank");
    },
    getHumanReadableName(keyProp) {
      return json[keyProp];
    },
    calcIndex(rowIndex, keyIndex) {
      var index = keyIndex;

      for (var i = 0; i < rowIndex; i++) index += this.rows[i].length;

      return index;
    },
    putToDB(slot, file) {
      //check if rawImages already contains a reference for each slot, if not fetch it
      if(this.rawImages.length != this.slots.length){
         axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "imageSearchByReport/?vin=" +
            localStorage.getItem("selectedVehicle")
          )
          .then(response => {
            this.rawImages = response.data
            this.finalPutToDB(slot, file)
          })
      }
      else{
        this.finalPutToDB(slot, file)
      }

      
    },

    finalPutToDB(slot, file){
      //get index of file in rawimages that contains id for database
      var id;
      for (var i = 0; i < this.rawImages.length; i++) {
        if (this.rawImages[i].slot == slot) {
          id = this.rawImages[i].id;
          break;
        }
      }

      let data = new FormData(); // creates a new FormData object

      var img = new Image;
      var c = document.createElement("canvas");
      var ctx = c.getContext("2d");

      var max_width = 2142 ,
        max_height = 1615,
        quality = 0.6,
        ratio = 1;

      img.onload = function() {
        if(img.width > max_width || img.height > max_height){
          if(img.width > img.height){
            ratio = img.width/img.height;
            c.width = max_width;     // update canvas size to match image
            c.height = max_width / ratio;
          }
          else{
            ratio = img.height/img.width;
            c.height = max_height;
            c.width = max_height / ratio;
          }
        }
        else{
          c.width = img.width;     // update canvas size to match image
          c.height = img.height;
          quality = 1;
        }
        
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, c.width, c.height);      // draw in image

        c.toBlob(function(blob) {        // get content as JPEG blob
          // here the image is a blob
          //append image file
          data.append("imageFile", blob, "image.jpg");
          data.append("slot", slot);
          data.append("bIsKeyVisual", false);
          data.append(
            "reportPhotosToVehicle",
            this.report
          );

          axios //authenticated get request
            .put(axios.defaults.baseURL + "reportPhotos/" + id + "/", data)
            .then((response) => {
              this.images[this.changeIndex]
              this.$set(
                this.images,
                this.changeIndex,
                response.data.imageFile
              );
              this.changeIndex = -1;
              this.$forceUpdate();
            })
            .catch((error) => {
                  if (error.response == undefined) {
                    this.errorOn = true
                  }
                })

          this.updateVehicleImages(slot, file)


        }.bind(this), "image/jpeg", quality);
      }.bind(this);
      img.src = URL.createObjectURL(file);

      // data.append("slot", slot);
      // data.append("bIsKeyVisual", false);
      // data.append(
      //   "reportPhotosToVehicle",
      //   this.report
      // );

      // axios //authenticated get request
      //   .put(axios.defaults.baseURL + "reportPhotos/" + id + "/", data)
      //   .then((response) => {
      //     this.images[this.changeIndex]
      //     this.$set(
      //       this.images,
      //       this.changeIndex,
      //       response.data.imageFile
      //     );
      //     this.changeIndex = -1;
      //     this.$forceUpdate();
      //   })
      //   .catch((error) => {
      //         if (error.response == undefined) {
      //           this.errorOn = true
      //         }
      //       })

      // this.updateVehicleImages(slot, file)
    },

    postToDB(slot, file) {
      let data = new FormData(); // creates a new FormData object

      var img = new Image;
      var c = document.createElement("canvas");
      var ctx = c.getContext("2d");

      var max_width = 2142 ,
        max_height = 1615,
        quality = 0.6,
        ratio = 1;

      img.onload = function() {
        if(img.width > max_width || img.height > max_height){
          if(img.width > img.height){
            ratio = img.width/img.height;
            c.width = max_width;     // update canvas size to match image
            c.height = max_width / ratio;
          }
          else{
            ratio = img.height/img.width;
            c.height = max_height;
            c.width = max_height / ratio;
          }
        }
        else{
          c.width = img.width;     // update canvas size to match image
          c.height = img.height;
          quality = 1;
        }
        
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, c.width, c.height); // draw in image

        c.toBlob(function(blob) {        // get content as JPEG blob
          // here the image is a blob
          //append image file
          data.append("imageFile", blob, "image.jpg");

          
          data.append("slot", slot);
          data.append("bIsKeyVisual", false);
          data.append(
            "reportPhotosToVehicle",
            this.report
          );

          axios //authenticated get request
            .post(axios.defaults.baseURL + "reportPhotos/", data)
            .then((response) => {
              this.$set(
                this.images,
                this.changeIndex,
                response.data.imageFile
              );
              this.changeIndex = -1;
              this.$forceUpdate();
            })
            .catch((error) => {
                  if (error.response == undefined) {
                    this.errorOn = true
                  }
                })
      
          this.updateVehicleImages(slot, file)

        }.bind(this), "image/jpeg", quality);
      }.bind(this);
      img.src = URL.createObjectURL(file);

    },

    updateVehicleImages(slot, file){
    //get Vehicle Images if array doesnt already contain them
    if(this.vehicleImages.length != this.slots.length)
    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "imageSearchByVehicle/?vin=" +
          localStorage.getItem("selectedVehicle")
      )
      .then((response) => { 
          this.vehicleImages = response.data
          this.putVehicleImages(slot, file)
        })

      else{
        this.putVehicleImages(slot, file)
      }     
  },

  putVehicleImages(slot, file){
    let data = new FormData(); // creates a new FormData object

    var index;
    for(var i = 0; i < this.vehicleImages.length; i++){
      if(this.vehicleImages[i].slot == slot){
        index = i;
        break;
      }
    }

    var img = new Image;
      var c = document.createElement("canvas");
      var ctx = c.getContext("2d");

      var max_width = 2142 ,
        max_height = 1615,
        quality = 0.6,
        ratio = 1;

      img.onload = function() {
        if(img.width > max_width || img.height > max_height){
          if(img.width > img.height){
            ratio = img.width/img.height;
            c.width = max_width;     // update canvas size to match image
            c.height = max_width / ratio;
          }
          else{
            ratio = img.height/img.width;
            c.height = max_height;
            c.width = max_height / ratio;
          }
        }
        else{
          c.width = img.width;     // update canvas size to match image
          c.height = img.height;
          quality = 1;
        }
        
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, c.width, c.height);      // draw in image

        c.toBlob(function(blob) {        // get content as JPEG blob
          // here the image is a blob
          //append image file
          data.append("imageFile", blob, "image.jpg");

          
          data.append("slot", slot);
          data.append("bIsKeyVisual", false);
          data.append(
            "vehiclePhotosToVehicle",
            localStorage.getItem("selectedVehicle")
          );

        if(index){
          axios //authenticated get request
              .put(axios.defaults.baseURL + "vehiclePhotos/" + this.vehicleImages[index].id + "/", data)
              .then(e => console.log(e))
              .catch((error) => {
                  if (error.response == undefined) {
                    this.errorOn = true
                  }
                })
        }
        else{
          axios //authenticated get request
              .post(axios.defaults.baseURL + "vehiclePhotos/", data)
              .then(e => console.log(e))
              .catch((error) => {
                  if (error.response == undefined) {
                    this.errorOn = true
                  }
                })
        }

        }.bind(this), "image/jpeg", quality);
      }.bind(this);
      img.src = URL.createObjectURL(file);

  },
  },


  updated(){
    if(this.hasImages.indexOf(false) == -1){
      this.$emit("input", true)
    }
  },

  created() {

    if(this.$route.path == "/stock/vehicle/" + this.$route.params.vin + "/pictures"){
      this.hideInputIcons = true

      axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "imageSearchByVehicle/?vin=" +
          localStorage.getItem("selectedVehicle")
      )
      .then((response) => {
        this.rawImages = response.data;

        var slot;
        var index;
        for (var i = 0; i < response.data.length; i++) {
          slot = response.data[i].slot;
          index = this.slots.indexOf(slot);

          if (index != -1){
            this.images[index] = response.data[i].imageFile;
          }
        }

        this.$forceUpdate();
      })
      .catch((error) => console.log(error));
    }
      else{
        axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "reportSearchByVehicle/?vin=" +
          localStorage.getItem("selectedVehicle")
      )
      .then((resp) => {
        this.report= parseInt(resp.data[0].id)
        
        axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "imageSearchByReport/?vin=" +
          localStorage.getItem("selectedVehicle")
      )
      .then((response) => {
        this.rawImages = response.data;

        var slot;
        var index;
        for (var i = 0; i < response.data.length; i++) {
          slot = response.data[i].slot;
          index = this.slots.indexOf(slot);

          if (index != -1) {
            this.images[index] = response.data[i].imageFile;
            this.hasImages[index] = true
          }
        }

        this.$forceUpdate();
      })
      .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
      }
  },
};
</script>
