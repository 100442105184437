<template>
  <div id="brakeCondition">
    <v-form ref="brakeForm">
      <v-expansion-panels class="pa-0 ma-0" flat :value="panel">
        <v-expansion-panel class="target" expand v-model="panel">
          <v-expansion-panel-header
            disable-icon-rotate
            class="px-9 py-0"
          >
            <div class="statusPreIcon">
              <v-icon v-if="displayPlus" color="secondary"> mdi-plus </v-icon>
              <v-icon v-else color="secondary"> mdi-minus </v-icon>
            </div>

            <h4 height="32px">Statuserfassung</h4>
            <template v-slot:actions>
              <v-icon color="teal" v-if="bIsFinished == true">
                mdi-check
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid class="pa-0 ma-0 px-2">
              <p class="pb-0 mb-0">Bremstyp</p>
              <v-row>
                <v-col cols="8">
                  <v-slider
                    v-model="bIsCarbon"
                    max="1"
                    min="-1"
                    :value="bIsCarbon"
                    :rules="[(v) => v != 0]"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['Carbon', '', 'Stahl']"
                    v-on:change="validateBoolAndPush(bIsCarbon, 'bIsCarbon')"
                  ></v-slider>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    @click="
                      fetchData(2);
                    "
                    color="primary"
                    block
                    ><v-icon>mdi-content-copy</v-icon></v-btn
                  >
                </v-col>
              </v-row>

              <v-row>
                <v-col :cols="colWidth">
                  <p class="pb-0 mb-0">Bremsscheibe Verschleiß</p>
                  <v-text-field
                    required
                    single-line
                    :rules="rules"
                    :items="wearData"
                    v-model="wear"
                    class="pt-0 mt-0"
                    label="in %"
                    :disabled="bIsCarbon == 0"
                    :value="wear"
                    v-on:change="validateBasicAndPush(wear, 'wear')"
                  ></v-text-field>
                </v-col>
                <v-col :cols="colWidth">
                  <p class="pb-0 mb-0">Bremsbeläge Stärke</p>
                  <v-text-field
                    single-line
                    :rules="rules"
                    :items="brakePadData"
                    label="in mm"
                    v-model="brakePadThickness"
                    class="pt-0 mt-0"
                    :value="brakePadThickness"
                    v-on:change="
                      validateBasicAndPush(
                        brakePadThickness,
                        'brakePadThickness'
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row> </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>

    <errorPopup :on="errorOn" :data="'Statuserfassung Bremse'" />
  </div>
</template>

<script>
import errorPopup from "../ErrorPopup.vue";

const axios = require("axios");

export default {
  name: "brakeCondition",
  props: ["partGroupID"],

  data() {
    return {
      errorOn: false,

      brakeConditionID: undefined,
      statusCollectionID: undefined,

      brakePadThickness: undefined,
      wear: undefined,
      bIsCarbon: undefined,

      brakePadData: [],
      wearData: [],

      bIsFinished: false,
      panel: undefined,
      displayPlus: false,

      rules: [(v) => !!v],

      brakeData: [],
      dataAvailable: false,
    };
  },

  components: {
    errorPopup,
  },

  watch: {
    bIsCarbon: function (val) {
      var i;
      if (val == 1) {
        this.wearData = [];
        for (i = 0; i < 101; i += 5) this.wearData.push(i);
      } else if (val == -1) {
        this.wearData = [];
        for (i = 0; i < 101; i++) this.wearData.push(i);
      }
    },
  },

  computed: {
    colWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 12;
        case "lg":
          return 12;
        default:
          return 6;
      }
    },
  },

  methods: {
    pushToDB(val, valName) {
      axios //authenticated get request
        .put(
          axios.defaults.baseURL +
            "brakeConditions/" +
            this.brakeConditionID +
            "/",
          {
            [valName]: val,
            statusToBrakeConditions: this.statusCollectionID,
            location: this.partGroupID,
          }
        )
        .catch((error) => {
          if (error.response == undefined) {
            this.errorOn = true;
          }
        });
    },
    validateBasicAndPush(val, valName) {
      if (val != undefined) {
        this.pushToDB(val, valName);
      }
    },
    validateBoolAndPush(val, valName) {
      if (val != 0) {
        val == -1
          ? this.pushToDB(true, valName)
          : this.pushToDB(false, valName);
      }
    },
    fetchData(type = 0) {
      /**
       * types:
       *  0 - dont call useData()
       *  1 - call useData() only for bIsCarbon
       *  2 - call useData() for all available data
       */
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "simpleStatusColByVehicle/?vin=" +
            localStorage.getItem("selectedVehicle")
        )
        .then((response) => {
          this.statusCollectionID = response.data[0].id;

          var dataPre = [];
          var locString = undefined; //H or V depending on brake location
          var j;

          for (j = 0; j < response.data[0].brakeCollection.length; j++) {
            if (
              response.data[0].brakeCollection[j].location.id == this.partGroupID
            ) {
              this.brakeConditionID = response.data[0].brakeCollection[j].id;
              locString = response.data[0].brakeCollection[j].location.name
                .split(" ")[1]
                .charAt(0);
            }
            else {
              if (response.data[0].brakeCollection[j].bIsFinished == true) {
                this.dataAvailable = true;
                dataPre.push(response.data[0].brakeCollection[j]);
              }
            }
          }

          this.brakeData = []
          //sort dataPre array depending on locstring
          if (!this.bIsFinished) {
            for (j = 0; j < dataPre.length; j++) {
              if (
                dataPre[j].location.name.split(" ")[1].charAt(0) == locString
              ) {
                this.brakeData.splice(0, 0, dataPre[j]);
              } else {
                  this.brakeData.push(dataPre[j]);
              }
            }

           if(this.brakeData.length > 0)
            this.brakeData = this.brakeData[0];
          }

          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "brakeConditions/" +
                this.brakeConditionID +
                "/"
            )
            .then((resp) => {

              // if(this.bIsCarbon == 0){
                if (resp.data.bIsCarbon == undefined || resp.data.bIsCarbon == null) this.bIsCarbon = 0;
                else if (resp.data.bIsCarbon == true) this.bIsCarbon = -1;
                else this.bIsCarbon = 1;
              // }

              this.brakePadThickness = resp.data.brakePadThickness;

              this.wear = resp.data.wear

              //put data into code depending on type
              switch(type){
                case 0: break;
                case 1: this.useData("basic"); break;
                case 2: this.useData("extended"); break;
              }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    useData(type = "") {
      if (this.brakeData != undefined) {
        axios //authenticated get request
          .get(
            axios.defaults.baseURL + "brakeConditions/" + this.brakeData.id + "/"
          )
          .then((response) => {
            var k;

            if(response.data.bIsCarbon == true){
              this.bIsCarbon = -1
            }
            else if(response.data.bIsCarbon == false){
              this.bIsCarbon = 1
            }

            if(type != ""){
              if (this.bIsCarbon == 1) {
                this.wearData = [];
                for (k = 0; k < 101; k += 5) this.wearData.push(k);
              } else if (this.bIsCarbon == -1) {
                this.wearData = [];
                for (k = 0; k < 101; k++) this.wearData.push(k);
              }
            
              if(type == "extended"){
                this.wear = response.data.wear
                this.brakePadThickness = response.data.brakePadThickness

                this.validateBasicAndPush(this.brakePadThickness, "brakePadThickness");
                this.validateBasicAndPush(this.wear, "wear");
                this.validateBoolAndPush(this.bIsCarbon, "bIsCarbon");
              }
            }
          })
          .catch((error) => console.log(error));
      }
    },
    initialise() {
      this.panel = 0;
    },
  },

  mounted() {
    this.$refs.brakeForm.validate()
    
    var i;
    for (i = 1; i < 16; i++) this.brakePadData.push(i);

    this.initialise();
    this.fetchData(1)
  },

  updated() {
    if (this.bIsCarbon != 0 && this.wear != undefined && this.brakePadThickness != undefined && this.bIsFinished != true) {
      axios //authenticated get request
        .put(
          axios.defaults.baseURL +
            "brakeConditions/" +
            this.brakeConditionID +
            "/",
          {
            statusToBrakeConditions: this.statusCollectionID,
            location: this.partGroupID,

            brakePadThickness: this.brakePadThickness,
            wear: this.wear,
            bIsCarbon: this.bIsCarbon == 1? false : true
          }
        )
        .then(() => {
          setTimeout(() => {
          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "brakeConditions/" +
                this.brakeConditionID +
                "/",
            )
            .then((resp) => {
              var finished = true

              if(resp.data.brakePadThickness == null) finished = false
              if(resp.data.wear == null) finished = false
              if(resp.data.bIsCarbon == null) finished = false

              if(finished == true){
                this.bIsFinished = true
                this.$emit("input", true)

                axios //authenticated get request
                .put(
                  axios.defaults.baseURL +
                    "brakeConditions/" +
                    this.brakeConditionID +
                    "/",
                  {
                    statusToBrakeConditions: this.statusCollectionID,
                    location: this.partGroupID,
                    bIsFinished: true
                  }
                )
                .catch((error) => {
                  if (error.response == undefined) {
                    this.errorOn = true
                }})
              }
              }, 1000);
        })})
        .catch((error) => {
          if (error.response == undefined) {
            this.errorOn = true;
          }
        });
    }
  },
};
</script>