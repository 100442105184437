<template>
  <div id="searchBar">
    <v-text-field
      class="searchBar"
      color="tertiary"
      clearable
      label="Suche"
      single-line
      
      flat
      prepend-inner-icon="mdi-magnify"
      v-model="searchQueue"
      @click:clear="onClearClicked"
    >
    </v-text-field>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "searchBar",

  data() {
    return {
      searchQueue: "",
      searchResults: [],
    };
  },

  methods: {
    onClearClicked(){
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    }
  },

  watch: {
  searchQueue: function() {
    if (this.searchQueue && this.searchQueue.length >= 2) {
      this.searchResults = []
      
      axios //authenticated get request
      .get(
        axios.defaults.baseURL + "model/search/?search=" + this.searchQueue
      )
      .then((response) => {
        this.searchResults = this.searchResults.concat(response.data);

        this.$emit("input", this.searchResults);

        if (this.$route.path != "/stock/search")
          this.$router.push("/stock/search");
      })
      .catch((error) => console.log(error));

      axios //authenticated get request
        .get(
          axios.defaults.baseURL + "stock/search/?search=" + this.searchQueue
        )
        .then((response) => {
        
          this.searchResults = this.searchResults.concat(response.data)

          this.$emit("input", this.searchResults);

          if (this.$route.path != "/stock/search")
            this.$router.push("/stock/search");
        })
        .catch((error) => console.log(error));
    }
    else if(this.searchQueue.length == 0){
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/stock");
    }
  },
  },

};
</script>
