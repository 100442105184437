<template>
  <div id="modelCard">
    <v-card class="mx-4 rounded-xl" @click="updateStockView" :disabled="disabled">
      <v-img class="white--text align-end text-right" height="200px" :src="imageFile">
        
      </v-img>

      <v-card-title text-no-wrap text-truncate>
        {{ name }}
        <v-spacer />
        <v-icon v-if="needPricing" id="alertIcon" small
          >mdi-currency-eur</v-icon
        >
        <v-icon v-if="needAssessment" id="alertIcon" small>mdi-tools</v-icon>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text text-no-wrap text-truncate>
        Vorhandene Fahrzeuge: {{ count }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "modelCard",
  props: [
    "name",
    "imageFile",
    "count",
    "disabled",
    "needAssessment",
    "needPricing",
  ],

  data() {
    return {
      bIsDisabled: this.count == 0 ? true : false,
    };
  },

  methods: {
    updateStockView() {
      this.$emit("input", this.name);
    },
  },
};
</script>