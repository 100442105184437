<template>
  <div>
    <v-container fluid>
      <v-row class="pb-2 mb-0">
        <v-list-item three-line  class="py-0">
          <v-list-item-content>
            <v-list-item-subtitle>
              <h3>Schritt {{ stepNumber }}</h3>
            </v-list-item-subtitle>
            <v-list-item-title>
              <h2>{{ readableTitle(title) }}</h2>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-row>
      <v-row justify="center" class="py-0 my-0">
        <v-col :cols="calcCols" class="py-0">
          <baseCondition v-if="title == 'Innenraum'" v-model="emitUpdate" />
          <tireCondition
            v-if="title.includes('Reifen')"
            :partGroupID="partGroup.id"
            :refetch="notifyTireCond"
            v-model="emitUpdate"
          />
          <brakeCondition
            v-if="title.includes('Bremse')"
            :partGroupID="partGroup.id"
            v-model="emitUpdate"
          />
          <suspensionCondition
            v-if="title.includes('Fahrwerk')"
            :partGroupID="partGroup.id"
            v-model="emitUpdate"
          />
          <toolCondition
            v-if="title.includes('klappe')"
            :partGroupID="partGroup.id"
            v-model="emitUpdate"
          />

          <v-list-item three-line class="pb-0 pt-2">
            <v-list-item-content id="test" class="py-0">
                <v-list-item
                  class="pb-1"
                  v-for="(element, index) in parts"
                  :key="element.name"
                >
                  <v-btn
                    block
                    depressed
                    elevation="2"
                    color="primary"
                    @click="createNewDamage(index)"
                    >{{ element.name }}</v-btn
                  >
                </v-list-item>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col :cols="12 - calcCols">
          <v-img :src="imageFile" class="pa-0 ma-0" v-if="showButtons">
            <div v-for="(element, index) in parts" :key="element.name">
              <v-btn
                id="assessmentIcon"
                v-if="
                  getLeftOffset(element.name) != 0 &&
                  getTopOffset(element.name) != 0
                "
                depressed
                color="rgba(0,0,0,0)"
                absolute
                icon
                class="ma-0 pa-0 ml-n4"
                @click="createNewDamage(index)"
                :style="
                  'left:' +
                  getLeftOffset(element.name) +
                  '%; top:' +
                  getTopOffset(element.name) +
                  '%'
                "
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </div>
          </v-img>
          <v-img :src="imageFile" class="pa-0 ma-0" v-else> </v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay v-if="bShowOverlay" :value="bShowOverlay">
      <overlay
        v-model="bShowOverlay"
        :title="'Schadenserfassung'"
        :type="'damage'"
        :data="[part, partGroup, reportID]"
      />
    </v-overlay>
  </div>
</template>

<script>
const axios = require("axios");
const json = require("../assets/data/partGroupData.json");

import overlay from "./Overlays/Overlay.vue";
import baseCondition from "./StatusCollection/BaseCondition";
import brakeCondition from "./StatusCollection/BrakeCondition";
import tireCondition from "./StatusCollection/TireCondition";
import suspensionCondition from "./StatusCollection/SuspensionCondition";
import toolCondition from "./StatusCollection/ToolCondition";

export default {
  name: "assessmentTemplate",
  props: ["title", "stepNumber", "reportID", "partGroup", "notifyTireCond"],

  components: {
    overlay,
    baseCondition,
    brakeCondition,
    tireCondition,
    suspensionCondition,
    toolCondition,
  },

  data() {
    return {
      parts: [],
      bShowOverlay: false,
      emitUpdate: false,

      part: undefined,
      imageFile: undefined,
    };
  },

  watch: {
    bShowOverlay: function () {
      if (this.bShowOverlay == false) {
        this.$emit("input", -1);
      }
    },
    emitUpdate: function (val) {
      if (val == true) {
        this.$emit("input", this.partGroup.id);
        this.emitUpdate = false;
      }
    },
  },

  computed: {
    calcCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "12";
        case "sm":
          return "12";
        case "md":
          return "4";
        case "lg":
          return "4";
        case "xl":
          return "3";
        default:
          return "3";
      }
    },
    showButtons() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return true;
        default:
          return true;
      }
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    createNewDamage(index) {
      this.part = this.parts[index];
      this.bShowOverlay = true;
    },
    readableTitle(val) {
      var temp = val.split(" ");

      return temp[0];
    },
    getLeftOffset(val) {
      var partsArray = json[this.title];
      if (partsArray) {
        var part = partsArray[val];
        if (part) return part[0];
      }

      return 0;
    },
    getTopOffset(val) {
      var partsArray = json[this.title];
      if (partsArray) {
        var part = partsArray[val];
        if (part) return part[1];
      }

      return 0;
    },
  },

  mounted() {
    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "vehiclePartByGroup/?name=" +
          this.partGroup.name
      )
      .then((response) => {
        this.parts = response.data;

        var tempArray = [];
        var temp;

        for (var i = 0; i < this.parts.length; i++) {
          if (this.parts[i].name == "Sonstiges") {
            temp = this.parts[i];
          } else {
            tempArray.push(this.parts[i]);
          }
        }

        if (temp) {
          tempArray.push(temp);
        }

        this.parts = tempArray;
      })
      .catch((error) => console.log(error));

    axios //authenticated get request
      .get(
        axios.defaults.baseURL + "vehiclePartGroups/" + this.partGroup.id + "/"
      )
      .then((response) => {
        this.imageFile = response.data.imageFile;
      })
      .catch((error) => console.log(error));
  },
};
</script>