<template>
  <div id="documents" class="mx-6">
    <!----- Papiere -->
    <v-container fluid class="pa-0">
      <v-row>
        <v-col>
          <h2>Fahrzeugdokumente</h2>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-icon @click="enableOverlay" class="icon-off" id="plusIcon"
            >mdi-plus-box</v-icon
          >
        </v-col>
      </v-row>
    </v-container>
    <v-divider />

    <v-overlay :value="overlay">
      <overlay v-model="overlay" :title="'Neues Dokument'" :type="'document'" />
    </v-overlay>

    <v-overlay :value="editOverlay">
      <overlay
        v-model="editOverlay"
        :title="'Dokument editieren'"
        :type="'document'"
        :data="selectedDocument"
      />
    </v-overlay>

    <v-container fluid class="pb-8">
      <v-divider v-if="documents.length > 0" />
      <div v-if="documents.length != 0">
        <div v-for="document in documents" :key="document.date">
          <v-row dense>
            <v-col cols="12">
              <v-card
                class="mx-auto text--primary text-no-wrap text-truncate"
                flat
              >
                <v-container fluid>
                  <v-row>
                    <v-col cols="8" v-if="!breakLine" class="pb-0">
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title class="mb-3">
                            <h3>{{ document.name }}</h3>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            erstellt von
                            {{
                              document.documentsToUser.first_name +
                              " " +
                              document.documentsToUser.last_name +
                              " am " +
                              humanReadableDate(document.created)
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="8" v-else>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title class="mb-3">
                            <h3>{{ document.name }}</h3>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            erstellt von
                            {{
                              document.documentsToUser.first_name +
                              " " +
                              document.documentsToUser.last_name +
                              " am " +
                              humanReadableDate(document.created)
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-spacer />
                    <v-col class="pt-0 pl-2" block cols="12" v-if="breakLine">
                      <v-card-actions>
                        <v-dialog v-model="dialog" :value="dialog" width="500">
                          <template v-slot:activator="{ on }">
                            <v-icon medium v-on="on" class="px-2"
                              >mdi-delete</v-icon
                            >
                          </template>

                          <v-card>
                            <v-card-title class="headline grey lighten-2">
                              Dokument wirklich löschen?
                            </v-card-title>

                            <v-divider></v-divider>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="primary"
                                text
                                @click="dialog = false"
                              >
                                Nein
                              </v-btn>
                              <v-btn
                                color="primary"
                                text
                                @click="deleteDocument(document)"
                              >
                                Ja
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-icon
                          medium
                          class="px-2"
                          @click="editDocument(document)"
                          >mdi-file-document-edit-outline</v-icon
                        >
                        <v-icon
                          class="px-2"
                          medium
                          @click="openDocument(document.document)"
                          >mdi-book-open-outline
                        </v-icon>
                      </v-card-actions>
                    </v-col>
                    <v-col class="pt-8" block cols="3" v-else>
                      <v-card-actions>
                        <v-spacer />
                        <v-dialog v-model="dialog" :value="dialog" width="500">
                          <template v-slot:activator="{ on }">
                            <v-icon medium v-on="on" class="px-2"
                              >mdi-delete</v-icon
                            >
                          </template>

                          <v-card>
                            <v-card-title class="headline grey lighten-2">
                              Dokument wirklich löschen?
                            </v-card-title>

                            <v-divider></v-divider>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="primary"
                                text
                                @click="dialog = false"
                              >
                                Nein
                              </v-btn>
                              <v-btn
                                color="primary"
                                text
                                @click="deleteDocument(document)"
                              >
                                Ja
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-icon
                          medium
                          class="px-2"
                          @click="editDocument(document)"
                          >mdi-file-document-edit-outline</v-icon
                        >
                        <v-icon
                          class="px-2"
                          medium
                          @click="openDocument(document.document)"
                          >mdi-book-open-outline
                        </v-icon>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-divider />
        </div>
      </div>
      <div v-else>
        <v-row dense>
          <p>Keine Dokumente vorhanden!</p>
        </v-row>
      </div>
    </v-container>

    <!----- Gutachten -->
    <h2>Bewertung</h2>
    <v-divider />
    <v-container fluid>
      <div v-if="reports.length != 0">
        <v-divider />
        <div v-for="(report, index) in reports" :key="report.date">
          <v-row dense>
            <v-col cols="12">
              <v-card
                class="mx-auto text--primary text-no-wrap text-truncate"
                flat
              >
                <v-container fluid class="fill-height">
                  <v-row justify="center" align="center">
                    <v-col cols="9" v-if="!breakLine" class="pb-0">
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="pb-1">Berwertung vom</div>
                          <v-list-item-title class="mb-3">
                            <v-container>
                              <v-row>
                                <h3>{{ humanReadableDate(report.created) }}</h3>
                                <div v-if="index==0">
                                  <v-icon v-if="!vehicle.bFinishedReport" x-small style="color:red; padding-left: 5px">mdi-tools</v-icon>
                                  <v-icon v-if="!vehicle.bFinishedPricing" x-small style="color:red;">mdi-currency-eur</v-icon>
                                </div>
                              </v-row>
                            </v-container>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            erstellt von
                            {{
                              report.reportsToUser.first_name +
                              " " +
                              report.reportsToUser.last_name
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="9" v-else>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="pb-1">Berwertung vom</div>
                          <v-list-item-title class="mb-3">
                            <v-container>
                              <v-row>
                                <h3>{{ humanReadableDate(report.created) }}</h3>
                                <div v-if="index==0">
                                  <v-icon v-if="!vehicle.bFinishedReport" x-small style="color:red; padding-left: 5px">mdi-tools</v-icon>
                                  <v-icon v-if="!vehicle.bFinishedPricing" x-small style="color:red;">mdi-currency-eur</v-icon>
                                </div>
                              </v-row>
                            </v-container>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            erstellt von
                            {{
                              report.reportsToUser.first_name +
                              " " +
                              report.reportsToUser.last_name
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-spacer />
                    <v-col v-if="!breakLine" cols="auto" class="pr-3">
                      <v-card-actions>
                        <v-spacer />
                        <v-icon class="pr-2" disabled style="color:rgb(201, 201, 201) !important;" v-if="index==0 && (vehicle != undefined) && (!vehicle.bFinishedReport)" medium @click="createPDF(report)"
                          >mdi-book-open-outline</v-icon
                        >
                        <v-icon v-else medium @click="createPDF(report.id)" class="pr-2"
                          >mdi-book-open-outline</v-icon
                        >
                      </v-card-actions>
                    </v-col>
                    <v-col v-else cols="12" class="pl-5 pt-0">
                      <v-card-actions class="pt-0">
                        <v-icon disabled style="rgb(201, 201, 201) !important;" v-if="index==0 && (vehicle != undefined) && (!vehicle.bFinishedReport)" medium
                          >mdi-book-open-outline</v-icon
                        >
                        <v-icon v-else medium @click="createPDF(report.id)"
                          >mdi-book-open-outline</v-icon
                        >
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-divider />
        </div>
      </div>
      <div v-else>
        <v-row dense>
          <p>Keine Bewertungen vorhanden!</p>
        </v-row>
      </div>
    </v-container>

    <div :visible="loading == 0 ? false : true" style="margin-top: 900px"></div>

    <!-- <assessmentPDF
      :printPDF="printPDF"
      :requestNumber="requestNumber"
      v-model="loading"
    /> -->

    <v-snackbar
      v-model="snackbar"
      color="primary"
      top
      multi-line
      min-height="80px"
    >
      <h5 class="black--text">Der Download wird vorbereitet. <br>Bitte einen Moment gedulden.</h5>

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Ok!
        </v-btn>
      </template>
    </v-snackbar>
        
    <v-dialog :show="loading<0" width="500">
      <!-- <template v-slot:activator="{ on }">
        <v-btn block depressed color="primary" v-on="on">
          <v-icon id="damageIcon" color="black" medium
            >mdi-trash-can-outline</v-icon
          >
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Dokument konnte nicht geladen werden. Bitte prüfen Sie Ihr Internet und versuchen es später erneut.
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="loading=0"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <errorPopup :on="errorOn" :data="'Dokumenten Upload'" />
  </div>
</template>

<script>
// import jsPDF from "jspdf";
import overlay from "../components/Overlays/Overlay.vue";
// import assessmentPDF from "./AssessmentPDF.vue";

import errorPopup from "../components/ErrorPopup.vue";

const axios = require("axios");
export default {
  name: "documents",

  data() {
    return {
      snackbar: false,
      errorOn: false, 

      reports: [],
      documents: [],
      overlay: false,
      editOverlay: false,
      selectedDocument: undefined,
      dialog: false,

      printPDF: undefined,
      requestNumber: 0,
      loading: 0,

      vehicle: undefined,
    };
  },

  components: {
    overlay,
    errorPopup,
  },

  computed: {
    breakLine() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },

  watch: {
    overlay: function (val) {
      if (!val) {
        axios //authenticated get request
          .get(
            axios.defaults.baseURL +
              "documentSearchByVehicle/?vin=" +
              localStorage.getItem("selectedVehicle")
          )
          .then((response) => {
            this.documents = response.data;
          })
          .catch((error) => console.log(error));
      }
    },
    editOverlay: function (val) {
      if (!val) {
        axios //authenticated get request
          .get(
            axios.defaults.baseURL +
              "documentSearchByVehicle/?vin=" +
              localStorage.getItem("selectedVehicle")
          )
          .then((response) => {
            this.documents = response.data;
          })
          .catch((error) => console.log(error));
      }
    },
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    deleteDocument(source) {
      var finalDoc = new FormData();

      finalDoc.append("id", source.id);
      finalDoc.append("deleted", true);
      finalDoc.append(
        "documentsToDocumentType",
        source.documentsToDocumentType.name
      );
      finalDoc.append("documentsToVehicle", source.documentsToVehicle);
      finalDoc.append("name", source.name);

      axios //authenticated get request
        .put(axios.defaults.baseURL + "documents/" + source.id + "/", finalDoc)
        .then(() => {
          this.fetchDocuments();
        })
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
                this.loading = false
              }
            })
    },
    editDocument(source) {
      if (!this.editOverlay) {
        this.selectedDocument = source;
        this.editOverlay = true;
      }
    },
    openDocument(source) {
      window.open(source, "_blank");
    },
    humanReadableDate(date) {
      var dateArray = date.split("T");
      dateArray = dateArray[0].split("-");

      return dateArray[2] + "." + dateArray[1] + "." + dateArray[0];
    },
    enableOverlay() {
      if (!this.overlay) this.overlay = true;
    },
    disableOverlay() {
      if (this.overlay) this.overlay = false;
    },
    createPDF(report) {
      // this.loading = 1;
      // this.printPDF = report;
      // ++this.requestNumber;
      this.$router.push("/documents/" + report)
    },
    fetchReports() {
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "reportSearchByVehicle/?vin=" +
            localStorage.getItem("selectedVehicle")
        )
        .then((response) => {
          this.reports = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchDocuments() {
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "documentSearchByVehicle/?vin=" +
            localStorage.getItem("selectedVehicle")
        )
        .then((response) => {
          this.documents = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchVehicle(){
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "vehicles/" +
            localStorage.getItem("selectedVehicle") + "/"
        )
      .then((response) => {
          this.vehicle = response.data;

          this.$forceUpdate()
        })
        .catch((error) => console.log(error));
    }
  },

  created() {
    this.fetchReports();

    this.fetchDocuments();

    this.fetchVehicle();
  },

  mounted(){
    if(this.$route.params.alert == 1){
      this.snackbar = true;
    }
  }
};
</script>