<template>
  <div id="baseCondition">
    <v-expansion-panels class="pa-0 ma-0" flat :value="panel">
    <v-expansion-panel expand  @click="changePlusIcon()">
      <v-expansion-panel-header disable-icon-rotate class="px-9 py-0">
        <div class="statusPreIcon">
              <v-icon v-if="displayPlus" color="secondary">
                mdi-plus
              </v-icon>
              <v-icon v-else color="secondary">
                mdi-minus
              </v-icon>
              </div>
        <h4 height="32px">Statuserfassung</h4>
        <template v-slot:actions> 
          <v-icon color="teal" v-if="bIsFinished==true"> mdi-check </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        >
        <v-form ref="baseForm">
          <v-container class="py-0">
            <v-row v-if="!checkModelAge()">
              <v-col cols="12" class="py-0">
                <p class="ma-0">Kilometerstand</p>
                <v-text-field
                  class="pa-0"
                  v-model="mileage"
                  :value="mileage"
                  :rules="rules"
                  required
                  v-on:change="validateIntAndPush(mileage, 'mileage')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="6" class="py-0">
                <p class="ma-0">Kilometerstand</p>
                <v-text-field
                  class="pa-0"
                  v-model="mileage"
                  :value="mileage"
                  :rules="rules"
                  required
                  v-on:change="validateIntAndPush(mileage, 'mileage')"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <p class="ma-0">Getriebe Verschleiß</p>
                <v-text-field
                  class="pa-0"
                  v-model="getriebe"
                  :value="getriebe"
                  :rules="rules"
                  required
                  v-on:change="validateIntAndPush(getriebe, 'getriebe')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <p class="ma-0">TPMS VL (optional)</p>
                <v-text-field
                  class="pa-0"
                  v-model="tpms_vl"
                  :value="tpms_vl"
                  :rules="tpms_rules"
                  v-on:change="validateIntAndPush(tpms_vl, 'tpms_vl')"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <p class="ma-0">VR</p>
                <v-text-field
                  class="pa-0"
                  v-model="tpms_vr"
                  :value="tpms_vr"
                  :rules="tpms_rules"
                  v-on:change="validateIntAndPush(tpms_vr, 'tpms_vr')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <p class="ma-0">HL</p>
                <v-text-field
                  class="pa-0"
                  v-model="tpms_hl"
                  :value="tpms_hl"
                  :rules="tpms_rules"
                  v-on:change="validateIntAndPush(tpms_hl, 'tpms_hl')"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <p class="ma-0">HR</p>
                <v-text-field
                  class="pa-0"
                  v-model="tpms_hr"
                  :value="tpms_hr"
                  :rules="tpms_rules"
                  v-on:change="validateIntAndPush(tpms_hr, 'tpms_hr')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-2">
                <p class="ma-0">Zweitschlüssel</p>
                <v-slider
                  v-model="secondKey"
                  max="1"
                  min="-1"
                  value="0"
                  :rules="boolRules"
                  color="secondary"
                  track-color="secondary"
                  thumb-color="primary"
                  ticks="always"
                  :tick-labels="['ja', '', 'nein']"
                  v-on:change="validateBoolAndPush(secondKey, 'zweitschlüssel')"
                ></v-slider>
              </v-col>
              <v-col cols="6" class="py-2">
                <p class="ma-0">FB AKS</p>
                <v-slider
                  v-model="fbaks"
                  max="1"
                  min="-1"
                  value="0"
                  :rules="boolRules"
                  color="secondary"
                  track-color="secondary"
                  thumb-color="primary"
                  ticks="always"
                  :tick-labels="['ja', '', 'nein']"
                  v-on:change="validateBoolAndPush(fbaks, 'fbaks')"
                ></v-slider>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider />
      </v-expansion-panel-content>
    </v-expansion-panel>
    </v-expansion-panels>

    <errorPopup :on="errorOn" :data="'Statuserfassung Innenraum'" />
  </div>
</template>

<script>
import errorPopup from "../ErrorPopup.vue";

const axios = require("axios");
export default {
  name: "baseCondition",

  data() {
    return {
      errorOn: false, 

      mileage: undefined,
      getriebe: undefined,
      secondKey: -1,
      fbaks: -1,
      tpms_vl:undefined,
      tpms_vr:undefined,
      tpms_hl:undefined,
      tpms_hr:undefined,

      baseConditionID: undefined,
      statusCollectionID: undefined,

      bIsFinished: false,
      panel: 0,
      displayPlus: false,

      rules: [(v) => (!!v && !isNaN(parseInt(v))) || "Gültige Zahl angeben!"],
      tpms_rules: [(v) => (!v || !isNaN(parseInt(v))) || "Gültige Zahl angeben!"],
      boolRules: [(v) => v != 0],

      model: undefined,
      oldModelNames: ["360 Modena | Stradale","360 Spider","458 Italia | Speciale","F 430 | Scuderia","599","612 Scaglietti","California","575 Superamerica","575m Maranello","Enzo Ferrari","F355 F1 Spider","FXX","550 Barchetta","F430 Spider | 16M"]
    };
  },

  components: {
    errorPopup,
  },

  methods: {
    checkModelAge(){
      if(this.oldModelNames.indexOf(this.model) >= 0){
        return true;
      }
      
      return false;
    },
    pushToDB(val, valName) {
      axios //authenticated get request
        .put(
          axios.defaults.baseURL +
            "baseConditions/" +
            this.baseConditionID +
            "/",
          {
            [valName]: val,
            statusToBaseConditions: this.statusCollectionID,
            location: this.partGroupID,
          }
        )
        .then(() => {
          axios //authenticated get request
            .put(
              axios.defaults.baseURL +
                "vehicles/" +
                localStorage.getItem("selectedVehicle") +
                "/",
              {
                mileage: parseInt(this.mileage),
                vin: localStorage.getItem("selectedVehicle"),
              }
            )
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
        })
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
    },
    validateBoolAndPush(val, valName) {
      if (val != 0) {
        val == -1
          ? this.pushToDB(true, valName)
          : this.pushToDB(false, valName);
      }
    },
    validateIntAndPush(val, valName) {
      if (val != undefined && parseInt(val)) {
        this.pushToDB(val, valName);
      }
    },
    initialize() {
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "simpleVehicle/" +
            localStorage.getItem("selectedVehicle")
        )
        .then((response) => {
          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "vehicleManufacturerModels/" +
                response.data.vehiclesToModel
            )
            .then((resp) => {
              this.model = resp.data.name
            })
        })


      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "simpleStatusColByVehicle/?vin=" +
            localStorage.getItem("selectedVehicle")
        )
        .then((response) => {
          this.baseConditionID = response.data[0].baseCollection[0].id;
          this.statusCollectionID = response.data[0].id;

          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "baseConditions/" +
                this.baseConditionID +
                "/"
            )
            .then((resp) => {
              if (resp.data.mileage) this.mileage = resp.data.mileage;
              if (resp.data.getriebe) this.getriebe = resp.data.getriebe;
              if (resp.data.tpms_vl) this.tpms_vl = resp.data.tpms_vl;
              if (resp.data.tpms_vr) this.tpms_vr = resp.data.tpms_vr;
              if (resp.data.tpms_hl) this.tpms_hl = resp.data.tpms_hl;
              if (resp.data.tpms_hr) this.tpms_hr = resp.data.tpms_hr;

              if (resp.data.zweitschlüssel == undefined) this.secondKey = 0;
              else if (resp.data.zweitschlüssel == true) this.secondKey = -1;
              else this.secondKey = 1;

              if (resp.data.fbaks == undefined) this.fbaks = 0;
              else if (resp.data.fbaks == true) this.fbaks = -1;
              else this.fbaks = 1;
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    changePlusIcon(){
      this.displayPlus = !this.displayPlus
    }
  },

  mounted() {
    this.$refs.baseForm.validate()
    this.initialize();
  },

  updated() {
    if (this.mileage != null && this.fbaks != 0 && this.secondKey != 0 && this.bIsFinished != true) {
      
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "baseConditions/" +
            this.baseConditionID +
            "/"
        )
        .then(() => {
          axios //authenticated get request
            .put(
              axios.defaults.baseURL +
                "baseConditions/" +
                this.baseConditionID +
                "/",
              {
                statusToBaseConditions: this.statusCollectionID,

                mileage: this.mileage,
                getriebe: this.getriebe,
                tpms_vl: this.tpms_vl,
                tpms_vr: this.tpms_vr,
                tpms_hl: this.tpms_hl,
                tpms_hr: this.tpms_hr,
                zweitschluessel: this.secondKey == 1 ? false : true,
                fbaks: this.fbaks == 1 ? false : true,
              }
            )
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
            .then(() => {
              setTimeout(() => {
                
              axios //authenticated get request
              .get(
                axios.defaults.baseURL +
                  "baseConditions/" +
                  this.baseConditionID +
                  "/",
              )
              .then((resp) => {
                var finished = true

                if(resp.data.mileage == null) finished = false
                if(resp.data.zweitschlüssel == null) finished = false
                if(resp.data.fbaks == null) finished = false

                if(finished == true){
                  this.bIsFinished = true
                  this.$emit("input", true);

                  axios //authenticated get request
                  .put(
                    axios.defaults.baseURL +
                      "baseConditions/" +
                      this.baseConditionID +
                      "/",
                    {
                      statusToBaseConditions: this.statusCollectionID,
                      bIsFinished: true
                    }
                  )
                  .catch((error) => {
                    if (error.response == undefined) {
                      this.errorOn = true
                  }})
                }
                }, 1000);
            })})
        });
    }
  },
};
</script>