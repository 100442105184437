<template>
  <div id="vehicleNav" class="whiteBG">
    <v-tabs
      class="vehicleNavBar"
      color="primary"
      background-color="accent"
      slider-color="primary"
      height="85px"
      show-arrows
      fixed-tabs
    >
      <v-tab>
        <v-badge
          v-if="!imageStatus"
          overlap
          absolute
          icon="mdi-exclamation"
          color="red"
        >
          <v-img
          class="outlinedRed"
          id="navBarImage"
          width="100px"
          :src="require(`../assets/icons/vehicleicon_camera.jpg`)"
        ></v-img>
        </v-badge>
        <v-img v-else
          id="navBarImage"
          width="100px"
          :src="require(`../assets/icons/vehicleicon_camera.jpg`)"
        ></v-img>
      </v-tab>

      <v-tab>
        <v-img
            class="navBarImage"
            id="navBarImage"
            width="100px"
            :src="require(`../assets/icons/vehicleicon_paint.jpg`)"
          ></v-img>
      </v-tab>

      <v-tab v-for="pg in partGroups" :key="pg.id" class="vehicleNavBar" ripple>
        <v-badge
          v-if="
            statusCollectionLocations.indexOf(pg.id) != -1 &&
            !statusCollectionStatus[statusCollectionLocations.indexOf(pg.id)]
          "
          overlap
          absolute
          icon="mdi-exclamation"
          color="red"
        >
          <v-img
            class="outlinedRed"
            id="navBarImage"
            width="100px"
            :src="pg.iconFile"
          >
          </v-img>
        </v-badge>
        <v-img v-else id="navBarImage" width="100px" :src="pg.iconFile">
        </v-img>
      </v-tab>
      <v-tab v-if="partGroups.length > 0" class="vehicleNavBar" ripple>
        <v-img
          id="navBarImage"
          width="100px"
          :src="require(`../assets/icons/vehicleicon_list.jpg`)"
        ></v-img>
      </v-tab>

      <v-tab-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-subtitle>
              <h3>Schritt 0</h3>
            </v-list-item-subtitle>
            <v-list-item-title>
              <h2>Fotos</h2>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-container class="pa-0 pt-0" fluid>
          <vehicleImages :rowScaling="0.82" v-model="imageStatus" />
        </v-container>
      </v-tab-item>

      <v-tab-item>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-subtitle>
              <h3>Schritt 1</h3>
            </v-list-item-subtitle>
            <v-list-item-title>
              <h2>Lackschichtenmessung</h2>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-container class="pa-0 pt-0" fluid>
          <paintCondition :partGroups="partGroups" />
        </v-container>
      </v-tab-item>

      <v-tab-item
        v-for="(partGroup, index) in partGroups"
        :key="partGroup.name"
        :index="index"
      >
        <assessmentTemplate
          :title="partGroup.name"
          :imageFile="partGroup.imageFile"
          :stepNumber="index + 2"
          :reportID="reportID"
          :notifyTireCond="notifyTireCond"
          :partGroup="partGroup"
          v-model="emitUpdate"
        />
      </v-tab-item>

      <v-tab-item v-if="partGroups.length > 0">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-subtitle>
              <h3>Schritt {{ partGroups.length + 1 }}</h3>
            </v-list-item-subtitle>
            <v-list-item-title>
              <h2>Schäden</h2>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <damages
          v-model="updateTabs"
          :reportButtonDisabled="!finishedStatusCols"
          :update="updateDamage"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
const axios = require("axios");
const json = require("../assets/data/vehicleTypeData.json");

import assessmentTemplate from "../components/AssessmentTemplate.vue";
import damages from "../views/Damages.vue";
import vehicleImages from "../views/VehicleImages.vue";
import paintCondition from "./StatusCollection/PaintCondition.vue"

export default {
  name: "vehicleNavBar",

  data() {
    return {
      notifyTireCond: false,

      partGroups: [],
      updateTabs: false,
      reportID: undefined,

      emitUpdate: false,
      updateDamage: false,

      statusCollectionLocations: [],
      statusCollectionStatus: [],
      imageStatus: false,
      finishedStatusCols: false,
    };
  },

  components: {
    assessmentTemplate,
    damages,
    vehicleImages,
    paintCondition
  },

  watch: {
    updateTabs: function (val) {
      if (val == true) {
        this.$emit("input", true);
      }
    },
    emitUpdate: function (val) {
      if (val == -1) {
        this.updateDamage = !this.updateDamage;
        this.emitUpdate = false;
      } else if (val >= 0) {
        var index = this.statusCollectionLocations.indexOf(val);
        this.$set(this.statusCollectionStatus, index, true);

        this.notifyTireCond = true;

        this.finishedStatusCols = true;
        for (var i = 0; i < this.statusCollectionStatus.length; i++) {
          if (this.statusCollectionStatus[i] == false) {
            this.finishedStatusCols = false;
            break;
          }
        }

        if(this.imageStatus == false){
          this.finishedStatusCols = false
        }

      }
    },
    imageStatus: function (val) {
      if (val == true) {
        this.finishedStatusCols = true;
        for (var i = 0; i < this.statusCollectionStatus.length; i++) {
          if (this.statusCollectionStatus[i] == false) {
            this.finishedStatusCols = false;
            break;
          }
        }

        if(this.imageStatus == false){
          this.finishedStatusCols = false
        }
      }
    },
  },

  mounted() {
    window.scrollTo(0,0);
    
    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "vehicles/" +
          localStorage.getItem("selectedVehicle") +
          "/"
      )
      .then((response) => {
        this.reportID = response.data.lastReport.id;
        this.$forceUpdate();

        var typeString = response.data.vehiclesToModel.vehicleManufacturerModelsToVehicleType.name
        localStorage.setItem("selectedVehicleType", typeString)

    //order vehicle part groups based on vehicle type json
    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "vehicleTypeByName/?name=" +
          typeString
      )
      .then((response) => {
        var names = [];
        var jsonGroups = json[typeString];
        var orderedGroups = [];
        var index;

        var insideId;
        var trunkId;

        for (var i = 0; i < response.data[0].vehicleTypesToVehiclePartGroups.length; i++) {
          names.push(response.data[0].vehicleTypesToVehiclePartGroups[i].name);

          if (response.data[0].vehicleTypesToVehiclePartGroups[i].name == "Innenraum")
            insideId = response.data[0].vehicleTypesToVehiclePartGroups[i].id;

          if (response.data[0].vehicleTypesToVehiclePartGroups[i].name.includes("klappe")
          )
            trunkId = response.data[0].vehicleTypesToVehiclePartGroups[i].id;
        }

        for (var j = 0; j < names.length; j++) {
          index = names.indexOf(jsonGroups[j]);

          if (index >= 0) {
            orderedGroups.push(
              response.data[0].vehicleTypesToVehiclePartGroups[index]
            );
          }
        }

        if (
          orderedGroups.length <
          response.data[0].vehicleTypesToVehiclePartGroups.length
        ) {
          for (
            var k = 0;
            k < response.data[0].vehicleTypesToVehiclePartGroups.length;
            k++
          ) {
            if (
              orderedGroups.indexOf(
                response.data[0].vehicleTypesToVehiclePartGroups[k]
              ) < 0
            ) {
              orderedGroups.push(
                response.data[0].vehicleTypesToVehiclePartGroups[k]
              );
            }
          }
        }

        this.partGroups = orderedGroups;

        //get basic data to determine wheater a status collection is finished or not
        axios //authenticated get request
          .get(
            axios.defaults.baseURL +
              "simpleStatusColByVehicle/?vin=" +
              localStorage.getItem("selectedVehicle")
          )
          .then((response) => {
            var i;
            for (i = 0; i < response.data[0].tireCollection.length; i++) {
              this.statusCollectionLocations.push(
                response.data[0].tireCollection[i].location.id
              );
              this.statusCollectionStatus.push(
                response.data[0].tireCollection[i].bIsFinished
              );
            }

            for (i = 0; i < response.data[0].suspCollection.length; i++) {
              this.statusCollectionLocations.push(
                response.data[0].suspCollection[i].location.id
              );
              this.statusCollectionStatus.push(
                response.data[0].suspCollection[i].bIsFinished
              );
            }

            for (i = 0; i < response.data[0].brakeCollection.length; i++) {
              this.statusCollectionLocations.push(
                response.data[0].brakeCollection[i].location.id
              );
              this.statusCollectionStatus.push(
                response.data[0].brakeCollection[i].bIsFinished
              );
            }

            this.statusCollectionLocations.push(insideId);
            this.statusCollectionStatus.push(
              response.data[0].baseCollection[0].bIsFinished
            );
            
            this.statusCollectionLocations.push(trunkId);
            this.statusCollectionStatus.push(
              
              response.data[0].toolCollection[0].bIsFinished
            );
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));


      })
      .catch((error) => console.log(error));
  },
};
</script>