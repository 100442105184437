<template>
  <div id="overlay">
    <v-form ref="form">
      <v-row class="py-6 pb-2 text-center">
        <v-spacer />
        <v-col :cols="cols" class="whiteBG rounded-lg">
          <v-text-field
            required
            :rules="pricingRules"
            label="Schadenshöhe"
            placeholder="Preis eintragen"
            v-model="dataCopy.cost"
          ></v-text-field>
        </v-col>
        <v-spacer />
      </v-row>

      <v-row class="py-1">
        <v-spacer />
        <v-col :cols="cols">
          <v-btn @click="save()" x-large color="primary" depressed block>
            Speichern & Schließen</v-btn
          >
        </v-col>
        <v-spacer />
      </v-row>
    </v-form>

    <errorPopup :on="errorOn" :data="'Bepreisung'" />
  </div>
</template>

<script>
import errorPopup from "../ErrorPopup.vue";

const axios = require("axios");

export default {
  name: "overlay",
  props: ["data"],

  data() {
    return {
      errorOn: false,

      pricingRules: [
        (v) => !!v || "Preis angeben!",
        (v) => (!isNaN(parseFloat(v)) && v >= 0 && v <= 1000000) || "Fehlerhaftes Format",
      ],
      dataCopy: this.data,
    };
  },

  components: {
    errorPopup,
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 9;
        case "md":
          return 7;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.dataCopy.last_updated = new Date().toLocaleString();

        if (this.dataCopy.isAdditional) {
          axios
            .patch(
              axios.defaults.baseURL + "additionalDamages/" + this.dataCopy.id + "/",
              this.dataCopy
            )
            .then(() => {
              this.$emit("input", true);
            })
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true;
                console.log(error);
              }
            });
        } else {
          axios //authenticated get request
            .patch(
              axios.defaults.baseURL + "damages/" + this.dataCopy.id + "/",
              this.dataCopy
            )
            .then(() => {
              this.$emit("input", true);
            })
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true;
              }
            });
        }
      }
    },
  },
};
</script>
