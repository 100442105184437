<template>
  <div id="modelDetail">
    <v-container fluid class="pa-2">
      <v-row fluid>
        <p class="ml-8 mt-4" v-if="vehicles.length == 0">
          Keine Einträge vom Modell {{ selectedModel }} vorhanden!
        </p>

        <v-col
          :cols="amount"
          class="pb-12 pt-2"
          v-for="vehicle in vehicles"
          :key="vehicle.vin"
          @click="
            cardClick(
              vehicle.vin,
              vehicle.vehiclesToModel.vehicleManufacturerModelsToVehicleType
                .name
            )
          "
        >
          <detailCard
            :plate="vehicle.plate"
            :vin="vehicle.vin"
            :imageFile="
              vehicle.images.length > 0
                ? getDisplayImage(vehicle)
                : require('../assets/images/placeholders/vehiclePlaceholder.jpg')
            "
            :user="vehicle.lastReport.reportsToUser"
            :owner="vehicle.currentOwner"
            :last_updated="vehicle.lastReport.created"
            :pricingNeeded="!vehicle.bFinishedPricing"
            :assessmentNeeded="!vehicle.bFinishedReport"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import detailCard from "../components/DetailCard";

const axios = require("axios");

export default {
  name: "modelDetail",

  props: [
    "modelName",
    "bShowAllModels",
    "bShowPricingNeeded",
    "bShowAssessmentNeeded",
    "bShowFinished",
    "bShowInHouse",
    "bHasChanged",
  ],

  data() {
    return {
      vehicles: {},
      selectedModel: this.$route.params.model,
      loading: false,
    };
  },

  watch: {
    bHasChanged: function () {
      this.loading = true;
      this.vehicles = {};
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "vehicle/list/?price=" +
            this.bShowPricingNeeded +
            "&report=" +
            this.bShowAssessmentNeeded +
            "&model=" +
            this.selectedModel +
            "&finished=" +
            this.bShowFinished +
            "&house=" +
            this.bShowInHouse
        )
        .then((response) => {
          this.vehicles = response.data;

          setTimeout(() => {
            this.loading = false;
            this.$forceUpdate();
          }, 500);
          //this.loading = false
        });
    },
  },

  components: {
    detailCard,
  },

  computed: {
    amount() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 3;
      }
    },
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
      localStorage.setItem("selectedModel", "");
    },

    cardClick(vin, vType) {
      localStorage.setItem("selectedVehicle", vin);
      localStorage.setItem("selectedVehicleType", vType);
    },

    getDisplayImage(val){
      for(var i = 0; i < val.images.length; i++){
        if(val.images[i].slot == "left") return val.images[i].imageFile
      }

      return val.images[0].imageFile
    },
  },
  mounted() {
    this.loading = true;
    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "vehicle/list/?price=" +
          this.bShowPricingNeeded +
          "&model=" +
          this.selectedModel +
          "&report=" +
          this.bShowAssessmentNeeded +
          "&finished=" +
          this.bShowFinished +
            "&house=" +
            this.bShowInHouse
      )
      .then((response) => {
        this.vehicles = response.data;
        this.loading = false;
      });
  },
};
</script>