<template>
  <div id="app">
    <v-tabs
      v-model="active_tab"
      class="mainNavBar pb-5"
      color="yellow"
      background-color="accent"
      slider-color="rgba(0,0,0,0)"
      :fixed-tabs="isFixed"
      show-arrows
      height="2em"
    >
      <v-tab
        class="mainNavBar"
        ripple
        @click="updateView('Stammdaten', 'overview', 0)"
        >Stammdaten</v-tab
      >
      <v-tab
        class="mainNavBar"
        ripple
        @click="updateView('Fahrzeugfotos', 'pictures', 1)"
        >Fotos</v-tab
      >
      <v-tab
        class="mainNavBar"
        ripple
        :disabled="disableAssessmentTab || !getPerm(3)"
        @click="updateView('Bewertungsassistent', 'assistant', 2)"
        >Bewertung</v-tab
      >
      <v-tab
        class="mainNavBar"
        ripple
        @click="updateView('Schadensliste', 'damages', 3)"
        >Schadensliste</v-tab
      >
      <v-tab
        class="mainNavBar"
        ripple
        @click="updateView('Status', 'status', 4)"
        >Status</v-tab
      >
      <v-tab
        class="mainNavBar"
        ripple
        @click="updateView('Dokumente', 'documents', 5)"
        >Dokumente</v-tab
      >
    </v-tabs>

    <router-view v-model="updateTabs" />
  </div>
</template>

<script>
const axios = require("axios");
export default {
  name: "mainNavBar",

  data() {
    return {
      active_tab: 0,
      vehicle: undefined,
      updateTabs: false,
      disableAssessmentTab: false,
      disableDamageTab: false,
    };
  },

  computed: {
    isFixed() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        default:
          return true;
      }
    },
  },

  methods: {
    updateView(title, pathEnding, activeTab) {
      this.$emit("input", title);

      this.active_tab = activeTab;

      if (pathEnding != "")
        this.$router.push(
          "/stock/vehicle/" + this.$route.params.vin + "/" + pathEnding
        );
    },
    getPerm(val){
      var perm = localStorage.getItem("perm")
      if(perm <= parseInt(val))
        return true
      
      return false
    },
    getPermEqual(val){
      var perm = localStorage.getItem("perm")
      if(perm == parseInt(val) || perm == 1)
        return true
      
      return false
    },
  },

  updated() {
    if (this.updateTabs) {
      this.updateTabs = false;

      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "vehicles/" +
            localStorage.getItem("selectedVehicle") + "/"
        )
        .then((response) => {
          this.vehicle = response.data;

          this.disableDamageTab =
            this.vehicle.bFinishedPricing || !this.vehicle.bFinishedReport;
          this.disableAssessmentTab = this.vehicle.bFinishedReport;

          if (
            this.$route.path ==
            "/stock/vehicle/" + this.$route.params.vin + "/overview"
          ) {
            if (response.data.bFinishedReport == true)
              this.updateView("Stammdaten", "", 0);
            else this.updateView("Bewertungsassistent", "assistant", 2);
          } else if (
            this.$route.path ==
            "/stock/vehicle/" + this.$route.params.vin + "/assistant"
          )
            this.updateView("Stammdaten", "overview", 0);
          else if (
            this.$route.path ==
            "/stock/vehicle/" + this.$route.params.vin + "/damages"
          )
            this.updateView("Stammdaten", "overview", 0);

          this.$forceUpdate();
        })
        .catch((error) => console.log(error));
    }
  },

  mounted() {
    this.$emit("input", "Stammdaten");

    this.vehicle = {};

    if(this.$route.path == "/stock/vehicle/" + this.$route.params.vin + "/documents"){
      this.updateView('Dokumente', 'documents', 5)
    }
    else if(this.$route.path == "/stock/vehicle/" + this.$route.params.vin + "/documents/1"){
      this.updateView('Dokumente', 'documents', 5)
    }
    else if (
      this.$route.path !=
      "/stock/vehicle/" + this.$route.params.vin + "/overview"
    )
      this.$router.push(
        "/stock/vehicle/" + this.$route.params.vin + "/overview"
      );

    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "vehicles/" +
          localStorage.getItem("selectedVehicle") + "/"
      )
      .then((response) => {
        this.vehicle = response.data;

        this.disableDamageTab =
          this.vehicle.bFinishedPricing || !this.vehicle.bFinishedReport;
        this.disableAssessmentTab = this.vehicle.bFinishedReport;

        if (this.vehicle.lastReport.created) {
          //report already exists
        } else {
          this.disableDamageTab = true;
          this.disableAssessmentTab = true;
        }

        this.$forceUpdate();
      })
      .catch((error) => console.log(error));
  },
};
</script>
