<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default{
  name: "app",
}
</script>

<style>
@import './assets/css/style.css';
</style>
