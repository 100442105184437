<template>
    <div id="paintCondition">
        <v-switch v-model="isOn" :value="isOn" label="Messen?" class="px-4"> </v-switch>
        <v-container v-if="isOn" fluid>
            <p class="pl-1">Alle Angaben in &#181;m</p>
            <v-expansion-panels class="pa-0 ma-0" flat>
            <v-expansion-panel expand>
            <v-expansion-panel-header class="py-0" :color="colors[0]" @click="changeColor(0)">
                <h4 height="32px">Fahrerseite</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            
                <v-container fluid>
                    <v-row align="center" justify="center">
                        <v-col :cols="imgCols">
                            <v-img :src="driverDisplayImage">
                            </v-img>
                        </v-col>
                        <v-col>
                            <v-container fluid :cols="12-imgCols">
                                <v-row>
                                    <v-col v-for="part in partsDriverSide" :key="part.name" :cols="cols">
                                        <v-text-field
                                         :label="part.name" 
                                         @change="pushToDB(part.model, part.value)" 
                                         v-model="part.value"
                                         :rules="rules"
                                         :value="part.value"
                                         >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                        
                </v-container>
                <v-divider />
            </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel expand >
            <v-expansion-panel-header class="py-0" :color="colors[1]" @click="changeColor(1)">
                <h4 height="32px">Beifahrerseite</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            
                <v-container fluid>
                    <v-row align="center" justify="center">
                        <v-col :cols="imgCols">
                            <v-img :src="coDriverDisplayImage">
                            </v-img>
                        </v-col>
                        <v-col>
                            <v-container fluid :cols="12-imgCols">
                                <v-row>
                                    <v-col v-for="part in partsCoDriverSide" :key="part.name" :cols="cols">
                                        <v-text-field
                                         :label="part.name" 
                                         @change="pushToDB(part.model, part.value)" 
                                         v-model="part.value"
                                         :rules="rules"
                                         :value="part.value"
                                         >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                        
                </v-container>
                <v-divider />
            </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel expand >
            <v-expansion-panel-header class="py-0" :color="colors[2]" @click="changeColor(2)">
                <h4 height="32px">Front</h4>
            </v-expansion-panel-header >
            <v-expansion-panel-content>
            
                <v-container fluid>
                    <v-row align="center" justify="center">
                        <v-col :cols="imgCols">
                            <v-img :src="frontDisplayImage">
                            </v-img>
                        </v-col>
                        <v-col>
                            <v-container fluid :cols="12-imgCols">
                                <v-row>
                                    <v-col v-for="part in partsFront" :key="part.name" :cols="cols">
                                        <v-text-field
                                         :label="part.name" 
                                         @change="pushToDB(part.model, part.value)" 
                                         v-model="part.value"
                                         :rules="rules"
                                         :value="part.value"
                                         >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                        
                </v-container>
                <v-divider />
            </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel expand >
            <v-expansion-panel-header class="py-0" :color="colors[3]" @click="changeColor(3)">
                <h4 height="32px">Heck</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            
                <v-container fluid>
                    <v-row align="center" justify="center">
                        <v-col :cols="imgCols">
                            <v-img :src="backDisplayImage">
                            </v-img>
                        </v-col>
                        <v-col>
                            <v-container fluid :cols="12-imgCols">
                                <v-row>
                                    <v-col v-for="part in partsBack" :key="part.name" :cols="cols">
                                        <v-text-field
                                         :label="part.name" 
                                         @change="pushToDB(part.model, part.value)" 
                                         v-model="part.value"
                                         :rules="rules"
                                         :value="part.value"
                                         >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                        
                </v-container>
                <v-divider />
            </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel expand >
            <v-expansion-panel-header class="py-0" :color="colors[4]" @click="changeColor(4)">
                <h4 height="32px">Dach</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            
                <v-container fluid>
                    <v-row align="center" justify="center">
                        <v-col :cols="imgCols">
                            <v-img :src="topDisplayImage">
                            </v-img>
                        </v-col>
                        <v-col>
                            <v-container fluid :cols="12-imgCols">
                                <v-row>
                                    <v-col v-for="part in partsTop" :key="part.name" :cols="cols">
                                        <v-text-field
                                         :label="part.name" 
                                         @change="pushToDB(part.model, part.value)" 
                                         v-model="part.value"
                                         :rules="rules"
                                         :value="part.value"
                                         >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                        
                </v-container>
                <v-divider />
            </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </v-container>
    </div>
</template>

<script>
const axios = require("axios")
export default {
    name: "paintCondition",
    props:["partGroups"],

    data() {
        return {
            paintConditionID: undefined,
            statusCollectionID: undefined,

            isOn: false,
            partsDriverSide: [
                {name: "Kotflügel", model: "fs_kotfluegel", value: undefined},
                {name: "Tür außen", model: "fs_tuer", value: undefined},
                {name: "Seitenpanele", model: "fs_seitenpanele", value: undefined},
                {name: "Schweller", model: "fs_schweller", value: undefined}],

            partsCoDriverSide: [
                {name: "Kotflügel", model: "bfs_kotfluegel", value: undefined},
                {name: "Tür außen", model: "bfs_tuer", value: undefined},
                {name: "Seitenpanele", model: "bfs_seitenpanele", value: undefined},
                {name: "Schweller", model: "bfs_schweller", value: undefined}],


            partsFront:[
                {name: "Motorhaube", model: "front_motorhaube", value: undefined},
                {name: "Stoßstange", model: "front_stossstange", value: undefined}],

            partsBack:[
                {name: "Heck", model: "heck_heck", value: undefined},
                {name: "Stoßstange", model: "heck_stossstange", value: undefined},
                {name: "Spoiler", model: "heck_spoiler", value: undefined}],

            partsTop:[
                {name: "Dach", model: "dach_dach", value: undefined},
                {name: "Hardtop", model: "dach_hardtop", value: undefined}],

            
            driverDisplayImage: undefined,
            coDriverDisplayImage: undefined,
            frontDisplayImage: undefined,
            backDisplayImage: undefined,
            topDisplayImage: undefined,

            rules: [(v) => (!!v || !isNaN(parseInt(v))) || v=="" || "Gültige Zahl angeben!"],

            colors: ["accent", "accent", "accent", "accent", "accent"],

        }
    },

    watch: {
        isOn: function () {
            this.pushOnStatus();
        },
    },

    computed: {
        cols() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                return "12";
                case "sm":
                return "6";
                case "md":
                return "4";
                case "lg":
                return "4";
                case "xl":
                return "4";
                default:
                return "4";
            }
        },
        imgCols(){
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                return "12";
                case "sm":
                return "6";
                case "md":
                return "4";
                case "lg":
                return "3";
                case "xl":
                return "3";
                default:
                return "3";
            }
        }
    },

    methods: {
        pushOnStatus(){
            var value;
            if(this.isOn == null)
                value = false
            else
                value = true

            axios //authenticated get request
                .put(axios.defaults.baseURL + "paintConditions/" + this.paintConditionID + "/",
                {
                    meassureCondition: value,
                    statusToPaintConditions: this.statusCollectionID
                })
                .catch((error) => {
                    if (error.response == undefined) {
                        this.errorOn = true
                    }
                })
        },
        changeColor(index){
            //active panel yellow
            for(var i = 0; i < this.colors.length; i++){
                if(i == index){
                    if(this.colors[i] == "accent")
                        this.colors[i] = "primary";
                    else
                        this.colors[i] = "accent";
                }
                else{
                    this.colors[i] = "accent";
                }
                
            }

            this.$forceUpdate();
        },
        pushToDB(key, value){

            if(value=="")
                value = null //reset

                

                axios //authenticated get request
                .put(axios.defaults.baseURL + "paintConditions/" + this.paintConditionID + "/",
                {
                    [key]: value,
                    statusToPaintConditions: this.statusCollectionID
                })
                .then(() => {
                })
                .catch((error) => {
                    if (error.response == undefined) {
                        this.errorOn = true
                    }
                })
        }
    },

    mounted() {
        //get display images based on part groups from local folder
        for(var i = 0; i < this.partGroups.length; i++){
            if(this.partGroups[i].name.includes("Fahrer")){
                let val = this.partGroups[i].name.replace(/\s/g, "_")
                
                this.driverDisplayImage = require('../../assets/images/paintConditionImages/' + val + '.jpg')
            }
            else if(this.partGroups[i].name.includes("Beifahrer")){
                let val = this.partGroups[i].name.replace(/\s/g, "_")
                
                this.coDriverDisplayImage = require('../../assets/images/paintConditionImages/' + val + '.jpg')
            }
            if(this.partGroups[i].name == "Front"){
                let val = this.partGroups[i].name.replace(/\s/g, "_")
                
                this.frontDisplayImage = require('../../assets/images/paintConditionImages/' + val + '.jpg')
            }
            if(this.partGroups[i].name == "Heck"){
                let val = this.partGroups[i].name.replace(/\s/g, "_")
                
                this.backDisplayImage = require('../../assets/images/paintConditionImages/' + val + '.jpg')
            }
            if(this.partGroups[i].name.includes("Dach") || this.partGroups[i].name.includes("Hardtop")){
                let val = this.partGroups[i].name.replace(/\s/g, "_")
                
                this.topDisplayImage = require('../../assets/images/paintConditionImages/' + val + '.jpg')
            }
        }
    },

    created() {
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "simpleStatusColByVehicle/?vin=" +
            localStorage.getItem("selectedVehicle")
        )
        .then((response) => {
          this.paintConditionID = response.data[0].paintCollection[0].id;
          this.statusCollectionID = response.data[0].id;

          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "paintConditions/" +
                this.paintConditionID +
                "/"
            )
            .then((resp) => {

                this.isOn = resp.data.meassureCondition;

                var i;
                for(i = 0; i < this.partsDriverSide.length; i++){
                    this.partsDriverSide[i].value = resp.data[this.partsDriverSide[i].model]
                }
                for(i = 0; i < this.partsCoDriverSide.length; i++){
                    this.partsCoDriverSide[i].value = resp.data[this.partsCoDriverSide[i].model]
                }
                for(i = 0; i < this.partsFront.length; i++){
                    this.partsFront[i].value = resp.data[this.partsFront[i].model]
                }
                for(i = 0; i < this.partsBack.length; i++){
                    this.partsBack[i].value = resp.data[this.partsBack[i].model]
                }
                for(i = 0; i < this.partsTop.length; i++){
                    this.partsTop[i].value = resp.data[this.partsTop[i].model]
                }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }
}
</script>