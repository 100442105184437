<template>
  <div id="overlay">
    <v-form ref="form">
      <v-row class="py-1">
        <v-container fluid>
          <v-row>
            <v-col class="whiteBG text-center align-center rounded-lg">
              <v-text-field
                required
                label="Reifenname"
                class="px-4"
                :disabled="false"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <v-row class="py-1">
        <v-col :cols="cols">
          <v-btn @click="save()" x-large color="primary" depressed block>
            Speichern & Schließen</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-overlay :value="loading" opacity="0.1">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "overlay",
  props: ["val"],

  data() {
    return {
      errorOn: false,

      loading: false,

      newData: undefined,
    };
  },

  components: {},

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 9;
        case "md":
          return 7;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },

  watch: {},

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$refs.form.resetValidation();
        this.loading = true;

        this.$emit("putTireData", this.newData);
      }
    },
  },

  mounted() {
    this.newData = this.val;

    console.log(this.newData);
  },
};
</script>