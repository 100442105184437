<template>
  <div id="overlay">
    <v-form ref="form">
      <v-row class="py-3 pb-0 text-center">
        <v-spacer />
        <v-col :cols="cols" class="whiteBG text-center align-center rounded-lg">
          <v-select
            required
            label="Komponente"
            :items="componentDisplayNames"
            class="px-4"
            v-model="component"
            :rules="[rules.required]"
            :value="component"
            :disabled="componentDisplayNames.length==1 ? true : false"
          ></v-select>

          <v-select
            required
            label="Art des Schadens"
            :items="damageTypeDisplayNames"
            :disabled="components.length == 0 || component == undefined || damageTypeDisplayNames.length==1"
            class="px-4"
            :rules="[rules.required]"
            v-model="damageType"
            :value="damageType"
          ></v-select>

          <v-select
            required
            label="Höhe des Schadens"
            :items="damageStageDisplayNames"
            :disabled="
              components.length == 0 ||
              component == undefined ||
              damageType == undefined ||
              damageTypes.length == 0 ||
              damageStageDisplayNames.length == 1
            "
            class="px-4"
            :rules="[rules.required]"
            v-model="damageStage"
            :value="damageStage"
          ></v-select>

          <div class="damageDisplay px-4">
            <v-container fluid class="pa-0 ma-0 pb-3">
              <v-row>
                <v-col cols="12">
                  <v-img :src="displayFile" class="align-center" v-if="component != undefined && damageType != undefined && damageStage != undefined">
                    
                    <image-uploader
                        :debug="0"
                        :rules="[rules.required]"
                        :maxWidth="2142"
                        :maxHeight="1615"
                        :quality="0.6"
                        :autoRotate=true
                        outputFormat="verbose"
                        :preview="true"
                        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                        capture="camera"
                        accept="image/*"
                        @input="setImage"
                        @onComplete="save"
                        id="damageUpload"
                      >
                        <label for="damageUpload" slot="upload-label">
                          
                          <span class="upload-caption">
                            <v-icon> mdi-camera </v-icon>
                          </span>
                        </label>
                      </image-uploader>
                  </v-img>
                   <v-img :src="displayFile" class="align-center" v-else>
                    <v-file-input
                      disabled
                      dense
                      hide-input
                      prepend-icon="mdi-camera-off"
                      :rules="[rules.required]"
                      required
                      capture="camera"
                      accept="image/*"
                      v-model="file"
                      class="fileUploadButton"
                    ></v-file-input>
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
        <v-spacer />
      </v-row>

      <!-- <v-row class="py-0">
        <v-spacer />
        <v-col :cols="cols" class="px-0">
          <v-btn @click="save()" x-large color="primary" depressed block class="rounded-lg">
            Speichern & Schließen</v-btn
          >
        </v-col>
        <v-spacer />
      </v-row> -->
    </v-form>

    <v-overlay :value="loading" opacity="0.1">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>

    <errorPopup :on="errorOn" :data="'Schadenserfassung'" v-model="loading" />
  </div>
</template>

<script>
import errorPopup from "../ErrorPopup.vue";
import ImageUploader from 'vue-image-upload-resize'

const axios = require("axios");

export default {
  name: "overlay",
  props: ["data"],

  data() {
    return {
      errorOn: false, 
      hasImage: false,

      file: undefined,
      displayFile: undefined,
      component: undefined,
      damageType: undefined,
      damageStage: undefined,

      components: [],
      componentDisplayNames: [],

      damageTypes: [],
      damageTypeDisplayNames: [],

      damageStages: undefined,
      damageStageDisplayNames: [],

      rules: {
        required: (value) => !!value || "Feld ausfüllen!",
      },

      loading: false,
    };
  },

   components: {
    errorPopup,
    ImageUploader
  },

  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 9;
        case "md":
          return 7;
        case "lg":
          return 6;
        case "xl":
          return 6;
        default:
          return 6;
      }
    },
  },

  watch: {
    component: function (val) {
      this.damageTypes = [];
      this.damageTypeDisplayNames = [];

      for (var i = 0; i < this.components.length; i++) {
        if (this.components[i].name == val) {
          for (
            var j = 0;
            j < this.components[i].componentsToDamageTypes.length;
            j++
          ) {
            this.damageTypes.push(
              this.components[i].componentsToDamageTypes[j]
            );
            this.damageTypeDisplayNames.push(
              this.components[i].componentsToDamageTypes[j].name
            );
          }

          if(this.damageTypeDisplayNames.length == 1){
            this.damageType = this.damageTypeDisplayNames[0]
          }

          return;
        }
      }
    },
    file: function () {
      this.displayFile = URL.createObjectURL(this.file);
      // this.displayFile = this.file;
      // this.save()
    },
    damageType: function (val) {
      this.damageStages = undefined
      this.damageStageDisplayNames = []

      for (var i = 0; i < this.components.length; i++) {
        if (this.components[i].name == this.component) {
          for (
            var j = 0;
            j < this.components[i].componentsToDamageTypes.length;
            j++
          ) {
            if (this.components[i].componentsToDamageTypes[j].name == val) {
              this.damageStages = this.components[i].componentsToDamageTypes[
                j
              ].damageTypesToDamageStage;
                this.damageStageDisplayNames.push(this.damageStages.small);
              if(this.damageStages.medium != '-')
                this.damageStageDisplayNames.push(this.damageStages.medium);

              if(this.damageStages.large != '-')
                this.damageStageDisplayNames.push(this.damageStages.large);

              if(this.damageStageDisplayNames.length == 1){
                this.damageStage = this.damageStageDisplayNames[0]
              }
              return;
            }
            
          }
        }
      }
    },
  },

  methods: {
    setImage(output) {
      this.hasImage = true;
      this.file = output;
      this.displayFile = this.file
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$refs.form.resetValidation()
        this.loading = true;

        var damageStageIndex = 0;

        for (var i = 0; i < this.damageStageDisplayNames.length; i++) {
          if (
            this.damageStage.localeCompare(this.damageStageDisplayNames[i]) == 0
          ) {
            damageStageIndex = i;
            break;
          }
        }

        var componentIndex = 0;
        for (var j = 0; j < this.componentDisplayNames.length; j++) {
          if (this.component == this.componentDisplayNames[j]) {
            componentIndex = this.components[j].id;
            break;
          }
        }

        var typeIndex = 0;
        for (var k = 0; k < this.damageTypeDisplayNames.length; k++) {
          if (this.damageType == this.damageTypeDisplayNames[k]) {
            typeIndex = this.damageTypes[k].id;
            break;
          }
        }

        var newDamage = new FormData();
        newDamage.append("created", new Date().toLocaleString());
          newDamage.append("last_updated", new Date().toLocaleString());
          newDamage.append("damageStageIndex", damageStageIndex);
          newDamage.append(
            "damagesToVehicleParts",
            Number.parseInt(this.data[0].id)
          );
          newDamage.append("damagesToComponent", componentIndex);
          newDamage.append(
            "damagesToPartGroup",
            Number.parseInt(this.data[1].id)
          );
          newDamage.append("damagesToReport", parseInt(this.data[2]));
          newDamage.append("damagesToDamageType", typeIndex);

        //check and resize image file if needed
        var dataURL = this.file.dataUrl;
        dataURL = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
        var blob = this.base64toBlob(dataURL, 'image/jpeg')

        newDamage.append("imageFile", blob, 'image.jpg');
        this.sendDamageToDB(newDamage);
      }
    },
    base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    },
    sendDamageToDB(newDamage){
      axios //authenticated get request
            .post(axios.defaults.baseURL + "damages/new/", newDamage)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.$emit("input", true);
              }, 750);
            })
            .catch((error) => {
              if (error.response == undefined) {
                this.loading = false
                this.errorOn = true
              }
            });
    }
  },

  mounted() {
    axios //authenticated get request
      .get(
        axios.defaults.baseURL + "componentByPart/?name=" + this.data[0].name
      )
      .then((response) => {
        this.components = response.data;

        this.componentDisplayNames = [];

        for (var i = 0; i < this.components.length; i++) {
          this.componentDisplayNames.push(this.components[i].name);
        }

        if(this.componentDisplayNames.length == 1)
          this.component = this.componentDisplayNames[0]

      })
      .catch((error) => console.log(error));
  },
};
</script>