<template>
  <div id="mainPage">
    <v-switch v-model="enableNewModel" value="enableNewModel"> </v-switch>
    <v-form ref="newModel" class="pa-4 ma-2" :disabled="!enableNewModel">
      <h2>Neue Fahrzeugmodelle</h2>
      <p>
        Erstellt automatisch ein oder mehrere neue Einträge für Fahrzeugmodelle.
        <br />
        Benennung der Bilddatei: hersteller_modell_name.dateinendung, bspw.:
        ferrari_sf90_stradale.jpg <br />
        Daraus generierter Modellname: 'SF90 Stradale', Hersteller: 'Ferrari'<br />
      </p>
      <v-switch v-model="multiple" flat label="Mehrere Dateien"></v-switch>
      <v-text-field
        v-model="modelName"
        v-if="multiple"
        disabled
        label="Modellname (wenn leer und bei mehreren Bildern automatisch generiert)"
      ></v-text-field>
      <v-text-field
        v-model="modelName"
        v-else
        label="Modellname (wenn leer und bei mehreren Bildern automatisch generiert)"
      ></v-text-field>

      <v-text-field
        v-model="manufacturerName"
        v-if="multiple"
        disabled
        label="Hersteller (wenn leer und bei mehreren Bildern automatisch generiert)"
      ></v-text-field>
      <v-text-field
        v-model="manufacturerName"
        v-else
        label="Hersteller (wenn leer und bei mehreren Bildern automatisch generiert)"
      ></v-text-field>

      <v-file-input
        v-model="files"
        v-if="multiple"
        counter
        multiple
      ></v-file-input>
      <v-file-input v-model="files" v-else counter></v-file-input>

      <v-btn
        :disabled="!enableNewModel"
        color="primary"
        depressed
        @click="uploadModels"
      >
        Upload
      </v-btn>
    </v-form>
    <hr />
    <v-switch v-model="enableModelImageChange" value="enableModelImageChange">
    </v-switch>
    <v-form
      class="pa-4 ma-2 pt-0"
      ref="imageUpdate"
      :disabled="!enableModelImageChange"
    >
      <h2>Bilddateien anpassen für Modelle</h2>
      <p>
        Liest automatisch Modell aus und ändert die Bilddetai(en)
        <br />
        Benennung der Bilddatei: hersteller_modell_name.dateinendung, bspw.:
        ferrari_sf90_stradale.jpg <br />
        Daraus generierter Modellname: 'SF90 Stradale', Hersteller: 'Ferrari'<br />
      </p>

      <v-file-input v-model="files" counter multiple></v-file-input>

      <v-btn
        :disabled="!enableModelImageChange"
        color="primary"
        depressed
        @click="updateImages"
      >
        Upload
      </v-btn>
    </v-form>
    <br />
    <hr />
    <v-switch
      v-model="enablePartGroupImageChange"
      value="enablePartGroupImageChange"
    >
    </v-switch>
    <v-form
      class="pa-4 ma-2 pt-0"
      ref="imageUpdate"
      :disabled="!enablePartGroupImageChange"
    >
      <h2>Bilddateien anpassen für PartGroups</h2>
      <p>
        Benennung der Bilddatei: Name_Der_Gruppe, bspw.: Fahrwerk_VL.jpg <br />
        Daraus generierter PartGroup Name: 'Fahrwerk VL'<br />
      </p>

      <v-file-input v-model="files" counter multiple></v-file-input>

      <v-btn
        :disabled="!enablePartGroupImageChange"
        color="primary"
        depressed
        @click="updatePartGroups"
      >
        Upload
      </v-btn>
    </v-form>
    <br />
    <hr />
    <v-switch v-model="enableModelTypeChange" value="enableModelTypeChange">
    </v-switch>
    <v-form
      class="pa-4 ma-2 pt-0"
      ref="imageUpdate"
      :disabled="!enableModelTypeChange"
    >
      <h2>Typen und Jahr anpassen</h2>
      <v-container fluid>
        <v-row>
          <v-col cols="3" v-for="(model,index) in models" :key="model.name" >
            <v-card>
              {{ model.name }}
              <v-img :src="model.imageFile" height="200px"> </v-img>
              
              <v-select :value="selectedType[index]" :items="vehicleTypeDisplayNames" v-model="selectedType[index]" v-on:change="updateType(index)"> </v-select>
              <v-text-field :value="selectedYear[index]" v-model="selectedYear[index]" v-on:change="updateYear(index)"></v-text-field>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <errorPopup :on="errorOn" />
  </div>
</template>

<script>
import errorPopup from "../components/ErrorPopup.vue";
const axios = require("axios");

export default {
  name: "admin",

  components: {
    errorPopup,
  },

  data() {
    return {
      errorOn: false, 

      multiple: false,
      modelName: "",
      manufacturerName: "",
      files: undefined,
      apiManufacturers: {},

      partGroups: [],
      models: [],
      vehicleTypes: [],
      vehicleTypeDisplayNames: [],
      selectedType: [],
      selectedYear: [],

      enableNewModel: false,
      enableModelImageChange: false,
      enablePartGroupImageChange: false,
      enableModelTypeChange: false,
    };
  },

  watch: {
    enableNewModel: function (val) {
      if (val) {
        this.enableModelImageChange = false;
        this.enablePartGroupImageChange = false;
        this.enableModelTypeChange = false;
      }
    },
    enableModelImageChange: function (val) {
      if (val) {
        this.enableNewModel = false;
        this.enablePartGroupImageChange = false;
        this.enableModelTypeChange = false;
      }
    },
    enablePartGroupImageChange: function (val) {
      if (val) {
        this.enableModelImageChange = false;
        this.enableNewModel = false;
        this.enableModelTypeChange = false;

        this.fetchPartGroups();
      }
    },
    enableModelTypeChange: function (val) {
      if (val) {
        this.enableModelImageChange = false;
        this.enablePartGroupImageChange = false;
        this.enableNewModel = false;
        
        this.fetchModels();
      }
    },
  },

  computed: {
    amount() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 3;
      }
    },
  },
  mounted() {
    axios //authenticated get request
      .get(axios.defaults.baseURL + "vehicleManufacturers/")
      .then((response) => {
        this.apiManufacturers = response.data;
      });
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    updateYear(index){
      axios //authenticated get request
      .get(axios.defaults.baseURL + "vehicleManufacturerModels/" + this.models[index].id + "/")
      .then((response) => {
        var model = new FormData()
        var temp = {
          id: undefined,
          name: undefined,
          year: parseInt(this.selectedYear[index]),
          vehicleManufacturerModelsToVehicleType: response.data.vehicleManufacturerModelsToVehicleType
        };
        
        model.append("id", response.data.id)
        model.append("name", response.data.name)
        model.append("vehicleManufacturerModelsToVehicleType", response.data.vehicleManufacturerModelsToVehicleType)
        model.append("year", parseInt(this.selectedYear[index]))

        temp.id = response.data.id
        temp.name = response.data.name
        
        axios //authenticated get request
        .put(
          axios.defaults.baseURL + "vehicleManufacturerModels/put/" + this.models[index].id + "/",
          model
        )
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
      });
    },
    updateType(index){
      axios //authenticated get request
      .get(axios.defaults.baseURL + "vehicleManufacturerModels/" + this.models[index].id + "/")
      .then((response) => {
        var model = new FormData()
        var temp = {
          id: undefined,
          name: undefined,
          vehicleManufacturerModelsToVehicleType: undefined
        };
        var image = new Image()
        image.src = response.data.imageFile

        model.append("id", response.data.id)
        model.append("name", response.data.name)

        temp.id = response.data.id
        temp.name = response.data.name

        for(var i = 0; i < this.vehicleTypes.length; i++){
          if(this.vehicleTypes[i].name == this.selectedType[index]){
            //model.append("vehicleManufacturerModelsToVehicleType", this.vehicleTypes[index].id)
            temp.vehicleManufacturerModelsToVehicleType = this.vehicleTypes[i].id
          }
        }
        
        axios //authenticated get request
        .put(
          axios.defaults.baseURL + "vehicleManufacturerModels/put/" + this.models[index].id + "/",
          temp
        )
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
      });
    },
    getTypes(){
      for(var j = 0; j < this.models.length; j++){
        if(this.models[j].vehicleManufacturerModelsToVehicleType >= 0){
          for(var i = 0; i < this.vehicleTypeDisplayNames.length; i++){
            if(this.models[j].vehicleManufacturerModelsToVehicleType == this.vehicleTypes[i].id){
              this.selectedType[j] = this.vehicleTypeDisplayNames[i]
              break
            }
          }  
        }
      }
    },
    getYears(){
      for(var j = 0; j < this.models.length; j++){
        this.selectedYear[j] = this.models[j].year
      }   
    },
    fetchModels() {
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "model/list/?all=True"
        )
        .then((response) => {
          this.models = response.data;

          
        axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "vehicleTypes/simple/"
        )
        .then((response) => {
          this.vehicleTypes = response.data;

          for(var i = 0; i < this.vehicleTypes.length; i++)
            this.vehicleTypeDisplayNames.push(this.vehicleTypes[i].name)

          this.getTypes()
          this.getYears()

          this.$forceUpdate();
        });
        });

    },
    fetchPartGroups() {
      axios //authenticated get request
        .get(axios.defaults.baseURL + "vehiclePartGroups/")
        .then((response) => {
          this.partGroups = response.data;
        })
        .catch((error) => console.log(error));
    },
    putPGIconFile(pgName, file) {
      var pg;
      for (var i = 0; i < this.partGroups.length; i++) {
        if (this.partGroups[i].name == pgName) {
          pg = this.partGroups[i];
          break;
        }
      }

      var finalPG = new FormData();

      finalPG.append("iconFile", file);
      finalPG.append("id", parseInt(pg.id));
      finalPG.append("name", pg.name);

      axios //authenticated get request
        .put(
          axios.defaults.baseURL + "vehiclePartGroups/put/" + pg.id + "/",
          finalPG
        )
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
    },
    putPGImageFile(pgName, file) {
      var pg;
      for (var i = 0; i < this.partGroups.length; i++) {
        if (this.partGroups[i].name == pgName) {
          pg = this.partGroups[i];
          break;
        }
      }

      var finalPG = new FormData();

      finalPG.append("imageFile", file);
      finalPG.append("id", parseInt(pg.id));
      finalPG.append("name", pg.name);

      axios //authenticated get request
        .put(
          axios.defaults.baseURL + "vehiclePartGroups/put/" + pg.id + "/",
          finalPG
        )
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
    },
    updatePartGroups() {
      var tempName;
      var i = 0;

      for (i = 0; i < this.files.length; i++) {
        tempName = this.files[i].name;
        tempName = tempName.split(".")[0];

        if (tempName.includes("Icon_") || tempName.includes("icon_")) {
          tempName = tempName.replace("icon_", "");
          tempName = tempName.replace("Icon_", "");
          tempName = tempName.replace(/_/g, " ");
          this.putPGIconFile(tempName, this.files[i]);
        } else {
          tempName = tempName.replace(/_/g, " ");
          this.putPGImageFile(tempName, this.files[i]);
        }
      }
    },
    updateImages() {
      var names = [];
      var manuf = [];
      var i;
      for (i = 0; i < this.files.length; i++) {
        names.push(this.generateNameFromImagePath(this.files[i].name));
        manuf.push(this.generateManufacturerFromImagePath(this.files[i].name));
      }

      for (i = 0; i < names.length; i++) {
        axios //authenticated get request
          .get(
            axios.defaults.baseURL +
              "model/search/?search=" +
              names[i] +
              "&manuf=" +
              manuf[i]
          )
          .then((response) => {
            var finalModel = new FormData();

            finalModel.append(
              "imageFile",
              this.files[names.indexOf(response.data[0].name)]
            );
            finalModel.append("id", response.data[0].id);
            finalModel.append("name", response.data[0].name);
            finalModel.append(
              "vehicleManufacturerModelsToVehicleManufacturer",
              response.data[0].vehicleManufacturerModelsToVehicleManufacturer
            );
            finalModel.append(
              "vehicleManufacturerModelsToVehicleType",
              response.data[0].vehicleManufacturerModelsToVehicleType
            );

            axios //authenticated get request
              .put(
                axios.defaults.baseURL +
                  "vehicleManufacturerModels/" +
                  response.data[0].id +
                  "/",
                finalModel
              )
              .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
          });
      }
    },
    uploadModels() {
      if (this.multiple) {
        for (var i = 0; i < this.files.length; i++) {
          let data = new FormData(); // creates a new FormData object

          //generare and append model name
          data.append(
            "name",
            this.generateNameFromImagePath(this.files[i].name)
          );

          //append image file
          data.append("imageFile", this.files[i]);

          //generate manufacturer name, get database index and append it
          var tempMan = this.generateManufacturerFromImagePath(
            this.files[i].name
          );

          for (var k = 0; k < this.apiManufacturers.length; k++) {
            if (this.apiManufacturers[k].name == tempMan) {
              data.append(
                "vehicleManufacturerModelsToVehicleManufacturer",
                this.apiManufacturers[k].id
              );
              break;
            }
          }

          data.append("vehicleManufacturerModelsToVehicleType", 1);

          axios //authenticated get request
            .post(axios.defaults.baseURL + "vehicleManufacturerModels/", data)
            .then(() => {
              this.modelName = "";
              this.files = undefined;
            })
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
        }
      } else {
        //post one single vehicle
        let data = new FormData(); // creates a new FormData object

        //append model name, generate first if empty
        if (this.modelName) {
          data.append("name", this.modelName);
        } else {
          data.append("name", this.generateNameFromImagePath(this.files.name));
        }

        //append manufacturer name, generate first if empty
        if (this.manufacturerName) {
          for (var l = 0; l < this.apiManufacturers.length; l++) {
            if (this.apiManufacturers[l].name == this.manufacturerName) {
              data.append(
                "vehicleManufacturerModelsToVehicleManufacturer",
                this.apiManufacturers[l].id
              );
              break;
            }
          }
        } else {
          var man = this.generateManufacturerFromImagePath(this.files.name);
          for (var m = 0; m < this.apiManufacturers.length; m++) {
            if (this.apiManufacturers[m].name == man) {
              data.append(
                "vehicleManufacturerModelsToVehicleManufacturer",
                this.apiManufacturers[m].id
              );
              break;
            }
          }
        }

        //append image file
        data.append("imageFile", this.files);

        data.append("vehicleManufacturerModelsToVehicleType", 1);

        axios //authenticated get request
          .post(axios.defaults.baseURL + "vehicleManufacturerModels/", data)
          .then(() => {
            this.modelName = "";
            this.files = undefined;
          })
          .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
      }
    },
    //image file: manufacturer_model_name.ending
    generateNameFromImagePath(pathString) {
      var data = pathString.split("_");

      data[data.length - 1] = data[data.length - 1].split(".")[0];

      var resultString = "";

      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          continue;
        }

        var tempString = data[i];
        if (tempString.length < 4) {
          tempString = tempString.toUpperCase();
        } else {
          tempString = tempString.charAt(0).toUpperCase() + tempString.slice(1);
        }

        resultString = resultString.concat(tempString);

        if (!(i === data.length - 1)) resultString = resultString.concat(" ");
      }

      return resultString;
    },
    //image file: manufacturer_model_name.ending
    generateManufacturerFromImagePath(pathString) {
      var data = pathString.split("_");
      var resultString = data[0];
      resultString =
        resultString.charAt(0).toUpperCase() + resultString.slice(1);

      return "" + resultString;
    },
  },
};
</script>