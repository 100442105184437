import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
//import mdiVue from 'mdi-vue'
import * as mdijs from '@mdi/js'

Vue.use(Vuetify);
//Vue.use(mdiVue, {icons: mdijs}) 

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
        light: {
          primary: '#FFED00',
          secondary:colors.grey.lighten1,
          tertiary: colors.grey.darken2,
          accent:'#E9E9E9',
          background: '#FAFAFA',
          toolbar: '#FFF',
      },
      dark: {
        primary: '#FFED00',
        secondary:colors.grey.lighten1,
        tertiary: colors.grey.darken2,
        accent:'#E9E9E9',
        background: '#FAFAFA',
        toolbar: '#FFF',
    },
    },
  },

});

export default vuetify