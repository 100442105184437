<template>
  <div id="stockPage" class="mx-6">
    <div v-if="!reportFinished">
      <v-container fluid>
        <v-row>
          <v-spacer />
          <v-col :cols="amount">
            <vehicleComment> </vehicleComment>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col :cols="amount">
            <p>Bewertung abschließen, um Daten anzuzeigen!</p>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </div>

    <div v-else>
      <v-container fluid>
        <v-row>
          <v-spacer />
          <v-col :cols="amount">
            <vehicleComment> </vehicleComment>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
      <v-container class="pb-16" fluid>
        <v-row>
          <v-spacer />
          <v-col :cols="amount">
            <v-card>
              <v-container fluid>
                <v-row>
                  <v-col
                    :cols="toolCol"
                    v-for="(tool, index) in tools"
                    :key="index"
                  >
                    <v-card flat>
                      {{ toolDisplayNames[index] + ": " }}
                      <v-icon v-if="tool == true" style="color: green"
                        >mdi-check
                      </v-icon>
                      <v-icon v-else style="color: red"
                        >mdi-window-close
                      </v-icon>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row v-if="additionalToolInfo">
                  <v-col>
                    <v-card flat>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="2" class="pa-0">
                            Zusatzinformation:
                          </v-col>
                          <v-col class="pa-0">
                            {{ additionalToolInfo }}
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-spacer />
        </v-row>

        <v-row>
          <v-spacer />
          <v-col :cols="amount">
            <v-data-table
              :headers="reifen_headers"
              :items="reifen"
              item-key="name"
              class="elevation-1"
              hide-default-footer
              disable-sort
              @click:row="handleTireClick"
            ></v-data-table>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col :cols="amount">
            <v-card class="pa-2 px-3">
              Carbonbremse?
              <v-icon v-if="bIsCarbon == true" style="color: green"
                >mdi-check
              </v-icon>
              <v-icon v-else style="color: red">mdi-window-close </v-icon>
            </v-card>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col :cols="amount">
            <v-data-table
              :headers="bremse_headers"
              :items="bremse"
              item-key="name"
              class="elevation-1"
              hide-default-footer
              disable-sort
            ></v-data-table>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col :cols="amount">
            <v-data-table
              :headers="fahrwerk_headers"
              :items="fahrwerk"
              item-key="name"
              class="elevation-1"
              hide-default-footer
              disable-sort
            ></v-data-table>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col :cols="amount">
            <v-container
              fluid
              fill-height
              v-if="paintCondition && paintCondition.meassureCondition"
            >
              <v-row class="lackmessungStatusTableHeader">
                Lackschichtenmessung (wird gemessen, in &#181;m)
              </v-row>
              <v-row class="lackmessungStatusTableSubheader">
                <v-col style="font-weight: bold">Fahrerseite</v-col>
                <v-col>Kotflügel</v-col>
                <v-col>Tür</v-col>
                <v-col>Seitenpanele</v-col>
                <v-col>Schweller</v-col>
              </v-row>
              <v-row class="lackmessungStatusTableItem">
                <v-col></v-col>
                <v-col> {{ paintCondition.fs_kotfluegel || "-" }} </v-col>
                <v-col> {{ paintCondition.fs_tuer || "-" }} </v-col>
                <v-col> {{ paintCondition.fs_seitenpanele || "-" }} </v-col>
                <v-col> {{ paintCondition.fs_schweller || "-" }} </v-col>
              </v-row>
              <v-row class="lackmessungStatusTableSubheader">
                <v-col style="font-weight: bold">Beifahrerseite</v-col>
                <v-col>Kotflügel</v-col>
                <v-col>Tür</v-col>
                <v-col>Seitenpanele</v-col>
                <v-col>Schweller</v-col>
              </v-row>
              <v-row class="lackmessungStatusTableItem">
                <v-col></v-col>
                <v-col> {{ paintCondition.bfs_kotfluegel || "-" }} </v-col>
                <v-col> {{ paintCondition.bfs_tuer || "-" }} </v-col>
                <v-col> {{ paintCondition.bfs_seitenpanele || "-" }} </v-col>
                <v-col> {{ paintCondition.bfs_schweller || "-" }} </v-col>
              </v-row>
              <v-row class="lackmessungStatusTableSubheader">
                <v-col style="font-weight: bold">Front</v-col>
                <v-col>Stoßstange</v-col>
                <v-col>Motorhaube</v-col>
                <v-col></v-col>
                <v-col></v-col>
              </v-row>
              <v-row class="lackmessungStatusTableItem">
                <v-col></v-col>
                <v-col> {{ paintCondition.front_stossstange || "-" }} </v-col>
                <v-col> {{ paintCondition.front_motorhaube || "-" }} </v-col>
                <v-col></v-col>
                <v-col></v-col>
              </v-row>
              <v-row class="lackmessungStatusTableSubheader">
                <v-col style="font-weight: bold">Heck</v-col>
                <v-col>Heck</v-col>
                <v-col>Stoßstange</v-col>
                <v-col>Spoiler</v-col>
                <v-col></v-col>
              </v-row>
              <v-row class="lackmessungStatusTableItem">
                <v-col></v-col>
                <v-col> {{ paintCondition.heck_heck || "-" }} </v-col>
                <v-col> {{ paintCondition.heck_stossstange || "-" }} </v-col>
                <v-col> {{ paintCondition.heck_spoiler || "-" }} </v-col>
                <v-col></v-col>
              </v-row>
              <v-row class="lackmessungStatusTableSubheader">
                <v-col style="font-weight: bold">Dach</v-col>
                <v-col>Dach</v-col>
                <v-col>Hardtop</v-col>
                <v-col></v-col>
                <v-col></v-col>
              </v-row>
              <v-row class="lackmessungStatusTableItem">
                <v-col></v-col>
                <v-col> {{ paintCondition.dach_dach || "-" }} </v-col>
                <v-col> {{ paintCondition.dach_hardtop || "-" }} </v-col>
                <v-col></v-col>
                <v-col></v-col>
              </v-row>
            </v-container>
            <v-container fluid fill-height v-else>
              <v-row class="lackmessungStatusTableHeaderOff">
                Lackschichtenmessung (nicht gemessen)
              </v-row>
            </v-container>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </div>

    <v-overlay v-if="editTireDataVisible" :dark="false">
      <editTireData :val="tireData"  v-on:putTireData="pushAndRefreshTireData" />
    </v-overlay>

    
  </div>
</template>

<script>
import vehicleComment from "../components/VehicleComment.vue";
import editTireData from "../components/Overlays/Overlay_EditTireData.vue";

const axios = require("axios");
export default {
  name: "status",

  data() {
    return {
      pageTitle: "Status",

      reportFinished: false,

      bremse_headers: [
        {
          text: "Bremse",
          align: "start",
          value: "name",
          groupable: false,
        },
        { text: "Verschleiß (%)", value: "Verschleiss", align: "center" },
        { text: "Beläge (mm)", value: "Belaege", align: "center" },
      ],
      reifen_headers: [
        {
          text: "Reifen",
          align: "start",
          value: "name",
          groupable: false,
        },
        { text: "Reifenname", value: "Reifenname", align: "center" },
        { text: "Profiltiefe (i m a)", value: "Profiltiefe", align: "center" },
        { text: "Reifendimension", value: "Reifendimension", align: "center" },
        { text: "Lastindex", value: "Lastindex", align: "center" },
        { text: "DOT", value: "DOT", align: "center" },
        { text: "TPMS", value: "TPMS", align: "center" },
        { text: "Winterreifen?", value: "Winterreifen", align: "center" },
      ],
      fahrwerk_headers: [
        {
          text: "Fahrwerk",
          align: "start",
          value: "name",
          groupable: false,
        },
        { text: "Radlagerspiel", value: "Radlagerspiel", align: "center" },
        { text: "Lenkungsspiel", value: "Lenkungsspiel", align: "center" },
        { text: "Spurstange", value: "Spurstange", align: "center" },
      ],
      bremse: [
        {
          name: "VL",
          Verschleiss: "",
          Belaege: "",
        },
        {
          name: "VR",
          Verschleiss: "",
          Belaege: "",
        },
        {
          name: "HL",
          Verschleiss: "",
          Belaege: "",
        },
        {
          name: "HR",
          Verschleiss: "",
          Belaege: "",
        },
      ],
      reifen: [
        {
          name: "VL",
          Reifenname: "",
          Profiltiefe: "",
          Reifendimension: "",
          Lastindex: "",
          DOT: "",
          TPMS: "",
          Winterreifen: "",
        },
        {
          name: "VR",
          Reifenname: "",
          Profiltiefe: "",
          Reifendimension: "",
          Lastindex: "",
          DOT: "",
          TPMS: "",
          Winterreifen: "",
        },
        {
          name: "HL",
          Reifenname: "",
          Profiltiefe: "",
          Reifendimension: "",
          Lastindex: "",
          DOT: "",
          TPMS: "",
          Winterreifen: "",
        },
        {
          name: "HR",
          Reifenname: "",
          Profiltiefe: "",
          Reifendimension: "",
          Lastindex: "",
          DOT: "",
          TPMS: "",
          Winterreifen: "",
        },
      ],
      fahrwerk: [
        {
          name: "VL",
          Radlagerspiel: "",
          Lenkungsspiel: "",
          Spurstange: "",
        },
        {
          name: "VR",
          Radlagerspiel: "",
          Lenkungsspiel: "",
          Spurstange: "",
        },
        {
          name: "HL",
          Radlagerspiel: "",
          Lenkungsspiel: "",
          Spurstange: "",
        },
        {
          name: "HR",
          Radlagerspiel: "",
          Lenkungsspiel: "",
          Spurstange: "",
        },
      ],

      tools: [],
      toolDisplayNames: [],
      additionalToolInfo: undefined,
      bIsCarbon: false,

      paintCondition: {},

      statusColData: undefined,

      editTireDataVisible: false,
      tireData: undefined,
    };
  },

  components: {
    vehicleComment,
    editTireData,
  },

  computed: {
    amount() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 11;
        case "sm":
          return 11;
        case "md":
          return 9;
        case "lg":
          return 8;
        default:
          return 8;
      }
    },
    toolCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 4;
        case "sm":
          return 4;
        case "md":
          return 3;
        case "lg":
          return 3;
        default:
          return 3;
      }
    },
  },

  methods: {
    handleTireClick(val) {
        this.editTireDataVisible = true;
        this.tireData = val;
    },
    pushAndRefreshTireData(val){
      this.editTireDataVisible = false

      let profile = val.Profiltiefe.split(" ");
        let dim = val.Reifendimension.split(" ");

        let tpms_val = "tpms_" + val.name.toLowerCase();

        let tireLocation = undefined;
        let tireID;

        switch (val.name) {
          case "VL":
            tireID = 17;
            break;
          case "VR":
            tireID = 18;
            break;
          case "HL":
            tireID = 19;
            break;
          default:
            tireID = 20;
            break;
        }

        for (let i = 0; i < this.statusColData.tireCollection.length; i++) {
          if (this.statusColData.tireCollection[i].location == tireID) {
            tireLocation = i;
            break;
          }
        }

        axios //authenticated get request
          .put(
            axios.defaults.baseURL +
              "tireConditions/" +
              this.statusColData.tireCollection[tireLocation].id +
              "/",
            {
              model: val.Reifenname,
              tireProfileInner: profile[0],
              tireProfileMiddle: profile[1],
              tireProfileOuter: profile[2],
              crossSection: dim[0],
              width: dim[1],
              diameter: dim[2].split("R")[1],
              dot: val.DOT,
              bIsWinterTires: val.bIsWinterTires,
              statusToTireConditions:
                this.statusColData.tireCollection[tireLocation]
                  .statusToTireConditions,
              location: tireID,
              id: this.statusColData.tireCollection[tireLocation].id,
            }
          )
          .then((resp) => {
            axios //authenticated get request
              .put(
                axios.defaults.baseURL +
                  "baseConditions/" +
                  this.statusColData.baseCollection[0].id +
                  "/",
                {
                  [tpms_val]: val.TPMS,
                  statusToBaseConditions:
                    this.statusColData.baseCollection[0].statusToBaseConditions,
                }
              )
              .then((r) => {
                this.statusColData.baseCollection[0] = r.data;
                for (var j = 0; j < this.reifen.length; j++) {
                  if (this.reifen[j].name == val.name) {
                    this.fillTireStatus(
                      this.reifen[j],
                      resp.data,
                      this.statusColData.baseCollection[0]
                    );
                  }
                }
              })
              .catch(() => {
              });
          })
          .catch(() => {
          });
    },
    fillStatus(dataArray, simpleDataArray, resultArray, additional) {
      for (var i = 0; i < dataArray.length; i++) {
        var loc = simpleDataArray[i].location.name.split(" ");

        for (var j = 0; j < resultArray.length; j++) {
          if (resultArray[j].name == loc[1]) {
            if (loc[0] == "Reifen")
              this.fillTireStatus(resultArray[j], dataArray[i], additional);
            else if (loc[0] == "Bremse")
              this.fillBrakeStatus(resultArray[j], dataArray[i]);
            else if (loc[0] == "Fahrwerk")
              this.fillSuspensionStatus(resultArray[j], dataArray[i]);
            break;
          }
        }
      }
    },
    fillTireStatus(resultObject, dataObject, additional) {
      resultObject.Reifenname = dataObject.model;
      resultObject.Profiltiefe =
        dataObject.tireProfileInner +
        " " +
        dataObject.tireProfileMiddle +
        " " +
        dataObject.tireProfileOuter;
      resultObject.Reifendimension =
        dataObject.crossSection +
        " " +
        dataObject.width +
        " R" +
        dataObject.diameter;
      resultObject.Lastindex = dataObject.loadIndex;
      resultObject.DOT = dataObject.dot;

      var val = "tpms_" + resultObject.name.toLowerCase();
      resultObject.TPMS = additional[val];

      if (dataObject.bIsWinterTires) resultObject.Winterreifen = "Ja";
      else resultObject.Winterreifen = "Nein";
    },
    fillBrakeStatus(resultObject, dataObject) {
      resultObject.Verschleiss = dataObject.wear;
      resultObject.Belaege = dataObject.brakePadThickness;

      this.bIsCarbon = dataObject.bIsCarbon;
    },
    fillSuspensionStatus(resultObject, dataObject) {
      if (dataObject.bHasWheelBearingClearance)
        resultObject.Radlagerspiel = "Ja";
      else resultObject.Radlagerspiel = "Nein";

      if (dataObject.bHasWishboneClearance) resultObject.Lenkungsspiel = "Ja";
      else resultObject.Lenkungsspiel = "Nein";

      if (dataObject.bHasTrackRodClearance) resultObject.Spurstange = "Ja";
      else resultObject.Spurstange = "Nein";
    },
    fillToolStatus(toolCol, baseCol) {
      this.tools = [];
      this.toolDisplayNames = [];

      this.tools.push(baseCol[0].zweitschlüssel);
      this.toolDisplayNames.push("Zweitschlüssel");

      this.tools.push(baseCol[0].fbaks);
      this.toolDisplayNames.push("FBAKS");

      this.tools.push(toolCol[0].bordwerkzeug);
      this.toolDisplayNames.push("Bordwerkzeug");

      this.tools.push(toolCol[0].ladegeraet);
      this.toolDisplayNames.push("Ladegerät");

      this.tools.push(toolCol[0].paletot);
      this.toolDisplayNames.push("Paletot");

      this.tools.push(toolCol[0].reifenfuellkit);
      this.toolDisplayNames.push("Reifenfüllkit");

      this.tools.push(toolCol[0].warndreieck);
      this.toolDisplayNames.push("Warndreieck");

      this.tools.push(toolCol[0].verbandskasten);
      this.toolDisplayNames.push("Verbandskasten");

      this.tools.push(toolCol[0].radsatz8);
      this.toolDisplayNames.push("Rad 8-fach");

      this.tools.push(toolCol[0].servicemappe);
      this.toolDisplayNames.push("Servicemappe");

      this.tools.push(toolCol[0].radiokarte);
      this.toolDisplayNames.push("Radiokarte");

      this.tools.push(toolCol[0].garantieheft);
      this.toolDisplayNames.push("Garantieheft");

      this.tools.push(toolCol[0].windschott);
      this.toolDisplayNames.push("Windschott");

      this.tools.push(toolCol[0].elektronikkarte);
      this.toolDisplayNames.push("Elektronikkarte");

      this.additionalToolInfo = toolCol[0].comment;
    },
  },

  mounted() {
    axios //authenticated get request
      .get(axios.defaults.baseURL + "simpleVehicle/" + this.$route.params.vin)
      .then((r) => {
        this.reportFinished = r.data.bFinishedReport;

        this.vehicle_comment = r.data.vehicle_comment;
      });

    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "statusColByVehicle/?vin=" +
          this.$route.params.vin
      )
      .then((response) => {
        axios //authenticated get request
          .get(
            axios.defaults.baseURL +
              "simpleStatusColByVehicle/?vin=" +
              this.$route.params.vin
          )
          .then((resp) => {
            this.statusColData = response.data[0];
            this.fillStatus(
              response.data[0].tireCollection,
              resp.data[0].tireCollection,
              this.reifen,
              response.data[0].baseCollection[0]
            );
            this.fillStatus(
              response.data[0].brakeCollection,
              resp.data[0].brakeCollection,
              this.bremse,
              response.data[0].baseCollection[0]
            );
            this.fillStatus(
              response.data[0].suspCollection,
              resp.data[0].suspCollection,
              this.fahrwerk,
              response.data[0].baseCollection[0]
            );
            this.fillToolStatus(
              response.data[0].toolCollection,
              response.data[0].baseCollection
            );
            this.paintCondition = response.data[0].paintCollection[0];
          });
      });
  },
};
</script>