<template>
  <div>
    <div id="element-to-print" style="display: none">
      <div class="document">
        <!-- Page 1 -->
        <div class="pdfHeader"></div>

        <div class="pdfWrappingWrapper">
          <div id="img0" class="pdfImageWrapperSmall"></div>
        </div>

        <div class="pdfWrappingWrapper">
          <div id="img1" class="pdfImageWrapperSmall"></div>
        </div>

        <div style="margin-left: 28px !important">
          <table class="pdfTableBaseStats pdfInline">
            <tbody>
              <tr>
                <td>Hersteller:</td>
                <td id="hersteller"></td>
              </tr>
              <tr>
                <td>Fahrzeugmodell:</td>
                <td id="fahrzeugmodell"></td>
              </tr>
              <tr>
                <td>Kundenname:</td>
                <td id="kundenname"></td>
              </tr>
              <tr>
                <td>Kilometerstand:</td>
                <td id="kilometerstand"></td>
              </tr>
              <tr>
                <td>Kennzeichen:</td>
                <td id="kennzeichen"></td>
              </tr>
            </tbody>
          </table>

          <table class="pdfTablePrice pdfInline" style="text-align: right">
            <tbody>
              <tr>
                <td>Ankaufspreis</td>
                <td id="ankaufspreis"></td>
              </tr>
              <tr>
                <td>Reparaturpreis</td>
                <td id="reperaturpreis"></td>
              </tr>
              <tr>
                <td>Zusatzreparatur</td>
                <td id="zusatzreparatur"></td>
              </tr>
              <tr>
                <td>Servicekosten</td>
                <td id="servicekosten"></td>
              </tr>
              <tr>
                <td>Verkaufspreis</td>
                <td id="verkaufspreis"></td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Page 2 -->
        <div class="pdfHeader pageBreak"></div>

        <div class="pdfWrappingWrapper">
          <div id="img2" class="pdfImageWrapper"></div>
        </div>

        <div class="pdfWrappingWrapper">
          <div id="img3" class="pdfImageWrapper"></div>
        </div>

        <!-- Page 3 -->
        <div class="pdfHeader pageBreak"></div>

        <div class="pdfWrappingWrapper">
          <div id="img4" class="pdfImageWrapper"></div>
        </div>

        <div class="pdfWrappingWrapper">
          <div id="img5" class="pdfImageWrapper"></div>
        </div>

        <!-- Page 4 -->
        <div class="pdfHeader pageBreak"></div>

        <div class="pdfWrappingWrapper">
          <div id="img6" class="pdfImageWrapper"></div>
        </div>

        <div class="pdfWrappingWrapper">
          <div id="img7" class="pdfImageWrapper"></div>
        </div>

        <!-- Page 5 -->
        <div class="pdfHeader pageBreak"></div>

        <!-- Reifen -->
        <table class="toolsTable">
          <tbody>
            <tr>
              <td>
                <div>
                  <input type="checkbox" id="zweitschluessel" checked readonly />
                  <label for="zweitschluessel" class="pdfCheckbox">Zweitschlüssel</label>
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" id="fbaks" checked readonly />
                  <label for="fbaks" class="pdfCheckbox">FBAKS</label>
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" id="bordwz" checked readonly />
                  <label for="bordwz" class="pdfCheckbox">Bordwerkzeug</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <input type="checkbox" id="paletot" checked readonly />
                  <label for="paletot" class="pdfCheckbox">Paletot</label>
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" id="ladegeraet" checked readonly />
                  <label for="ladegeraet" class="pdfCheckbox">Ladegerät</label>
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" id="reifenfk" checked readonly />
                  <label for="reifenfk" class="pdfCheckbox">Reifenfüllkit</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <input type="checkbox" id="warndreieck" checked readonly />
                  <label for="warndreieck" class="pdfCheckbox">Warndreieck</label>
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" id="verband" checked readonly />
                  <label for="verband" class="pdfCheckbox">Verbandskasten</label>
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" id="rad8" checked readonly />
                  <label for="rad8" class="pdfCheckbox">Rad 8-fach</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <input type="checkbox" id="servicemappe" checked readonly />
                  <label for="servicemappe" class="pdfCheckbox">Servicemappe</label>
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" id="radiokarte" checked readonly />
                  <label for="radiokarte" class="pdfCheckbox">Radiokarte</label>
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" id="garantieheft" checked readonly />
                  <label for="garantieheft" class="pdfCheckbox">Garantieheft</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <input type="checkbox" id="elektronikkarte" checked readonly />
                  <label for="elektronikkarte" class="pdfCheckbox">Elektronikkarte</label>
                </div>
              </td>
              <td>
                <div>
                  <input type="checkbox" id="windschott" checked readonly />
                  <label for="windschott" class="pdfCheckbox">Windschott</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <input type="text" id="toolExtra" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p v-if="baseCollection && baseCollection.getriebe">
                    Getriebe (Verschleiß): {{ baseCollection.getriebe }}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="tg">
          <thead>
            <tr>
              <th class="tg-7ojr">Reifen</th>
              <th class="tg-7ojr">VL</th>
              <th class="tg-abuq">VR</th>
              <th class="tg-abuq">HL</th>
              <th class="tg-abuq">HR</th>
            </tr>
          </thead>
          <tbody>
            <tr id="reifenname">
              <td class="tg-w6x5">Reifenname</td>
              <td class="tg-w6x5" style="max-width: 130px"></td>
              <td class="tg-8l38" style="max-width: 130px"></td>
              <td class="tg-8l38" style="max-width: 130px"></td>
              <td class="tg-8l38" style="max-width: 130px"></td>
            </tr>
            <tr id="profiltiefe">
              <td class="tg-w6x5">Profiltiefe (i m a)</td>
              <td class="tg-w6x5"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
            </tr>
            <tr id="reifendimension">
              <td class="tg-w6x5">Reifendimension</td>
              <td class="tg-w6x5"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
            </tr>
            <tr id="loadIndex">
              <td class="tg-w6x5">Lastindex</td>
              <td class="tg-w6x5"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
            </tr>
            <tr id="dot">
              <td class="tg-8l38">DOT</td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
            </tr>
            <tr id="tpms">
              <td class="tg-8l38">TPMS</td>
              <td class="tg-8l38">
                {{ baseCollection ? baseCollection.tpms_vl : "" }}
              </td>
              <td class="tg-8l38">
                {{ baseCollection ? baseCollection.tpms_vr : "" }}
              </td>
              <td class="tg-8l38">
                {{ baseCollection ? baseCollection.tpms_hl : "" }}
              </td>
              <td class="tg-8l38">
                {{ baseCollection ? baseCollection.tpms_hr : "" }}
              </td>
            </tr>
            <tr id="winterreifen">
              <td class="tg-8l3">Winterreifen?</td>
              <td class="tg-8l3"></td>
              <td class="tg-8l3"></td>
              <td class="tg-8l3"></td>
              <td class="tg-8l3"></td>
            </tr>
          </tbody>
        </table>

        <table class="brakeCheckboxTable">
          <thead>
            <tr>
              <th>
                <div style="padding-right: 15px">
                  <input type="checkbox" id="bremse" checked readonly />
                  <label for="bremse" class="pdfCheckbox">Carbonbremse</label>
                </div>
              </th>
              <th>
                <div>
                  <input type="checkbox" id="bremse2" checked readonly />
                  <label for="bremse2" class="pdfCheckbox">Stahlbremse</label>
                </div>
              </th>
            </tr>
          </thead>
        </table>

        <br />

        <!-- Bremse -->
        <table class="tg" style="margin-top: 0px !important">
          <thead>
            <tr>
              <th class="tg-7ojr">Bremse</th>
              <th class="tg-7ojr">VL</th>
              <th class="tg-abuq">VR</th>
              <th class="tg-abuq">HL</th>
              <th class="tg-abuq">HR</th>
            </tr>
          </thead>
          <tbody>
            <tr id="verschleiss">
              <td class="tg-8l38">Verschleiß (%)</td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
            </tr>
            <tr id="belaege">
              <td class="tg-8l3">Beläge (mm)</td>
              <td class="tg-8l3"></td>
              <td class="tg-8l3"></td>
              <td class="tg-8l3"></td>
              <td class="tg-8l3"></td>
            </tr>
          </tbody>
        </table>

        <!-- Fahrwerk -->
        <table class="tg">
          <thead>
            <tr>
              <th class="tg-7ojr">Fahrwerk</th>
              <th class="tg-7ojr">VL</th>
              <th class="tg-abuq">VR</th>
              <th class="tg-abuq">HL</th>
              <th class="tg-abuq">HR</th>
            </tr>
          </thead>
          <tbody>
            <tr id="radlagerspiel">
              <td class="tg-8l38">Radlagerspiel</td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
            </tr>
            <tr id="lenkungsspiel">
              <td class="tg-8l38">Lenkungsspiel</td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
            </tr>
            <tr id="spurstange">
              <td class="tg-8l3">Spurstange</td>
              <td class="tg-8l3"></td>
              <td class="tg-8l3"></td>
              <td class="tg-8l3"></td>
              <td class="tg-8l3"></td>
            </tr>
          </tbody>
        </table>

        <div class="pdfHeader pageBreak"></div>
        <!-- Lack optional -->
        <br class="" />
        <h3 class="">Lackschichtenmessung, in &#181;m</h3>
        <table class="tg">
          <thead>
            <tr>
              <th class="tg-7ojr">Fahrerseite</th>
              <th class="tg-sub">Kotflügel</th>
              <th class="tg-sub">Tür</th>
              <th class="tg-sub">Schweller</th>
              <th class="tg-sub">Seitenpanele</th>
            </tr>
          </thead>
          <tbody>
            <tr id="paint_fs">
              <td class="tg-8l38"></td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38">-</td>
            </tr>
          </tbody>

          <thead>
            <tr>
              <th class="tg-7ojr">Beifahrerseite</th>
              <th class="tg-sub">Kotflügel</th>
              <th class="tg-sub">Tür</th>
              <th class="tg-sub">Schweller</th>
              <th class="tg-sub">Seitenpanele</th>
            </tr>
          </thead>
          <tbody>
            <tr id="paint_bfs">
              <td class="tg-8l38"></td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38">-</td>
            </tr>
          </tbody>

          <thead>
            <tr>
              <th class="tg-7ojr">Front</th>
              <th class="tg-sub">Motorhaube</th>
              <th class="tg-sub">Stoßstange</th>
              <th class="tg-sub"></th>
              <th class="tg-sub"></th>
            </tr>
          </thead>
          <tbody>
            <tr id="paint_front">
              <td class="tg-8l38"></td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38"></td>
              <td class="tg-8l38"></td>
            </tr>
          </tbody>

          <thead>
            <tr>
              <th class="tg-7ojr">Heck</th>
              <th class="tg-sub">Heck</th>
              <th class="tg-sub">Stoßstange</th>
              <th class="tg-sub">Spoiler</th>
              <th class="tg-sub"></th>
            </tr>
          </thead>
          <tbody>
            <tr id="paint_heck">
              <td class="tg-8l38"></td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38">-</td>
              <td class="tg-8l38"></td>
            </tr>
          </tbody>

          <thead>
            <tr>
              <th class="tg-7ojr">Dach</th>
              <th class="tg-sub">Dach</th>
              <th class="tg-sub">Hardtop</th>
              <th class="tg-sub"></th>
              <th class="tg-sub"></th>
            </tr>
          </thead>
          <tbody>
            <tr id="paint_dach">
              <td class="tg-8l3"></td>
              <td class="tg-8l3">-</td>
              <td class="tg-8l3">-</td>
              <td class="tg-8l3"></td>
              <td class="tg-8l3"></td>
            </tr>
          </tbody>
        </table>

        <!-- Page after damages -->
        <div class="pdfHeader pageBreak"></div>
        <br />
        Fahrzeugkommentar:

        <div id="vehicleCommentPDF"></div>
        <br />
        Zusatzreparatur: 
        <div id="vehicleAdditionalDamagePDF">
        
        </div>
      </div>
      <div class="document">
        <!-- Page 6 and more-->
        <div id="damageDiv"></div>
      </div>
    </div>

    <v-overlay :value="loading" opacity="0.7">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
const axios = require("axios");
// import VueHtml2pdf from "vue-html2pdf";
import html2pdf from "../../node_modules/html2pdf.js/dist/html2pdf";

export default {
  name: "assessmentPDF",

  data() {
    return {
      loading: true,
      printPDF: undefined,
      ready: {
        report: false,
        document: false,
        statusCol: false,
      },
      damageImg: [], //keep track of damageimgs drawn to local
      mainImg: [], //keep track of mainimgs drawn to local
      report: undefined,
      vehicle: undefined,
      imagesPlaceholder: [
        "left",
        "paper1",
        "front",
        "right",
        "trunk",
        "engine",
        "inside",
        "back",
      ],
      imagesSorted: [],

      tireCollections: [],
      brakeCollections: [],
      suspensionCollections: [],
      baseCollection: undefined,
      toolCollection: undefined,
      paintCollection: undefined,

      initialHTML: undefined,

      finalReadyCheck: [],
    };
  },

  components: {
    // VueHtml2pdf,
  },

  mounted() {
    this.printPDF = this.$route.params.assessmentID;
    this.init(this.printPDF);
  },

  methods: {
    humanReadableDate(date) {
      if (date) {
        var dateArray = date.split("T");
        dateArray = dateArray[0].split("-");

        return dateArray[2] + "." + dateArray[1] + "." + dateArray[0];
      }
      return "";
    },

    waitForAxios(count) {
      let isReady = true;
      for (var variable in this.ready) {
        if (this.ready[variable] == false) {
          isReady = false;
          break;
        }
      }

      if (this.mainImg.length < 8) isReady = false;
      if (this.report == undefined || this.damageImg.length != this.report.damages.length)
        isReady = false;

      if (count > 0 && isReady == false) {
        setTimeout(() => {
          this.waitForAxios(count);
        }, 5000);
      } else if (count <= 0) {
        this.$emit("input", -5);
        return;
      } else {
        let element = document.getElementById("element-to-print");
        element.style = "display:block";

        let documents = element.getElementsByClassName("document");
        //first document is main stuff, second one is damages

        const opt1 = {
          filename: "Bewertung_" + this.report.reportsToVehicle.vin + ".pdf",
          pagebreak: { before: ".pageBreak" },
          margin: 11,
          image: { type: "jpeg", quality: 0.9 },
          jsPDF: { format: "a4", orientation: "portrait" },
          html2canvas: { allowTaint: true, useCORS: true, imageTimeout: 0 },
        };

        html2pdf()
          .from(documents[0])
          .set(opt1)
          .toPdf()
          .get("pdf")
          .then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages();

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor(150);
              pdf.text(
                pdf.internal.pageSize.width / 2 - 2,
                pdf.internal.pageSize.height - 10,
                "" + i
              );

              if (i == totalPages) {
                pdf.setFontSize(10);
                pdf.setTextColor(150);
              } else if (i == totalPages - 1) {
                pdf.text(
                  pdf.internal.pageSize.width / 2 + 2,
                  pdf.internal.pageSize.height - 10,
                  " - roba.one"
                );
              }
            }
          })
          .save()
          .then(
            function () {
              this.finalReadyCheck[0] = true;
            }.bind(this)
          );

        const opt2 = {
          filename: "Schadensliste_" + this.report.reportsToVehicle.vin + ".pdf",
          pagebreak: { before: ".pageBreak" },
          margin: 11,
          image: { type: "jpeg", quality: 0.9 },
          jsPDF: { format: "a4", orientation: "portrait" },
          html2canvas: { allowTaint: true, useCORS: true, imageTimeout: 0 },
        };

        html2pdf()
          .from(documents[1])
          .set(opt2)
          .toPdf()
          .get("pdf")
          .then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages();

            pdf.deletePage(1);
            totalPages--;

            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor(150);
              pdf.text(
                pdf.internal.pageSize.width / 2 - 2,
                pdf.internal.pageSize.height - 10,
                "" + i
              );

              if (i == totalPages) {
                pdf.setFontSize(10);
                pdf.setTextColor(150);
              } else if (i == totalPages - 1) {
                pdf.text(
                  pdf.internal.pageSize.width / 2 + 2,
                  pdf.internal.pageSize.height - 10,
                  " - roba.one"
                );
              }
            }
          })
          .save()
          .then(
            function () {
              this.finalReadyCheck[1] = true;
            }.bind(this)
          );

        this.finishIt();
      }
    },

    finishIt() {
      if (this.finalReadyCheck[0] && this.finalReadyCheck[1]) {
        let element = document.getElementById("element-to-print");
        element.style = "display:none";
        this.loading = false;

        setTimeout(() => {
          while (element.hasChildNodes()) {
            element.removeChild(element.lastChild);
          }
        }, 3000);

        // this.$emit("input", 0)
        this.$router.push(
          "/stock/vehicle/" + localStorage.getItem("selectedVehicle") + "/documents/1"
        );
      } else if (!this.$route.path.includes("stock")) {
        setTimeout(() => {
          this.finishIt();
        }, 3000);
      }
    },

    createHeader() {
      var elements = document.getElementsByClassName("pdfHeader");

      for (var i = 0; i < elements.length; i++) {
        var row = document.createElement("div");
        row.classList.add("pdfRow");
        row.style = "padding-top:12px";
        elements[i].appendChild(row);

        var col1 = document.createElement("div");
        col1.classList.add("pdfColumn");
        var col2 = document.createElement("div");
        col2.classList.add("pdfColumn");
        var col3 = document.createElement("div");
        col3.classList.add("pdfColumn");

        row.appendChild(col1);
        row.appendChild(col2);
        row.appendChild(col3);

        var newdiv = document.createElement("h2");
        newdiv.innerText = "VIN: " + this.report.reportsToVehicle.vin;
        newdiv.style = "width: 370px";
        col1.appendChild(newdiv);

        newdiv = document.createElement("h2");
        newdiv.innerText = "Eberlein Carware";
        newdiv.style = "width: 350px, text-align: right; padding-right: 20px";
        col2.appendChild(newdiv);

        newdiv = document.createElement("img");
        newdiv.src = require(`@/assets/images/EberleinLogo.png`);
        newdiv.style = "width: 75px";
        col3.appendChild(newdiv);

        newdiv = document.createElement("p");
        (newdiv.innerText =
          "Bewertung von: " +
          this.report.reportsToUser.first_name +
          " " +
          this.report.reportsToUser.last_name +
          ", " +
          this.humanReadableDate(this.report.last_updated)),
          (newdiv.style = "color: #333; font-size: large; display: inline-block");
        elements[i].appendChild(newdiv);

        var date = new Date();
        var timeString = date.toLocaleTimeString();
        var timeArray = timeString.split(":");
        timeString = timeArray[0] + ":" + timeArray[1];

        var monthString = date.getMonth() + 1;
        if (monthString < 10) monthString = "0" + monthString;
        newdiv = document.createElement("p");
        newdiv.innerText =
          ", PDF gedruckt: " +
          date.getDate() +
          "." +
          monthString +
          "." +
          date.getFullYear() +
          ", " +
          timeString;
        newdiv.style = "color: #333; font-size: large; display: inline-block";
        elements[i].appendChild(newdiv);
      }
    },
    createImages() {
      for (var i = 0; i < 8; i++) {
        let element = document.getElementById("img" + i);
        let newdiv = document.createElement("img");

        axios
          .get(this.imagesSorted[i], { responseType: "arraybuffer" })
          .then((res) => {
            var test = Buffer.from(res.data, "binary").toString("base64");

            newdiv.src = "data:image/png;base64," + test;
            // newdiv.style ="background-image: url("+ dataURL + ")"
            newdiv.classList.add("pdfImage");

            this.mainImg.push(true);
          })
          .catch((err) => {
            console.log(err);
            this.mainImg.push(true);
          });

        element.appendChild(newdiv);
      }
    },

    checkTools() {
      var checkbox;
      checkbox = document.getElementById("zweitschluessel");
      checkbox.checked = this.baseCollection.zweitschlüssel;

      checkbox = document.getElementById("fbaks");
      checkbox.checked = this.baseCollection.fbaks;

      checkbox = document.getElementById("bordwz");
      checkbox.checked = this.toolCollection.bordwerkzeug;

      checkbox = document.getElementById("paletot");
      checkbox.checked = this.toolCollection.paletot;

      checkbox = document.getElementById("ladegeraet");
      checkbox.checked = this.toolCollection.ladegeraet;

      checkbox = document.getElementById("reifenfk");
      checkbox.checked = this.toolCollection.reifenfuellkit;

      checkbox = document.getElementById("warndreieck");
      checkbox.checked = this.toolCollection.warndreieck;

      checkbox = document.getElementById("verband");
      checkbox.checked = this.toolCollection.verbandskasten;

      checkbox = document.getElementById("rad8");
      checkbox.checked = this.toolCollection.radsatz8;

      checkbox = document.getElementById("servicemappe");
      checkbox.checked = this.toolCollection.servicemappe;

      checkbox = document.getElementById("radiokarte");
      checkbox.checked = this.toolCollection.radiokarte;

      checkbox = document.getElementById("garantieheft");
      checkbox.checked = this.toolCollection.garantieheft;

      checkbox = document.getElementById("windschott");
      checkbox.checked = this.toolCollection.windschott;

      checkbox = document.getElementById("elektronikkarte");
      checkbox.checked = this.toolCollection.elektronikkarte;

      checkbox = document.getElementById("bremse");
      checkbox.checked = this.brakeCollections[0].bIsCarbon;

      checkbox = document.getElementById("bremse2");
      checkbox.checked = !this.brakeCollections[0].bIsCarbon;

      document.getElementById("toolExtra").value =
        "Zusatz: " + (this.toolCollection.comment || "-");
    },
    fillStatusColTables() {
      var elements = [];

      //tires
      elements = Array.from(document.getElementById("reifenname").children).slice(1);
      this.fillStatusColTableItems(elements, this.tireCollections, "model");

      elements = Array.from(document.getElementById("profiltiefe").children).slice(1);
      this.fillStatusColTableItems(
        elements,
        this.tireCollections,
        ["tireProfileInner", "tireProfileMiddle", "tireProfileOuter"],
        3
      );

      elements = Array.from(document.getElementById("reifendimension").children).slice(1);
      this.fillStatusColTableItems(
        elements,
        this.tireCollections,
        ["crossSection", "width", "diameter"],
        3
      );

      elements = Array.from(document.getElementById("loadIndex").children).slice(1);
      this.fillStatusColTableItems(elements, this.tireCollections, "loadIndex");

      elements = Array.from(document.getElementById("dot").children).slice(1);
      this.fillStatusColTableItems(elements, this.tireCollections, "dot");

      elements = Array.from(document.getElementById("winterreifen").children).slice(1);
      this.fillStatusColTableItems(elements, this.tireCollections, "bIsWinterTires");

      //brakes
      elements = Array.from(document.getElementById("verschleiss").children).slice(1);
      this.fillStatusColTableItems(elements, this.brakeCollections, "wear");

      elements = Array.from(document.getElementById("belaege").children).slice(1);
      this.fillStatusColTableItems(elements, this.brakeCollections, "brakePadThickness");

      //suspension
      elements = Array.from(document.getElementById("radlagerspiel").children).slice(1);
      this.fillStatusColTableItems(
        elements,
        this.suspensionCollections,
        "bHasWheelBearingClearance"
      );

      elements = Array.from(document.getElementById("lenkungsspiel").children).slice(1);
      this.fillStatusColTableItems(
        elements,
        this.suspensionCollections,
        "bHasWishboneClearance"
      );

      elements = Array.from(document.getElementById("spurstange").children).slice(1);
      this.fillStatusColTableItems(
        elements,
        this.suspensionCollections,
        "bHasTrackRodClearance"
      );

      //paint condition
      if (
        this.paintCollection != undefined &&
        this.paintCollection.meassureCondition == true
      ) {
        var paintElements = document.getElementById("paint_fs").children;
        paintElements[1].innerHTML = this.paintCollection.fs_kotfluegel || "-";
        paintElements[2].innerHTML = this.paintCollection.fs_tuer || "-";
        paintElements[3].innerHTML = this.paintCollection.fs_schweller || "-";
        paintElements[4].innerHTML = this.paintCollection.fs_seitenpanele || "-";

        paintElements = document.getElementById("paint_bfs").children;
        paintElements[1].innerHTML = this.paintCollection.bfs_kotfluegel || "-";
        paintElements[2].innerHTML = this.paintCollection.bfs_tuer || "-";
        paintElements[3].innerHTML = this.paintCollection.bfs_schweller || "-";
        paintElements[4].innerHTML = this.paintCollection.bfs_seitenpanele || "-";

        paintElements = document.getElementById("paint_front").children;
        paintElements[1].innerHTML = this.paintCollection.front_motorhaube || "-";
        paintElements[2].innerHTML = this.paintCollection.front_stossstange || "-";

        paintElements = document.getElementById("paint_heck").children;
        paintElements[1].innerHTML = this.paintCollection.heck_heck || "-";
        paintElements[2].innerHTML = this.paintCollection.heck_stossstange || "-";
        paintElements[3].innerHTML = this.paintCollection.heck_spoiler || "-";

        paintElements = document.getElementById("paint_dach").children;
        paintElements[1].innerHTML = this.paintCollection.dach_dach || "-";
        paintElements[2].innerHTML = this.paintCollection.dach_hardtop || "-";
      } else {
        var hidePaintConditions = document.getElementsByClassName("showPaintCondition");
        for (var i = 0; i < hidePaintConditions.length; i++) {
          hidePaintConditions[i].style.display = "none";
        }
      }
    },
    fillStatusColTableItems(items, collection, prop, length) {
      var i, j;

      for (i = 0; i < collection.length; i++) {
        if (length > 1) {
          items[i].innerText = "";
          for (j = 0; j < length; j++) {
            items[i].innerText += collection[i][prop[j]] + "\xa0";
          }
        } else {
          if (typeof collection[i][prop] == "boolean") {
            if (collection[i][prop] == true) {
              items[i].innerText += "ja";
            } else {
              items[i].innerText += "nein";
            }
          } else {
            items[i].innerText = collection[i][prop];
          }
        }
      }
    },
    fillBaseStats() {
      var element;

      //base
      element = document.getElementById("hersteller");
      element.innerText = this.report.reportsToVehicle.vehiclesToModel.vehicleManufacturerModelsToVehicleManufacturer.name;

      element = document.getElementById("fahrzeugmodell");
      element.innerText = this.report.reportsToVehicle.vehiclesToModel.name;

      element = document.getElementById("kundenname");
      element.innerText = this.report.reportsToVehicle.currentOwner || "-";

      element = document.getElementById("kilometerstand");
      element.innerText = this.report.reportsToVehicle.mileage;

      element = document.getElementById("kennzeichen");
      element.innerText = this.report.reportsToVehicle.plate || "-";

      //pricing
      element = document.getElementById("ankaufspreis");
      if (parseInt(this.report.reportsToVehicle.purchasePrice))
        element.innerText = this.report.reportsToVehicle.purchasePrice + "€";
      else element.innerText = "-";

      element = document.getElementById("reperaturpreis");
      if (!isNaN(parseInt(this.report.reportsToVehicle.repairPrice))) {
        let sum = parseInt(this.report.reportsToVehicle.repairPrice);

        if (!isNaN(parseInt(this.report.service))) {
          sum -= parseInt(this.report.service);
        }

        element.innerText = sum + "€";
      } else element.innerText = "-";

      element = document.getElementById("zusatzreparatur")
      if(this.report.additionalDamages[0]){
        element.innerText = this.report.additionalDamages[0].cost + "€"
      }
      else{
        element.innerText = "- €"
      }

      element = document.getElementById("servicekosten");
      if (!isNaN(parseInt(this.report.reportsToVehicle.repairPrice))) {
        let sum = 0;
        if (!isNaN(parseInt(this.report.service))) {
          sum = parseInt(this.report.service);
        }

        element.innerText = sum + "€";
      } else element.innerText = "-";

      element = document.getElementById("verkaufspreis");
      if (
        parseInt(
          this.report.reportsToVehicle.purchasePrice +
            this.report.reportsToVehicle.repairPrice
        )
      ) {
        var count =
          this.report.reportsToVehicle.purchasePrice +
          this.report.reportsToVehicle.repairPrice;
        element.innerText = count + "€";
      } else element.innerText = "-";
    },
    fillVehicleComment() {
      var commentElement = document.getElementById("vehicleCommentPDF");
      

      if (this.report.reportsToVehicle.vehicle_comment_creator == null)
        commentElement.innerHTML = "Kein Kommentar für dieses Fahrzeug vorhanden.";
      else
        commentElement.innerHTML =
          this.report.reportsToVehicle.vehicle_comment +
          "<br>" +
          this.report.reportsToVehicle.vehicle_comment_creator +
          ", " +
          this.humanReadableDate(this.report.reportsToVehicle.vehicle_comment_date);
    },
    fillAdditionalDamage(){
      let additionalDamageElement = document.getElementById("vehicleAdditionalDamagePDF");
      if(!this.report.additionalDamages[0]){
        additionalDamageElement.innerHTML = "Keine Zusatzreparaturen für dieses Fahrzeug notwendig."
      }
      else{
        additionalDamageElement.innerHTML =
          this.report.additionalDamages[0].description + "<br>" +
          "Kosten: <b>" + this.report.additionalDamages[0].cost + "€</b>"
      }
    },
    fillDamages() {
      //NEEDS TO BE DONE BEFORE HEADER CREATION!
      var damagesPerSite = 6;
      var pages = this.report.damages.length / damagesPerSite;
      this.report.damages.length % damagesPerSite != 0 ? pages++ : pages;
      pages = parseInt(pages);

      var element = document.getElementById("damageDiv");
      var newPageDiv = document.createElement("div");
      newPageDiv.classList.add("pdfHeader");
      newPageDiv.classList.add("pageBreak");

      var i;
      var count;
      var damage, damageDiv, childElement, wrapperDiv;
      for (i = 0; i < pages; i++) {
        element.appendChild(newPageDiv.cloneNode());

        wrapperDiv = document.createElement("div");
        wrapperDiv.classList.add("pdfDamageWrapper");
        element.appendChild(wrapperDiv);

        for (count = 0; count < damagesPerSite; count++) {
          if (i * damagesPerSite + count < this.report.damages.length) {
            damage = this.report.damages[i * damagesPerSite + count];
            damageDiv = document.createElement("div");
            damageDiv.classList.add("pdfDamageCard");

            childElement = document.createElement("img");

            let newIMG = document.createElement("img");

            axios
              .get(damage.imageFile, {
                responseType: "arraybuffer",
              })
              .then((res) => {
                var test = Buffer.from(res.data, "binary").toString("base64");
                // console.log("base", test)

                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");

                // var image = new Image();
                newIMG.onload = function () {
                  ctx.drawImage(newIMG, 0, 0);

                  this.damageImg.push(true);
                }.bind(this);

                newIMG.src = "data:image/png;base64," + test;
                // newIMG.src = "data:image/png;base64," + test;
                // this.damageImg.push(true);
              })
              .catch((err) => console.log(err));

            damageDiv.appendChild(newIMG);

            newIMG.classList.add("pdfDamageImage");

            childElement = document.createElement("div");
            childElement.classList.add("pdfDamageText");
            if (damage.damagesToPartGroup.name.includes("Fahrerseite")) {
              childElement.innerHTML =
                "Fahrerseite" + "/" + damage.damagesToVehicleParts.name;
            } else if (damage.damagesToPartGroup.name.includes("Beifahrerseite")) {
              childElement.innerHTML =
                "Beifahrerseite" + "/" + damage.damagesToVehicleParts.name;
            } else {
              childElement.innerHTML =
                damage.damagesToPartGroup.name + "/" + damage.damagesToVehicleParts.name;
            }
            if (damage.damagesToVehicleParts.name != damage.damagesToComponent.name) {
              childElement.innerHTML += "/" + damage.damagesToComponent.name;
            }
            damageDiv.appendChild(childElement);

            childElement = document.createElement("div");
            childElement.classList.add("pdfDamageHeader");
            childElement.innerHTML = damage.damagesToDamageType.name;
            damageDiv.appendChild(childElement);

            childElement = document.createElement("div");
            childElement.classList.add("pdfDamageSubHeader");
            var stageIndex = damage.damageStageIndex;
            switch (stageIndex) {
              case 0:
                childElement.innerHTML =
                  damage.damagesToDamageType.damageTypesToDamageStage.small;
                break;
              case 1:
                childElement.innerHTML =
                  damage.damagesToDamageType.damageTypesToDamageStage.medium;
                break;
              case 2:
                childElement.innerHTML =
                  damage.damagesToDamageType.damageTypesToDamageStage.large;
                break;
              default:
                childElement.innerHTML = "-";
                break;
            }
            damageDiv.appendChild(childElement);

            childElement = document.createElement("hr");
            childElement.classList.add("pdfDamageHr");
            damageDiv.appendChild(childElement);

            childElement = document.createElement("div");
            childElement.classList.add("pdfDamageCost");
            if (damage.cost == null || damage.cost == 0) {
              childElement.innerHTML = "- €";
            } else {
              childElement.innerHTML = damage.cost + "€";
            }

            damageDiv.appendChild(childElement);

            wrapperDiv.appendChild(damageDiv);
          }
        }
      }
    },
    init(reportID) {
      this.ready.report = false;
      this.ready.document = false;
      this.ready.statusCol = false;

      this.report = undefined;
      (this.imagesPlaceholder = [
        "left",
        "paper1",
        "front",
        "right",
        "trunk",
        "engine",
        "inside",
        "back",
      ]),
        (this.imagesSorted = []);

      this.tireCollections = [];
      this.brakeCollections = [];
      this.suspensionCollections = [];
      this.baseCollection = undefined;
      this.toolCollection = undefined;

      var element = document.getElementById("element-to-print");

      if (element.hasChildNodes() == true) {
        this.initialHTML = document.createElement("div");
        for (var i = 0; i < element.children.length; i++) {
          this.initialHTML.appendChild(element.children[i].cloneNode(true));
        }
      } else {
        for (var j = 0; j < this.initialHTML.childNodes.length; j++) {
          element.appendChild(this.initialHTML.childNodes[j].cloneNode(true));
        }
      }

      //fetch document
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "documentSearchByVehicleAndType/?vin=" +
            localStorage.getItem("selectedVehicle") +
            "&type=FS vorn",
          { timeout: 10000 }
        )
        .then((response) => {
          if (response.data.length > 0) {
            this.imagesSorted[1] = response.data[0].document;
          } else {
            this.mainImg.push(true);
          }

          this.ready.document = true;
        });

      //fetch report
      axios //authenticated get request
        .get(axios.defaults.baseURL + "reports/" + reportID + "/", {
          timeout: 10000,
        })
        .then((response) => {
          this.report = response.data;

          this.fillVehicleComment();
          this.fillAdditionalDamage();

          //fetch status collection
          axios //authenticated get request
            .get(
              axios.defaults.baseURL + "statusColByVehicle/?report=" + this.report.id,
              { timeout: 10000 }
            )
            .then((resp) => {
              this.tireCollections = resp.data[0].tireCollection;
              this.brakeCollections = resp.data[0].brakeCollection;
              this.suspensionCollections = resp.data[0].suspCollection;
              this.toolCollection = resp.data[0].toolCollection[0];
              this.baseCollection = resp.data[0].baseCollection[0];
              this.paintCollection = resp.data[0].paintCollection[0];

              axios //authenticated get request
                .get(axios.defaults.baseURL + "vehiclePartGroups/", {
                  timeout: 10000,
                })
                .then((r) => {
                  var i, j;

                  //map location ids to location names and sort them (VL, VR, HL, HR)
                  var tempArray = [];
                  for (i = 0; i < this.tireCollections.length; i++) {
                    for (j = 0; j < r.data.length; j++) {
                      if (r.data[j].id == this.tireCollections[i].location) {
                        this.tireCollections[i].location = r.data[j].name.split(" ")[1];

                        switch (this.tireCollections[i].location) {
                          case "VL":
                            tempArray[0] = this.tireCollections[i];
                            break;
                          case "VR":
                            tempArray[1] = this.tireCollections[i];
                            break;
                          case "HL":
                            tempArray[2] = this.tireCollections[i];
                            break;
                          case "HR":
                            tempArray[3] = this.tireCollections[i];
                            break;
                        }
                      }
                    }
                  }

                  this.tireCollections = tempArray;

                  tempArray = [];

                  for (i = 0; i < this.brakeCollections.length; i++) {
                    for (j = 0; j < r.data.length; j++) {
                      if (r.data[j].id == this.brakeCollections[i].location) {
                        this.brakeCollections[i].location = r.data[j].name.split(" ")[1];

                        switch (this.brakeCollections[i].location) {
                          case "VL":
                            tempArray[0] = this.brakeCollections[i];
                            break;
                          case "VR":
                            tempArray[1] = this.brakeCollections[i];
                            break;
                          case "HL":
                            tempArray[2] = this.brakeCollections[i];
                            break;
                          case "HR":
                            tempArray[3] = this.brakeCollections[i];
                            break;
                        }
                      }
                    }
                  }

                  this.brakeCollections = tempArray;

                  tempArray = [];

                  for (i = 0; i < this.suspensionCollections.length; i++) {
                    for (j = 0; j < r.data.length; j++) {
                      if (r.data[j].id == this.suspensionCollections[i].location) {
                        this.suspensionCollections[i].location = r.data[j].name.split(
                          " "
                        )[1];

                        switch (this.suspensionCollections[i].location) {
                          case "VL":
                            tempArray[0] = this.suspensionCollections[i];
                            break;
                          case "VR":
                            tempArray[1] = this.suspensionCollections[i];
                            break;
                          case "HL":
                            tempArray[2] = this.suspensionCollections[i];
                            break;
                          case "HR":
                            tempArray[3] = this.suspensionCollections[i];
                            break;
                        }
                      }
                    }
                  }

                  this.suspensionCollections = tempArray;

                  this.checkTools();
                  this.fillStatusColTables();
                  this.fillBaseStats();

                  this.ready.statusCol = true;
                });
            });

          let tempImages = this.report.images;
          let index;

          for (let image in tempImages) {
            for (index = 0; index < this.imagesPlaceholder.length; index++) {
              if (this.imagesPlaceholder[index] == tempImages[image].slot) {
                this.imagesSorted[index] = tempImages[image].imageFile;
                break;
              }
            }
          }

          this.fillDamages();
          this.createHeader();
          this.createImages();

          this.ready.report = true;
        })
        .catch((error) => console.log(error));

      //wait until done
      this.waitForAxios(10);
    },
  },
};
</script>
