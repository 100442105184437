<template>
  <div id="suspensionCondition">
    <v-form ref="suspensionForm">
      <v-expansion-panels class="pa-0 ma-0" flat :value="panel">
        <v-expansion-panel expand @click="changePlusIcon()">
          <v-expansion-panel-header disable-icon-rotate class="px-9 py-0">
            <div class="statusPreIcon">
              <v-icon v-if="displayPlus" color="secondary"> mdi-plus </v-icon>
              <v-icon v-else color="secondary"> mdi-minus </v-icon>
            </div>
            <h4 height="32px">Statuserfassung</h4>
            <template v-slot:actions>
              <v-icon color="teal" v-if="bIsFinished == true">
                mdi-check
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid class="pa-0 ma-0 px-2">
              <p class="pb-0 mb-0">Radlagerspiel</p>
              <v-row>
                <v-col cols="12">
                  <v-slider
                    v-model="bHasWheelBearingClearance"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(
                        bHasWheelBearingClearance,
                        'bHasWheelBearingClearance'
                      )
                    "
                  ></v-slider>
                </v-col>
              </v-row>
              <p class="pb-0 mb-0">Lenkungsspiel</p>
              <v-row>
                <v-col cols="12">
                  <v-slider
                    v-model="bHasWishboneClearance"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(
                        bHasWishboneClearance,
                        'bHasWishboneClearance'
                      )
                    "
                  ></v-slider>
                </v-col>
              </v-row>
              <p class="pb-0 mb-0">Spurstange</p>
              <v-row>
                <v-col cols="12">
                  <v-slider
                    v-model="bHasTrackRodClearance"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(
                        bHasTrackRodClearance,
                        'bHasTrackRodClearance'
                      )
                    "
                  ></v-slider>
                </v-col>
              </v-row>
            </v-container>

            <v-divider />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>

    <errorPopup :on="errorOn" :data="'Statuserfassung Fahrwerk'" />
  </div>
</template>

<script>
import errorPopup from "../ErrorPopup.vue";

const axios = require("axios");

export default {
  name: "suspensionCondition",
  props: ["partGroupID"],

  data() {
    return {
      errorOn: false, 

      suspensionConditionID: undefined,
      statusCollectionID: undefined,

      bHasTrackRodClearance: 0,
      bHasWishboneClearance: 0,
      bHasWheelBearingClearance: 0,

      bIsFinished: false,
      panel: 0,
      displayPlus: false,

      rules: [(v) => v != 0],
    };
  },

   components: {
    errorPopup,
  },

  methods: {
    pushToDB(val, valName) {
      axios //authenticated get request
        .put(
          axios.defaults.baseURL +
            "suspensionConditions/" +
            this.suspensionConditionID +
            "/",
          {
            [valName]: val,
            statusToSuspensionConditions: this.statusCollectionID,
            location: this.partGroupID,
          }
        )
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
    },
    validateBoolAndPush(val, valName) {
      if (val != 0) {
        val == -1
          ? this.pushToDB(true, valName)
          : this.pushToDB(false, valName);
      }
    },
    initialise() {
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "simpleStatusColByVehicle/?vin=" +
            localStorage.getItem("selectedVehicle")
        )
        .then((response) => {
          this.statusCollectionID = response.data[0].id;
          for (var j = 0; j < response.data[0].suspCollection.length; j++) {
            if (
              response.data[0].suspCollection[j].location.id == this.partGroupID
            ) {
              this.suspensionConditionID =
                response.data[0].suspCollection[j].id;
              break;
            }
          }

          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "suspensionConditions/" +
                this.suspensionConditionID +
                "/"
            )
            .then((resp) => {
              if (resp.data.bHasTrackRodClearance == undefined)
                this.bHasTrackRodClearance = 0;
              else if (resp.data.bHasTrackRodClearance == true)
                this.bHasTrackRodClearance = -1;
              else this.bHasTrackRodClearance = 1;

              if (resp.data.bHasWishboneClearance == undefined)
                this.bHasWishboneClearance = 0;
              else if (resp.data.bHasWishboneClearance == true)
                this.bHasWishboneClearance = -1;
              else this.bHasWishboneClearance = 1;

              if (resp.data.bHasWheelBearingClearance == undefined)
                this.bHasWheelBearingClearance = 0;
              else if (resp.data.bHasWheelBearingClearance == true)
                this.bHasWheelBearingClearance = -1;
              else this.bHasWheelBearingClearance = 1;
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    changePlusIcon(){
      this.displayPlus = !this.displayPlus
    }
  },

  mounted() {
    this.$refs.suspensionForm.validate()
    this.initialise();
  },

  updated() {
    if (this.$refs.suspensionForm.validate() && this.bIsFinished != true) {
      
      axios //authenticated get request
        .put(
          axios.defaults.baseURL +
            "suspensionConditions/" +
            this.suspensionConditionID +
            "/",
          {
            statusToSuspensionConditions: this.statusCollectionID,
            location: this.partGroupID,

            bHasWishboneClearance: this.bHasWishboneClearance == 1 ? false : true,
            bHasTrackRodClearance: this.bHasTrackRodClearance == 1 ? false : true,
            bHasWheelBearingClearance: this.bHasWheelBearingClearance == 1 ? false : true,
          }
        )
        .then(() => {
          setTimeout(() => {
          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "suspensionConditions/" +
                this.suspensionConditionID +
                "/",
            )
            .then((resp) => {
              var finished = true

              if(resp.data.bHasWishboneClearance == null) finished = false
              if(resp.data.bHasTrackRodClearance == null) finished = false
              if(resp.data.bHasWheelBearingClearance == null) finished = false

              if(finished == true){
                this.bIsFinished = true
                this.$emit("input", true)

                axios //authenticated get request
                .put(
                  axios.defaults.baseURL +
                    "suspensionConditions/" +
                    this.suspensionConditionID +
                    "/",
                  {
                    statusToSuspensionConditions: this.statusCollectionID,
                    location: this.partGroupID,
                    bIsFinished: true
                  }
                )
                .catch((error) => {
                  if (error.response == undefined) {
                    this.errorOn = true
                }})
              }
              }, 1000);
        })})
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
    }
  },
};
</script>