var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"suspensionCondition"}},[_c('v-form',{ref:"suspensionForm"},[_c('v-expansion-panels',{staticClass:"pa-0 ma-0",attrs:{"flat":"","value":_vm.panel}},[_c('v-expansion-panel',{attrs:{"expand":""},on:{"click":function($event){return _vm.changePlusIcon()}}},[_c('v-expansion-panel-header',{staticClass:"px-9 py-0",attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.bIsFinished == true)?_c('v-icon',{attrs:{"color":"teal"}},[_vm._v(" mdi-check ")]):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"statusPreIcon"},[(_vm.displayPlus)?_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-plus ")]):_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-minus ")])],1),_c('h4',{attrs:{"height":"32px"}},[_vm._v("Statuserfassung")])]),_c('v-expansion-panel-content',[_c('v-container',{staticClass:"pa-0 ma-0 px-2",attrs:{"fluid":""}},[_c('p',{staticClass:"pb-0 mb-0"},[_vm._v("Radlagerspiel")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-slider',{attrs:{"max":"1","min":"-1","value":"0","rules":_vm.rules,"color":"secondary","track-color":"secondary","thumb-color":"primary","ticks":"always","tick-labels":['ja', '', 'nein']},on:{"change":function($event){return _vm.validateBoolAndPush(
                      _vm.bHasWheelBearingClearance,
                      'bHasWheelBearingClearance'
                    )}},model:{value:(_vm.bHasWheelBearingClearance),callback:function ($$v) {_vm.bHasWheelBearingClearance=$$v},expression:"bHasWheelBearingClearance"}})],1)],1),_c('p',{staticClass:"pb-0 mb-0"},[_vm._v("Lenkungsspiel")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-slider',{attrs:{"max":"1","min":"-1","value":"0","rules":_vm.rules,"color":"secondary","track-color":"secondary","thumb-color":"primary","ticks":"always","tick-labels":['ja', '', 'nein']},on:{"change":function($event){return _vm.validateBoolAndPush(
                      _vm.bHasWishboneClearance,
                      'bHasWishboneClearance'
                    )}},model:{value:(_vm.bHasWishboneClearance),callback:function ($$v) {_vm.bHasWishboneClearance=$$v},expression:"bHasWishboneClearance"}})],1)],1),_c('p',{staticClass:"pb-0 mb-0"},[_vm._v("Spurstange")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-slider',{attrs:{"max":"1","min":"-1","value":"0","rules":_vm.rules,"color":"secondary","track-color":"secondary","thumb-color":"primary","ticks":"always","tick-labels":['ja', '', 'nein']},on:{"change":function($event){return _vm.validateBoolAndPush(
                      _vm.bHasTrackRodClearance,
                      'bHasTrackRodClearance'
                    )}},model:{value:(_vm.bHasTrackRodClearance),callback:function ($$v) {_vm.bHasTrackRodClearance=$$v},expression:"bHasTrackRodClearance"}})],1)],1)],1),_c('v-divider')],1)],1)],1)],1),_c('errorPopup',{attrs:{"on":_vm.errorOn,"data":'Statuserfassung Fahrwerk'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }