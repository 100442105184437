<template>
  <div id="detailCard">
    <v-card class="mx-4 whiteBG rounded-xl" @click="toVehicle">
      <v-img height="200px" :src="imageFile"> </v-img>

      <v-card-title>
        <div v-if="plate">{{ plate }} </div>
        <div><br></div>
        <v-spacer />
        <v-icon v-if="pricingNeeded && !assessmentNeeded" id="alertIcon" x-large
          >mdi-currency-eur</v-icon
        >
        <v-icon v-if="assessmentNeeded" id="alertIcon" x-large
          >mdi-tools</v-icon
        >
      </v-card-title>

      <v-divider class="mx-4"></v-divider>

      <v-card-text class="text--primary text-no-wrap text-truncate">
        <div v-if="humanReadableDate!=''"> Bewertung: {{ this.humanReadableUserName }}, <br> am {{ humanReadableDate }} </div>
        <div v-else> <p class="pa-0 ma-0"> {{ this.humanReadableUserName }} <br><br></p> </div>
        <br />
        VIN: {{ vin }} <br />
        <div v-if="owner">Besitzer: {{ owner }} <br /></div>
        <div v-else><br /></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DetailCard",
  props: [
    "plate",
    "vin",
    "imageFile",
    "user",
    "owner",
    "last_updated",
    "pricingNeeded",
    "assessmentNeeded",
  ],

  data() {
    return {
      humanReadableUserName: this.user
        ? this.user.first_name + " " + this.user.last_name
        : "Noch keine Bewertung vorhanden!",
    };
  },

  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "5em";
        case "sm":
          return "5em";
        case "md":
          return "10em";
        case "lg":
          return "10em";
        case "xl":
          return "15em";
        default:
          return "15em";
      }
    },
    buttonWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "1em";
        case "sm":
          return "1em";
        case "md":
          return "2em";
        case "lg":
          return "6em";
        case "xl":
          return "6em";
        default:
          return "5em";
      }
    },
    humanReadableDate() {
      if (this.last_updated) {
        var dateArray = this.last_updated.split("T");
        dateArray = dateArray[0].split("-");

        return dateArray[2] + "." + dateArray[1] + "." + dateArray[0];
      }
      return ""
    },
  },

  methods: {
    toVehicle() {
      this.$router.push("/stock/vehicle/" + this.vin + "/overview");
    },
  },
};
</script>