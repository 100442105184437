<template>
  <div id="vehicleOverview" class="whiteBG mx-6">
    <v-form ref="form">
    <v-container fluid>
      <v-row class="mt-1 pt-1">
        <v-col class="mt-0 pt-0" :cols="textCols">
          <v-img
            class="mb-6 white--text align-center text-center font-weight-medium rounded-lg"
            :height="imgHeight"
            :src="
              leftImageDisplay ||
              require('../assets/images/placeholders/ph_left.jpg')
            "
            :gradient="
              bHasMainImages[0]
                ? ''
                : 'to bottom, rgba(0,0,0,.3), rgba(0,0,0,.5)'
            "
          >
          </v-img>
        </v-col>

        <v-col class="mt-0 pt-0" :cols="textCols">
          <v-img
            class="mb-6 white--text align-center text-center font-weight-medium rounded-lg"
            :height="imgHeight"
            :src="
              paper1ImageDisplay ||
              require('../assets/images/placeholders/ph_paper1.jpg')
            "
            :gradient="
              bHasMainImages[1]
                ? ''
                : 'to bottom, rgba(0,0,0,.3), rgba(0,0,0,.5)'
            "
          >
            <v-icon v-if="!bHasMainImages[1]" @click="paper1Overlay = true">
              mdi-plus</v-icon
            >
            <v-icon v-else @click="paper1Overlay = true">
              mdi-circle-edit-outline</v-icon
            >
          </v-img>
        </v-col>
        <v-col class="mt-0 pt-0" :cols="textCols">
          <v-img
            class="mb-6 white--text align-center text-center font-weight-medium rounded-lg"
            :height="imgHeight"
            :src="
              paper2ImageDisplay ||
              require('../assets/images/placeholders/ph_paper2.jpg')
            "
            :gradient="
              bHasMainImages[2]
                ? ''
                : 'to bottom, rgba(0,0,0,.3), rgba(0,0,0,.5)'
            "
          >
            <v-icon v-if="!bHasMainImages[2]" @click="paper2Overlay = true">
              mdi-plus</v-icon
            >
            <v-icon v-else @click="paper2Overlay = true">
              mdi-circle-edit-outline</v-icon
            >
          </v-img>
        </v-col>
      </v-row>

      <v-row class="py-0 px-6">
        <v-col :cols="textCols" class="py-0">
          <v-text-field
            label="Hersteller"
            class="px-4"
            disabled
            :value="
              vehicle.vehiclesToModel
                ? vehicle.vehiclesToModel
                    .vehicleManufacturerModelsToVehicleManufacturer.name
                : ''
            "
          ></v-text-field>

          <v-container fluid class="pa-0">
            <v-row>
              <v-col>
                <v-text-field
                  :value="vehicle.vin"
                  label="Fahrgestellnummer"
                  class="px-4"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col class="mb-0 pb-0" cols="auto">
                <v-icon :disabled="!getPerm(4)" @click="changeCrashVehicle(false)" v-if="vehicle.crashVehicle" class="icon-on mb-n6 px-0 mr-4">mdi-alpha-u-box-outline</v-icon>
                <v-icon :disabled="!getPerm(4)" @click="changePopUp = true" v-else class="icon-off mb-n6 px-0 mr-4">mdi-alpha-u-box-outline</v-icon>
              </v-col>
            </v-row>
          </v-container>

          <v-text-field
            :value="vehicle.vehiclesToModel ? vehicle.vehiclesToModel.name : ''"
            label="Fahrzeugmodell"
            disabled
            class="px-4"
          ></v-text-field>
        </v-col>

        <v-col :cols="textCols" class="py-0">
          <v-text-field
            :value="vehicle.currentOwner || ''"
            label="Kundenname"
            class="px-4"
            v-model="vehicle.currentOwner"
            @change="bHasChanged = true"
          ></v-text-field>

          <v-container fluid class="pa-0">
            <v-row>
              <v-col cols="5" class="pr-1">
                <v-text-field
                  :value="vehicle.mileage ? vehicle.mileage : ''"
                  label="Kilometerstand"
                  class="pl-4"
                  v-model="vehicle.mileage"
                  @change="bHasChanged = true"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="px-1">
                <v-text-field
                  label="TÜV/AU: Monat"
                  @change="bHasChanged = true"
                  v-model="vehicle.tuvau_month"
                  :value="vehicle.tuvau_month "
                  :rules="monthRules"
                ></v-text-field>
              </v-col>
              <v-col class="pl-1 pr-3">
                <v-text-field
                  :value="vehicle.tuvau_year"
                  label="Jahr"
                  class="pr-4"
                  v-model="vehicle.tuvau_year"
                  @change="bHasChanged = true"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-container fluid class="pa-0">
            <v-row>
              <v-col cols="5" class="pr-1">
                <v-text-field
                  :value="vehicle.plate"
                  :rules="rules"
                  label="Kennzeichen"
                  class="pl-4"
                  v-model="vehicle.plate"
                  @change="bHasChanged = true"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="px-1">
                <v-text-field
                  label="Insp.: Monat"
                  v-model="vehicle.inspection_month"
                  :value="vehicle.inspection_month"
                  :rules="monthRules"
                  @change="bHasChanged = true"
                ></v-text-field>
              </v-col>
              <v-col class="pl-1 pr-3">
                <v-text-field
                  :value="vehicle.inspection_year || ''"
                  label="Jahr"
                  class="pr-4"
                  v-model="vehicle.inspection_year"
                  @change="bHasChanged = true"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

        </v-col>
        <v-col :cols="textCols" class="py-0">
          <v-container fluid class="pa-0">
            <v-row>
              <v-col>
                <v-text-field
                  :value="vehicle.purchasePrice || 'Nicht vorhanden'"
                  label="Ankaufspreis"
                  :disabled="!getPermEqual(4) || !vehicle.bInHouse"
                  class="px-4"
                  v-model="vehicle.purchasePrice"
                  :type="!getPermEqual(4) ? 'password' : 'regular'"
                  @change="bHasChanged = true"
                ></v-text-field>
              </v-col>
              <v-col class="mb-0 pb-0" cols="auto">
                <v-icon @click="changeInHouseStatus(false)" class="icon-on-yellow mb-n6 px-0 mr-4" v-if="vehicle.bInHouse == true">
                  mdi-garage
                </v-icon>
                <v-icon @click="changeInHouseStatus(true)" class="icon-off  mb-n6 px-0 mr-4" v-else>
                  mdi-garage
                </v-icon>
              </v-col>
            </v-row>
          </v-container>

          <v-text-field
          
            :value="
              vehicle.bFinishedPricing
                ? vehicle.repairPrice
                : '--Bewertung nicht abgeschlossen!--'
            "
            :type="(vehicle.bFinishedPricing && !getPermEqual(4)) ? 'password' : 'regular'"
            label="Reperaturkosten"
            disabled
            class="px-4"
          ></v-text-field>

          <v-text-field
            :type="(vehicle.repairPrice && vehicle.purchasePrice && vehicle.bFinishedPricing && !getPermEqual(4)) ? 'password' : 'regular'"
            :value="
              (vehicle.purchasePrice == 0 || vehicle.purchasePrice == null) ? '--Nicht angekauft--' :
              (vehicle.bFinishedPricing
                ? parseInt(vehicle.repairPrice) + parseInt(vehicle.purchasePrice)
                : '--Bewertung nicht abgeschlossen!--')
            "
            label="Einkaufspreis"
            disabled
            class="px-4"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="5">
          <v-btn
            block
            depressed
            large
            color="primary"
            elevation="2"
            @click="startNewAssessment"
            :disabled="bAssessmentButtonDisabled || !getPerm(3)"
            >Bewertungsassistent starten</v-btn
          >
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>

    <v-overlay :value="loading" opacity="0.1">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>

    <v-overlay :value="paper1Overlay">
      <overlay
        v-if="!bHasMainImages[1]"
        :title="'Neues Dokument'"
        :data="[undefined, 'FS vorn', 'Fahrzeugschein vorn']"
        v-model="paper1Overlay"
        :type="'document'"
      ></overlay>
      <overlay
        v-else
        :title="'Dokument editieren'"
        :type="'document'"
        :data="[paper1Document, 'FS vorn', 'Fahrzeugschein vorn']"
        v-model="paper1Overlay"
      ></overlay>
    </v-overlay>

    <v-overlay :value="paper2Overlay">
      <overlay
        v-if="!bHasMainImages[2]"
        :title="'Neues Dokument'"
        :type="'document'"
        :data="[undefined, 'FS hinten', 'Fahrzeugschein hinten']"
        v-model="paper2Overlay"
      ></overlay>
      <overlay
        v-else
        :title="'Dokument editieren'"
        :type="'document'"
        :data="[paper2Document, 'FS hinten', 'Fahrzeugschein hinten']"
        v-model="paper2Overlay"
      ></overlay>
    </v-overlay>
    </v-form>

    <errorPopup :on="errorOn" />


    <v-dialog
      v-model="changePopUp"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Fahrzeug als Unfallfahrzeug markieren?
        </v-card-title>

        <v-card-text class="pa-4">
          Diese Aktion kann NICHT rückgängig gemacht werden. Wirklich fortfahren?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="changePopUp = false"
          >
            Doch nicht
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="changeCrashVehicle(true)"
          >
            Fortfahren!
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="notChangeablePopup"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Statusänderung Fehlgeschlagen!
        </v-card-title>

        <v-card-text class="pa-4">
          Das Fahrzeug wurde als Unfallfahrzeug markiert. Diese Aktion kann nicht rückgängig gemacht werden.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="notChangeablePopup = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import overlay from "../components/Overlays/Overlay.vue";
import errorPopup from "../components/ErrorPopup.vue";

const axios = require("axios");

export default {
  name: "vehicleOverview",

  data() {
    return {
      errorOn: false, 

      leftImage: undefined,
      bAllowUpload: false,

      leftImageDisplay: undefined,
      paper1ImageDisplay: undefined,
      paper2ImageDisplay: undefined,

      paper1Document: undefined,
      paper2Document: undefined,

      paper1Overlay: false,
      paper2Overlay: false,

      vehicle: {},
      bAssessmentButtonDisabled: true,
      bHasMainImages: [false, false, false],
      loading: false,
      bHasChanged: false,

      rules: [(v) => !v || v.length <= 10],
      monthRules: [(v) => (!v) || (!isNaN(parseInt(v)) && v > 0 && v < 13)],

      changePopUp: false,
      notChangeablePopup: false,
    };
  },

  components: {
    overlay,
    errorPopup,
  },

  watch: {
    leftImage: function () {
      if (this.bAllowUpload) {
        if (this.bHasMainImages[0]) {
          this.putImage("left", this.leftImage);
        } else {
          this.postImage("left", this.leftImage);
        }

        this.leftImage = undefined;
        this.bAllowUpload = false;
      }
    },
    paper1Overlay: function (val) {
      if (val == false) {
        this.updateImageFiles();
      }
    },
    paper2Overlay: function (val) {
      if (val == false) {
        this.updateImageFiles();
      }
    },
    bHasChanged: function (val){
      if(val == true){
        this.updateVehicle();
        this.bHasChanged = false;
      }
      
    }
  },

  computed: {
    textCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
            return 12;
        default:
          return 4;
      }
    },
    imgHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "300px";
        case "sm":
          return "300px";
        case "md":
          return "300px";
        case "lg":
          return "250px";
        default:
          return "400px";
      }
    },
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    putImage(slot, file) {
      var id;
      for (var i = 0; i < this.vehicle.images.length; i++) {
        if (this.vehicle.images[i].slot == slot) {
          id = this.vehicle.images[i].id;
          break;
        }
      }

      let data = new FormData(); // creates a new FormData object

      //append image file
      data.append("imageFile", file);
      data.append("slot", slot);
      data.append("bIsKeyVisual", true);
      data.append(
        "vehiclePhotosToVehicle",
        localStorage.getItem("selectedVehicle")
      );

      axios //authenticated get request
        .put(axios.defaults.baseURL + "vehiclePhotos/" + id + "/", data)
        .then(() => {
          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "vehicles/" +
                localStorage.getItem("selectedVehicle") +
                "/"
            )
            .then((response) => {
              this.vehicle = {};
              this.vehicle = response.data;
              this.updateImageFiles();
            });
        })
        .catch((error) => {
          console.log(error)
          if (error.response == undefined) {
              this.errorOn = true
              this.loading = false
            }
        });
    },
    postImage(slot, file) {
      let data = new FormData(); // creates a new FormData object

      //append image file
      data.append("imageFile", file);
      data.append("slot", slot);
      data.append("bIsKeyVisual", true);
      data.append(
        "vehiclePhotosToVehicle",
        localStorage.getItem("selectedVehicle")
      );

      axios //authenticated get request
        .post(axios.defaults.baseURL + "vehiclePhotos/", data)
        .then(() => {
          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "vehicles/" +
                localStorage.getItem("selectedVehicle") +
                "/"
            )
            .then((response) => {
              this.vehicle = {};
              this.vehicle = response.data;
              this.updateImageFiles();
            });
        })
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
                this.loading = false
              }
            })
    },
    updateImageFiles() {
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "documentSearchByVehicleAndType/?vin=" +
            localStorage.getItem("selectedVehicle") +
            "&type=FS vorn"
        )
        .then((response) => {
          if(response.data.length > 0){
          this.paper1Document = response.data[0];
          this.paper1ImageDisplay = response.data[0].document;
          this.bHasMainImages[1] = true;
          }
        });

      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "documentSearchByVehicleAndType/?vin=" +
            localStorage.getItem("selectedVehicle") +
            "&type=FS hinten"
        )
        .then((response) => {
          
          if(response.data.length > 0){
          this.paper2Document = response.data[0];
          this.paper2ImageDisplay = response.data[0].document;
          this.bHasMainImages[2] = true;
          }
        });

      if (this.vehicle.images.length > 0) {
        this.leftImageDisplay = undefined;

        for (var i = 0; i < this.vehicle.images.length; i++) {
          if (this.vehicle.images[i].slot == "left") {
            this.leftImageDisplay = this.vehicle.images[i].imageFile;
            this.bHasMainImages[0] = true;
            break;
          }
        }
      }
    },
    backToModel() {
      if (this.$route.params.vin)
        this.$router.push(
          "/stock/model/" + localStorage.getItem("selectedModel")
        );
      else this.$router.push("/stock");
    },
    updateVehicle() {
        this.loading = true;
        var tempVehicle;

        axios //authenticated get request
          .get(
            axios.defaults.baseURL +
              "vehicles/put/" +
              localStorage.getItem("selectedVehicle") +
              "/"
          )
          .then((response) => {
            tempVehicle = response.data;

            this.formatPlate();

            tempVehicle.tuvau_month = parseInt(this.vehicle.tuvau_month);
            tempVehicle.tuvau_year = this.vehicle.tuvau_year;
            tempVehicle.inspection_month = parseInt(this.vehicle.inspection_month);
            tempVehicle.inspection_year = this.vehicle.inspection_year;
            tempVehicle.last_updated = new Date().toLocaleString();
            tempVehicle.currentOwner = this.vehicle.currentOwner;
            tempVehicle.mileage = this.vehicle.mileage;
            tempVehicle.plate = this.vehicle.plate;
            tempVehicle.vehiclesToUser = localStorage.getItem("id");
            tempVehicle.bInHouse = this.vehicle.bInHouse;
            tempVehicle.purchasePrice = this.vehicle.purchasePrice || 0;
            tempVehicle.crashVehicle = this.vehicle.crashVehicle

            axios //authenticated get request
              .put(
                axios.defaults.baseURL +
                  "vehicles/put/" +
                  localStorage.getItem("selectedVehicle") +
                  "/",
                tempVehicle
              )
              .then(() => {
                setTimeout(() => {
                  this.bHasChanged = false;
                  this.loading = false;
                }, 750);
              })
              .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
                this.loading = false
              }
            })
          })
          .catch((error) => console.log(error));
    },
    startNewAssessment() {
      this.loading = true;
      var tempVehicle;

      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "vehicles/put/" +
            localStorage.getItem("selectedVehicle") +
            "/"
        )
        .then((response) => {
          tempVehicle = response.data;

          tempVehicle.last_updated = new Date().toLocaleString();
          tempVehicle.bFinishedReport = false;
          tempVehicle.bFinishedPricing = false;

          axios //authenticated get request
            .put(
              axios.defaults.baseURL +
                "vehicles/put/" +
                localStorage.getItem("selectedVehicle") +
                "/",
              tempVehicle
            )
            .then(() => {
              axios //authenticated get request
                .post(axios.defaults.baseURL + "reports/new", {
                  reportsToVehicle: localStorage.getItem("selectedVehicle"),
                  reportsToUser: localStorage.getItem("id"),
                })
                .then((response) => {
                  //post status collections
                  this.postConditions(response.data);

                  setTimeout(() => {
                    this.bAssessmentButtonDisabled = true;
                    this.loading = false;

                    this.$emit("input", true);
                  }, 750);
                });
            })
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
                this.loading = false
              }
            })
        })
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
                this.loading = false
              }
            })
    },
    changeInHouseStatus(val) {
      this.vehicle.bInHouse = val

      this.updateVehicle();
    },
    changeCrashVehicle(val) {
      if(val == false){
        this.notChangeablePopup = true;
      }
      else{
        this.vehicle.crashVehicle = true
        this.changePopUp = false;

        this.updateVehicle();
      }
    },
    postConditions(val) {
      axios //authenticated get request
        .post(axios.defaults.baseURL + "statusCollections/", {
          StatusToReport: val.id,
        })
        .then((res) => {
          axios //authenticated get request
            .get(axios.defaults.baseURL + "vehicleTypeByName/?name=" + this.vehicle.vehiclesToModel.vehicleManufacturerModelsToVehicleType.name)
            .then((response) => {
              var groups = response.data[0].vehicleTypesToVehiclePartGroups;

              for (var i = 0; i < groups.length; i++) {
                if (groups[i].name.includes("Bremse")) {
                  axios //authenticated get request
                    .post(axios.defaults.baseURL + "brakeConditions/", {
                      location: groups[i].id,
                      bIsFinished: false,
                      statusToBrakeConditions: res.data.id,
                    })
                    .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
                this.loading = false
              }
            })
                } else if (groups[i].name.includes("Fahrwerk")) {
                  axios //authenticated get request
                    .post(axios.defaults.baseURL + "suspensionConditions/", {
                      location: groups[i].id,
                      bIsFinished: false,
                      statusToSuspensionConditions: res.data.id,
                    })
                    .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
                this.loading = false
              }
            })
                } else if (groups[i].name.includes("Reifen")) {
                  axios //authenticated get request
                    .post(axios.defaults.baseURL + "tireConditions/", {
                      location: groups[i].id,
                      bIsFinished: false,
                      statusToTireConditions: res.data.id,
                    })
                    .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
                this.loading = false
              }
            })
                } else if (groups[i].name.includes("klappe")) {
                  axios //authenticated get request
                    .post(axios.defaults.baseURL + "toolConditions/", {
                      bIsFinished: false,
                      statusToToolConditions: res.data.id,
                    })
                    .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
                this.loading = false
              }
            })
                }
              }

              axios //authenticated get request
                .post(axios.defaults.baseURL + "baseConditions/", {
                  bIsFinished: false,
                  statusToBaseConditions: res.data.id,
                })
                .catch((error) => {
                  if (error.response == undefined) {
                    this.errorOn = true
                    this.loading = false
                  }
              })

              axios //authenticated get request
                .post(axios.defaults.baseURL + "paintConditions/", {
                  statusToPaintConditions: res.data.id,
                })
                .catch((error) => {
                  if (error.response == undefined) {
                    this.errorOn = true
                    this.loading = false
                  }
            })
            });
        })
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
                this.loading = false
              }
            })
    },
    formatPlate(){
      if(this.vehicle.plate && this.vehicle.plate.length > 0){
        var plateArray = this.vehicle.plate.split(/–| |-/)
        var result = plateArray[0] + "-"

        var i
        for(i = 1; i < plateArray.length; i++){
          result += plateArray[i] + " "
        }
        
        result = result.toUpperCase()
        this.vehicle.plate = result

        if(this.vehicle.plate.length > 10){
          this.vehicle.plate = this.vehicle.plate.substring(0, 9)
        }
      }
    },
    getPerm(val){
      var perm = localStorage.getItem("perm")
      if(perm <= parseInt(val))
        return true
      
      return false
    },
    getPermEqual(val){
      var perm = localStorage.getItem("perm")
      if(perm == parseInt(val) || perm == 1)
        return true
      
      return false
    },
  },

  mounted() {
    this.bHasChanged = false;
  },

  created() {
    localStorage.setItem("selectedVehicle", this.$route.params.vin);

    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "vehicles/" +
          localStorage.getItem("selectedVehicle") +
          "/"
      )
      .then((response) => {
        this.vehicle = response.data;

        this.formatPlate();

        if (this.vehicle.lastReport.created)
          this.bAssessmentButtonDisabled =
            !this.vehicle.bFinishedReport || !this.vehicle.bFinishedPricing;
        else this.bAssessmentButtonDisabled = false;

        this.bHasChanged = false;

        this.updateImageFiles();

        this.$forceUpdate();
      })
      .catch((error) => console.log(error));
  },
};
</script>