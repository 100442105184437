<template>
  <div id="suspensionCondition">
    <v-form ref="toolForm">
      <v-expansion-panels class="pa-0 ma-0" flat :value="panel">
        <v-expansion-panel expand @click="changePlusIcon()">
          <v-expansion-panel-header disable-icon-rotate class="px-9 py-0">
            <div class="statusPreIcon">
              <v-icon v-if="displayPlus" color="secondary">
                mdi-plus
              </v-icon>
              <v-icon v-else color="secondary">
                mdi-minus
              </v-icon>
              </div>
            <h4 height="32px">Statuserfassung</h4>
            <template v-slot:actions>
              <v-icon color="teal" v-if="bIsFinished == true">
                mdi-check
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid class="pa-0 ma-0 px-2">
              <v-row>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Bordwerkzeug</p>
                  <v-slider
                    v-model="werkzeug"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="validateBoolAndPush(werkzeug, 'bordwerkzeug')"
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Paletot</p>
                  <v-slider
                    v-model="paletot"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="validateBoolAndPush(paletot, 'paletot')"
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Ladegerät</p>
                  <v-slider
                    v-model="ladegeraet"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="validateBoolAndPush(ladegeraet, 'ladegeraet')"
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Reifenfüllkit</p>
                  <v-slider
                    v-model="reifenfuellkit"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(reifenfuellkit, 'reifenfuellkit')
                    "
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Warndreieck</p>
                  <v-slider
                    v-model="warndreieck"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(warndreieck, 'warndreieck')
                    "
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Verbandskasten</p>
                  <v-slider
                    v-model="verbandskasten"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(verbandskasten, 'verbandskasten')
                    "
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Radsatz 8-fach</p>
                  <v-slider
                    v-model="radsatz8"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="validateBoolAndPush(radsatz8, 'radsatz8')"
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Servicemappe</p>
                  <v-slider
                    v-model="servicemappe"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(servicemappe, 'servicemappe')
                    "
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Radiokarte</p>
                  <v-slider
                    v-model="radiokarte"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="validateBoolAndPush(radiokarte, 'radiokarte')"
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Garantieheft</p>
                  <v-slider
                    v-model="garantieheft"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(garantieheft, 'garantieheft')
                    "
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Elektronikkarte</p>
                  <v-slider
                    v-model="elektronikkarte"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(elektronikkarte, 'elektronikkarte')
                    "
                  ></v-slider>
                </v-col>
                <v-col :cols="calcCols">
                  <p class="pb-0 mb-0">Windschott</p>
                  <v-slider
                    v-model="windschott"
                    max="1"
                    min="-1"
                    value="0"
                    :rules="rules"
                    color="secondary"
                    track-color="secondary"
                    thumb-color="primary"
                    ticks="always"
                    :tick-labels="['ja', '', 'nein']"
                    v-on:change="
                      validateBoolAndPush(windschott, 'windschott')
                    "
                  ></v-slider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                      counter
                      outlined
                      rows="1"
                      label="Zusatzinformation"
                      :rules="textBoxRules"
                      :value="comment"
                      v-model="comment"
                      @change="addToolComment()"
                    ></v-textarea>
                </v-col>
              </v-row>
            </v-container>

            <v-divider />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>

    <errorPopup :on="errorOn" :data="'Statuserfassung Ausstattung'" />
  </div>
</template>

<script>
import errorPopup from "../ErrorPopup.vue";

const axios = require("axios");
export default {
  name: "toolCondition",
  props: ["partGroupID"],

  data() {
    return {
      errorOn: false, 

      toolConditionID: undefined,
      statusCollectionID: undefined,

      werkzeug: 0,
      paletot: 0,
      ladegeraet: 0,
      reifenfuellkit: 0,
      warndreieck: 0,
      verbandskasten: 0,
      radsatz8: 0,
      servicemappe: 0,
      radiokarte: 0,
      garantieheft: 0,
      windschott: 0,
      elektronikkarte: 0,
      comment: "",

      bIsFinished: false,
      panel: 0,
      displayPlus: false,

      rules: [(v) => v != 0],
      textBoxRules: [v => v==undefined || v==null || v.length < 100 || "Maximallänge 100 Zeichen!"],
    };
  },


 components: {
    errorPopup,
  },

  computed: {
    calcCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        case "lg":
          return 4;
        default:
          return 4;
      }
    },
  },

  methods: {
    pushToDB(val, valName) {
      axios //authenticated get request
        .put(
          axios.defaults.baseURL +
            "toolConditions/" +
            this.toolConditionID +
            "/",
          {
            [valName]: val,
            statusToToolConditions: this.statusCollectionID,
            location: this.partGroupID,
          }
        )
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
    },
    addToolComment(){
      if(this.comment.length > 100){
        this.comment = this.comment.substr(0, 100)
      }

      axios //authenticated get request
        .put(
          axios.defaults.baseURL +
            "toolConditions/" +
            this.toolConditionID +
            "/",
          {
            comment: this.comment,
            statusToToolConditions: this.statusCollectionID,
            location: this.partGroupID,
          }
        )
        .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
    },
    validateBoolAndPush(val, valName) {
      if (val != 0) {
        val == -1
          ? this.pushToDB(true, valName)
          : this.pushToDB(false, valName);
      }
    },
    initialise() {
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "simpleStatusColByVehicle/?vin=" +
            localStorage.getItem("selectedVehicle")
        )
        .then((response) => {
          this.statusCollectionID = response.data[0].id;
          this.toolConditionID = response.data[0].toolCollection[0].id;

          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "toolConditions/" +
                this.toolConditionID +
                "/"
            )
            .then((resp) => {
              if (resp.data.bordwerkzeug == true) this.werkzeug = -1;
              else if (resp.data.bordwerkzeug == false) this.werkzeug = 1;
              else this.werkzeug = 0;

              if (resp.data.paletot == true) this.paletot = -1;
              else if (resp.data.paletot == false) this.paletot = 1;
              else this.paletot = 0;

              if (resp.data.ladegeraet == true) this.ladegeraet = -1;
              else if (resp.data.ladegeraet == false) this.ladegeraet = 1;
              else this.ladegeraet = 0;

              if (resp.data.warndreieck == true) this.warndreieck = -1;
              else if (resp.data.warndreieck == false) this.warndreieck = 1;
              else this.warndreieck = 0;

              if (resp.data.verbandskasten == true) this.verbandskasten = -1;
              else if (resp.data.verbandskasten == false)
                this.verbandskasten = 1;
              else this.verbandskasten = 0;

              if (resp.data.garantieheft == true) this.garantieheft = -1;
              else if (resp.data.garantieheft == false) this.garantieheft = 1;
              else this.garantieheft = 0;

              if (resp.data.reifenfuellkit == true) this.reifenfuellkit = -1;
              else if (resp.data.reifenfuellkit == false)
                this.reifenfuellkit = 1;
              else this.reifenfuellkit = 0;

              if (resp.data.radsatz8 == true) this.radsatz8 = -1;
              else if (resp.data.radsatz8 == false) this.radsatz8 = 1;
              else this.radsatz8 = 0;

              if (resp.data.servicemappe == true) this.servicemappe = -1;
              else if (resp.data.servicemappe == false) this.servicemappe = 1;
              else this.servicemappe = 0;

              if (resp.data.radiokarte == true) this.radiokarte = -1;
              else if (resp.data.radiokarte == false) this.radiokarte = 1;
              else this.radiokarte = 0;

              if (resp.data.elektronikkarte == true) this.elektronikkarte = -1;
              else if (resp.data.elektronikkarte == false) this.elektronikkarte = 1;
              else this.elektronikkarte = 0;

              if (resp.data.windschott == true) this.windschott = -1;
              else if (resp.data.windschott == false) this.windschott = 1;
              else this.windschott = 0;

              this.comment = resp.data.comment
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    },
    changePlusIcon(){
      this.displayPlus = !this.displayPlus
    }
  },

  mounted() {
    this.$refs.toolForm.validate()
    this.initialise();
  },

  updated() {
    if (this.$refs.toolForm.validate() && this.bIsFinished != true) {
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "toolConditions/" +
            this.toolConditionID +
            "/"
        )
        .then(() => {
          axios //authenticated get request
            .put(
              axios.defaults.baseURL +
                "toolConditions/" +
                this.toolConditionID +
                "/",
              {
                statusToToolConditions: this.statusCollectionID,

                bordwerkzeug: this.bordwerkzeug == 1 ? false : true,
                paletot: this.paletot == 1 ? false : true,
                ladegeraet: this.ladegeraet == 1 ? false : true,
                reifenfuellkit: this.reifenfuellkit == 1 ? false : true,
                warndreieck: this.warndreieck == 1 ? false : true,
                verbandskasten: this.verbandskasten == 1 ? false : true,
                servicemappe: this.servicemappe == 1 ? false : true,
                radiokarte: this.radiokarte == 1 ? false : true,
                garantieheft: this.garantieheft == 1 ? false : true,
                radsatz8: this.radsatz8 == 1 ? false : true,
                elektronikkarte: this.elektronikkarte == 1 ? false : true,
                windschott: this.windschott == 1 ? false : (this.windschott == -1 ? true : undefined)
              }
            )
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true
              }
            })
        })
        .then(() => {
          setTimeout(() => {
          axios //authenticated get request
            .get(
              axios.defaults.baseURL +
                "toolConditions/" +
                this.toolConditionID +
                "/"
            )
            .then((resp) => {
              var finished = true

              if(resp.data.bordwerkzeug == null) finished = false
              if(resp.data.paletot == null) finished = false
              if(resp.data.ladegeraet == null) finished = false
              if(resp.data.reifenfuellkit == null) finished = false
              if(resp.data.warndreieck == null) finished = false
              if(resp.data.verbandskasten == null) finished = false
              if(resp.data.servicemappe == null) finished = false
              if(resp.data.radiokarte == null) finished = false
              if(resp.data.garantieheft == null) finished = false
              if(resp.data.radsatz8 == null) finished = false
              if(resp.data.elektronikkarte == null) finished = false

              if(finished == true){
                this.bIsFinished = true
                this.$emit("input", true)

                axios //authenticated get request
                .put(
                  axios.defaults.baseURL +
                    "toolConditions/" +
                    this.toolConditionID +
                    "/",
                  {
                    statusToToolConditions: this.statusCollectionID,
                    bIsFinished: true
                  }
                )
                .catch((error) => {
                  if (error.response == undefined) {
                    this.errorOn = true
                }})
              }
              }, 2000);
            })})
    }
  },
};
</script>