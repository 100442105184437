<template>
  <div id="damages" class="mx-6">
    <div>
      <v-card class="mx-auto" flat>
        <v-container fluid>
          <v-row>
            <v-col cols="2" style="display: flex; align-items: center">
              <b>Serviceleistung:</b>
            </v-col>
            <v-spacer />
            <v-spacer />
            <v-col cols="3" class="">
              <div
                style="display: flex; align-items: center"
                v-if="report && bIsEditable"
              >
                <v-text-field
                  type="number"
                  v-model="report.service"
                  label="Preis"
                  @input="patchServicePrice"
                ></v-text-field>
                <div>€</div>
              </div>
              <div
                style="display: flex; align-items: center"
                v-else-if="report && !bIsEditable"
              >
                <v-text-field
                  type="number"
                  v-model="report.service"
                  label="Preis"
                  @input="patchServicePrice"
                  disabled
                ></v-text-field>
                <div>€</div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <br />

    <!-- List -->
    <v-container fluid class="pa-0" v-if="!asIconList">
      <p v-if="!report || report.damages.length == 0">Keine Schäden vorhanden!</p>
      <v-divider />

      <v-container fluid class="pa-0">
        <v-row class="pa-0">
          <v-spacer />
          <v-col cols="2" class="px-2 pr-10 text-right"> Preis </v-col>
          <v-col cols="1" class="px-2"> Wird behoben </v-col>
          <v-col cols="1" class="px-2"> Behoben </v-col>
        </v-row>
      </v-container>
      <div v-if="report && report.damages">
        <div v-for="damage in report.damages" :key="damage.name">
          <v-row dense>
            <v-col cols="12">
              <v-card class="mx-auto" flat>
                <v-container fluid>
                  <v-row>
                    <v-col cols="2" v-if="showImg">
                      <v-img
                        :src="damage.imageFile"
                        max-height="6em"
                        min-height="6em"
                        class="rounded-lg"
                        @click="openInNewWindow(`${damage.imageFile}`)"
                      >
                      </v-img>
                    </v-col>
                    <v-col cols="2">
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div
                            v-if="
                              damage.damagesToVehicleParts.name !=
                              damage.damagesToComponent.name
                            "
                          >
                            {{ damage.damagesToPartGroup.name }} |
                            {{ damage.damagesToVehicleParts.name }} |
                            {{ damage.damagesToComponent.name }}
                          </div>
                          <div v-else>
                            {{ damage.damagesToPartGroup.name }} |
                            {{ damage.damagesToComponent.name }}
                          </div>
                          <h3 class="mb-3">
                            {{ damage.damagesToDamageType.name }}
                          </h3>
                          <v-list-item-subtitle v-if="damage.damageStageIndex == 0">
                            {{
                              damage.damagesToDamageType.damageTypesToDamageStage.small
                            }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else-if="damage.damageStageIndex == 1">
                            {{
                              damage.damagesToDamageType.damageTypesToDamageStage.medium
                            }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else>
                            {{
                              damage.damagesToDamageType.damageTypesToDamageStage.large
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col
                      cols="auto"
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                      "
                    >
                      <v-icon @click="saveImage(`${damage.imageFile}`)">
                        mdi-download
                      </v-icon>
                      <v-icon @click="openInNewWindow(`${damage.imageFile}`)">
                        mdi-crop-free
                      </v-icon>
                    </v-col>
                    <!-- </v-spacer> -->
                    <v-spacer />
                    <v-col cols="3" class="pt-12 text-right">
                      <v-icon
                        :disabled="!getPerm(3)"
                        v-if="bIsEditable"
                        @click="showOverlay(damage.id)"
                        x-large
                        >mdi-tools</v-icon
                      >
                      {{
                        damage.cost == null || damage.cost == undefined
                          ? "-"
                          : damage.cost
                      }}
                      €
                    </v-col>
                    <v-col cols="1" class="pt-12">
                      <v-flex class="checkboxFlex">
                        <v-checkbox
                          :disabled="!getPermEqual(4)"
                          v-model="damage.needFix"
                          @click="switchNeedFix(damage)"
                          class="shrink mr-0 mt-0"
                        >
                        </v-checkbox>
                      </v-flex>
                    </v-col>
                    <v-col cols="1" class="pt-12">
                      <v-flex class="checkboxFlex">
                        <v-checkbox
                          :disabled="!getPerm(3)"
                          v-model="damage.isFixed"
                          @click="switchIsFixed(damage)"
                          class="shrink mr-0 mt-0"
                        >
                        </v-checkbox>
                      </v-flex>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-divider />
        </div>
        <v-row dense>
          <v-col cols="12">
            <v-card class="mx-auto" flat>
              <v-container fluid>
                <v-row>
                  <v-col cols="2" v-if="showImg"> </v-col>
                  <v-col cols="2">
                    <v-list-item>
                      <v-list-item-content>
                        <div>
                          <h3>Zusatzreparatur</h3>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="5">
                    <v-textarea
                      outlined
                      counter="200"
                      v-model="additionalDamage.description"
                      :disabled="!bIsEditable"
                      v-if="showImg"
                      :rules="textareaRules"
                      class="customTextarea"
                      @blur="patchAdditionalDamage()"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col
                    cols="1"
                    class="pt-12 text-right"
                    v-if="this.additionalDamage && this.additionalDamage.id"
                  >
                    <v-icon
                      :disabled="!getPerm(3)"
                      v-if="bIsEditable"
                      x-large
                      @click="showOverlay(additionalDamage.id, true)"
                      >mdi-tools</v-icon
                    >
                    {{
                      this.additionalDamage.cost == null ||
                      this.additionalDamage.cost == undefined
                        ? "-"
                        : this.additionalDamage.cost
                    }}
                    €
                  </v-col>
                  <v-col
                    cols="2"
                    v-if="this.additionalDamage && this.additionalDamage.id"
                  ></v-col>
                  <v-spacer
                    v-if="!(this.additionalDamage && this.additionalDamage.id)"
                  ></v-spacer>
                  <v-col
                    cols="auto"
                    class="pt-12 pr-11"
                    v-if="!(this.additionalDamage && this.additionalDamage.id)"
                  >
                    <v-btn
                      color="primary"
                      @click="postAdditionalDamage()"
                      :disabled="!this.additionalDamage.description"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="!showImg">
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      counter="200"
                      v-model="additionalDamage.description"
                      :disabled="!bIsEditable"
                      :rules="textareaRules"
                      class="customTextarea"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-divider />
      </div>
      <v-row class="px-3">
        <v-col cols="8">
          <h2>Gesamtkosten</h2>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="text-right">
          <h2>{{ repairPrice }} €</h2>
        </v-col>
      </v-row>

      <v-footer padless app color="toolbar" height="65px">
        <v-col class="text-right" cols="12">
          <v-btn
            :disabled="pricingButtonDisabled"
            depressed
            large
            color="primary"
            elevation="2"
            @click="finishPricing"
            >Bepreisung abschließen</v-btn
          >
        </v-col>
      </v-footer>
    </v-container>

    <!-- Icons -->

    <v-container v-else fluid>
      <v-row :key="refreshcomp">
        <v-col v-for="damage in report.damages" :key="damage.id" :cols="damageCols">
          <v-card>
            <v-img
              class="white--text"
              :src="damage.imageFile"
              :height="imgHeight"
              @click="openInNewWindow(`${damage.imageFile}`)"
            >
            </v-img>

            <v-list-item three-line>
              <v-list-item-content>
                <div>
                  {{ damage.damagesToComponent.name }}
                </div>
                <v-list-item-title>
                  <h3 class="mb-3 text-no-wrap text-truncate">
                    {{ damage.damagesToDamageType.name }}
                  </h3>
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-no-wrap text-truncate"
                  v-if="damage.damageStageIndex == 0"
                >
                  {{ damage.damagesToDamageType.damageTypesToDamageStage.small }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="text-no-wrap text-truncate"
                  v-else-if="damage.damageStageIndex == 1"
                >
                  {{ damage.damagesToDamageType.damageTypesToDamageStage.medium }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-no-wrap text-truncate" v-else>
                  {{ damage.damagesToDamageType.damageTypesToDamageStage.large }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-container fluid id="damageIconBar">
              <v-row class="align-center" id="iconRow">
                <v-divider vertical></v-divider>
                <v-divider vertical></v-divider>

                <v-col class="pa-0 pl-2">
                  <v-btn
                    block
                    depressed
                    color="primary"
                    @click="
                      deleteDamage = damage;
                      dialog = true;
                    "
                  >
                    <v-icon id="damageIcon" color="black" medium
                      >mdi-trash-can-outline</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" :value="dialog" width="500" :retain-focus="false">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Schaden wirklich löschen?
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> Nein </v-btn>
            <v-btn color="primary" text @click="removeDamage()"> Ja </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row class="py-6">
        <v-spacer />
        <v-col cols="6">
          <v-footer padless app color="toolbar" height="65px">
            <v-col class="text-right" cols="12">
              <v-badge
                v-if="reportButtonDisabled"
                overlap
                icon="mdi-exclamation"
                color="red"
                id="exclamationIcon"
              >
                <v-btn
                  depressed
                  large
                  color="primary"
                  elevation="2"
                  :disabled="reportButtonDisabled"
                  >Bewertung abschließen
                </v-btn>
              </v-badge>

              <v-btn
                v-else
                depressed
                large
                color="primary"
                elevation="2"
                :disabled="reportButtonDisabled"
                @click="finishReport"
                >Bewertung abschließen
              </v-btn>
            </v-col>
          </v-footer>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>

    <v-overlay v-if="bShowPricingOverlay" :value="bShowPricingOverlay">
      <overlay
        v-model="bShowPricingOverlay"
        :title="'Preisanpassung'"
        :type="'price'"
        :data="selectedDamage"
        :items="[]"
      />
    </v-overlay>

    <v-overlay :value="loading" opacity="0.1">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>

    <errorPopup :on="errorOn" :data="'Schadenserfassung'" />
  </div>
</template>

<script>
import overlay from "../components/Overlays/Overlay.vue";

import errorPopup from "../components/ErrorPopup.vue";

const axios = require("axios");

export default {
  name: "damages",
  props: ["update", "reportButtonDisabled"],

  data() {
    return {
      errorOn: false,
      refreshcomp: 0,

      vin: localStorage.getItem("selectedVehicle"),
      report: undefined,
      bIsEditable: true,
      bShowPricingOverlay: false,
      selectedDamage: {
        id: undefined,
        last_updated: undefined,
        cost: undefined,
        isAdditional: undefined,
      },

      additionalDamage: {
        id: undefined,
        description: undefined,
        cost: undefined,
      },

      pricingButtonDisabled: true,
      loading: false,

      dialog: false,

      deleteDamage: undefined,

      textareaRules: [(v) => v == undefined || v.length <= 200 || "Maximal 200 Zeichen!"],
    };
  },

  watch: {
    bShowPricingOverlay: function (val) {
      if (!val) {
        axios //authenticated get request
          .get(axios.defaults.baseURL + "damageSearchByVehicle/?vin=" + this.vin)
          .then((response) => {
            this.report = response.data[0];

            if (this.report.additionalDamages[0]) {
              this.additionalDamage.cost = this.report.additionalDamages[0].cost;
            }
            this.pricingButtonDisabled = false;
            for (var i = 0; i < this.report.damages.length; i++) {
              if (
                this.report.damages[i].cost == null ||
                this.report.damages[i].cost == undefined
              )
                this.pricingButtonDisabled = true;
            }
          })
          .catch((error) => console.log(error));
      }
    },
    update: function () {
      axios //authenticated get request
        .get(axios.defaults.baseURL + "damageSearchByVehicle/?vin=" + this.vin)
        .then((response) => {
          this.report = response.data[0];
        })
        .catch((error) => console.log(error));
    },
  },

  components: {
    overlay,
    errorPopup,
  },

  computed: {
    damageCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 3;
      }
    },
    imgHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "150px";
        case "sm":
          return "175px";
        case "md":
          return "200px";
        case "lg":
          return "250px";
        case "xl":
          return "300px";
        default:
          return "300px";
      }
    },
    showImg() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        default:
          return true;
      }
    },
    repairPrice() {
      if (!this.report || !this.report.damages) return;

      var sum = 0;
      for (var i = 0; i < this.report.damages.length; i++) {
        if (!isNaN(parseInt(this.report.damages[i].cost)))
          sum += parseInt(this.report.damages[i].cost);
      }

      if (this.additionalDamage.cost) {
        sum += parseInt(this.additionalDamage.cost);
      }

      if (!isNaN(parseInt(this.report.service))) sum += parseInt(this.report.service);

      return sum;
    },
    asIconList() {
      if (this.$route.path == "/stock/vehicle/" + this.vin + "/damages") {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    patchServicePrice(val) {
      if (isNaN(parseInt(val))) return;

      axios
        .patch(axios.defaults.baseURL + "reports/" + this.report.id + "/", {
          id: this.report.id,
          service: val,
        })
        .then(() => {
          console.log("successful");
        })
        .catch((e) => {
          console.log("couldn't update service price for report ", this.report.id, e);
        });
    },
    goBack() {
      this.$router.push("/stock/vehicle/" + this.vin + "/overview");
    },
    showOverlay(val, isAdditional = false) {
      this.selectedDamage.id = val;

      if (isAdditional) {
        this.selectedDamage.isAdditional = true;
      } else {
        this.selectedDamage.isAdditional = false;
      }

      this.selectedDamage.cost = undefined;
      this.bShowPricingOverlay = !this.bShowPricingOverlay;
    },
    finishPricing() {
      this.loading = true;
      var tempVehicle;

      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "vehicles/put/" +
            localStorage.getItem("selectedVehicle") +
            "/"
        )
        .then((response) => {
          tempVehicle = response.data;

          tempVehicle.last_updated = new Date().toLocaleString();
          tempVehicle.bFinishedPricing = true;
          tempVehicle.repairPrice = this.repairPrice;

          axios //authenticated get request
            .put(
              axios.defaults.baseURL +
                "vehicles/put/" +
                localStorage.getItem("selectedVehicle") +
                "/",
              tempVehicle
            )
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.pricingButtonDisabled = true;
                this.$emit("input", true);
              }, 750);
            })
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true;
                this.loading = false;
              }
            });
        })
        .catch((error) => console.log(error));
    },
    finishReport() {
      this.loading = true;
      var tempVehicle;

      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "vehicles/put/" +
            localStorage.getItem("selectedVehicle") +
            "/"
        )
        .then((response) => {
          tempVehicle = response.data;

          tempVehicle.last_updated = new Date().toLocaleString();
          tempVehicle.bFinishedReport = true;

          axios //authenticated get request
            .put(
              axios.defaults.baseURL +
                "vehicles/put/" +
                localStorage.getItem("selectedVehicle") +
                "/",
              tempVehicle
            )
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.$emit("input", true);
              }, 750);
            })
            .catch((error) => {
              if (error.response == undefined) {
                this.errorOn = true;
                this.loading = false;
              }
            });
        })
        .catch((error) => console.log(error));
    },
    removeDamage() {
      if (this.deleteDamage) {
        this.loading = true;
        axios //authenticated get request
          .delete(axios.defaults.baseURL + "damages/" + this.deleteDamage.id)
          .then(() => {
            axios //authenticated get request
              .get(axios.defaults.baseURL + "damageSearchByVehicle/?vin=" + this.vin)
              .then((response) => {
                this.report.damages = response.data[0].damages;
                this.$forceUpdate();
                this.dialog = false;
                this.loading = false;
                this.deleteDamage = undefined;
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    },
    async openInNewWindow(url) {
      window.open(url, "_blank");
    },
    async saveImage(url) {
      //window.open(source, "_blank");
      await this.downloadImage(url);
    },
    async downloadImage(url) {
      var base64 = await axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) => Buffer.from(response.data, "binary").toString("base64"));
      var img = new Image();
      img.src = "data:image/jpeg;base64, " + base64;

      var fileLink = document.createElement("a");

      fileLink.href = img.src;

      var vin = this.$route.params.vin;
      fileLink.download = vin + "_Schaden.jpg";

      fileLink.click();
    },
    switchIsFixed(damage, isAdditional = false) {
      // damage.isFixed = !damage.isFixed

      if (damage.cost == null || damage.cost == undefined) {
        damage.cost = 0;
      }

      if (!isAdditional) {
        const data = {
          id: damage.id,
          last_updated: Date.now(),
          cost: damage.cost,
          isFixed: damage.isFixed,
          needFix: damage.needFix,
        };

        axios //authenticated get request
          .put(axios.defaults.baseURL + "damages/" + damage.id + "/", data);
      } else {
        const data = {
          id: damage.id,
          additionalDamage: damage.description,
          cost: damage.cost,
          isFixed: damage.isFixed,
          needFix: damage.needFix,
        };

        if (typeof data.id !== "undefined") {
          axios.put(
            axios.defaults.baseURL + "additionalDamages/" + damage.id + "/",
            data
          );
        } else {
          axios.post(axios.defaults.baseURL + "additionalDamages/", data);
        }
      }
    },
    switchNeedFix(damage, isAdditional = false) {
      // damage.isFixed = !damage.isFixed

      if (!isAdditional) {
        const data = {
          id: damage.id,
          last_updated: Date.now(),
          cost: damage.cost,
          isFixed: damage.isFixed,
          needFix: damage.needFix,
        };

        axios //authenticated get request
          .put(axios.defaults.baseURL + "damages/" + damage.id + "/", data);
      } else {
        const data = {
          id: damage.id,
          additionalDamage: damage.description,
          cost: damage.cost,
          isFixed: damage.isFixed,
          needFix: damage.needFix,
        };

        axios.put(axios.defaults.baseURL + "additionalDamages/" + damage.id + "/", data);
      }
    },
    getPerm(val) {
      var perm = localStorage.getItem("perm");
      if (perm <= parseInt(val)) return true;

      return false;
    },
    getPermEqual(val) {
      var perm = localStorage.getItem("perm");
      if (perm == parseInt(val) || perm == 1) return true;

      return false;
    },
    postAdditionalDamage() {
      axios
        .post(axios.defaults.baseURL + "additionalDamages/new/", {
          additionalDamagesToReport: this.report.id,
          description: this.additionalDamage.description,
        })
        .then((resp) => {
          this.additionalDamage = resp.data;
          console.log(resp);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    patchAdditionalDamage() {
      if (this.additionalDamage.id) {
        axios
          .patch(
            axios.defaults.baseURL +
              "additionalDamages/" +
              this.additionalDamage.id +
              "/",
            this.additionalDamage
          )
          .catch((error) => console.log(error));
      }
    },
  },

  mounted() {
    axios //authenticated get request
      .get(axios.defaults.baseURL + "damageSearchByVehicle/?vin=" + this.vin)
      .then((response) => {
        this.report = response.data[0];

        if (
          response.data[0] &&
          typeof response.data[0].additionalDamages[0] !== "undefined"
        ) {
          this.additionalDamage.id = response.data[0].additionalDamages[0].id;
          this.additionalDamage.description =
            response.data[0].additionalDamages[0].description;
          this.additionalDamage.cost = response.data[0].additionalDamages[0].cost;
        }

        axios //authenticated get request
          .get(axios.defaults.baseURL + "vehicles/" + this.vin + "/")
          .then((response) => {
            if (response.data.bFinishedPricing == true) this.bIsEditable = false;

            if (
              response.data.bFinishedPricing == true ||
              response.data.bFinishedReport == false
            )
              this.pricingButtonDisabled = true;
            else if (this.report.damages.length == 0) this.pricingButtonDisabled = false;
            else {
              this.pricingButtonDisabled = false;
              for (var i = 0; i < this.report.damages.length; i++) {
                if (this.report.damages[i].cost == 0) this.pricingButtonDisabled = true;
              }
            }
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  },
};
</script>
