<template>
  <div id="mainPage">
    <headerComponent :titleProp="pageTitle" :bHasSearchBar="false"/>

    <br>
    <mainNavBar v-model="pageTitle"/>
  </div>
</template>

<script>
import headerComponent from "../components/Header.vue"
import mainNavBar from "../components/MainNavBar.vue"

export default{
  name: "mainPage",
  
  components: {
      headerComponent,
      mainNavBar,
  },
  data() {
    return{
      pageTitle: "Stammdaten",
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },
};
</script>