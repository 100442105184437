<template>
  <div :class="marginHelper">
    <v-app-bar fixed app color="toolbar" flat>
      <v-toolbar color="toolbar" flat id="toolbar">
        <router-link to="/stock" tag="button" class="headerLink">
          <v-img
            id="headerImg"
            src="../assets/images/EberleinLogo.png"
            class="headerLink"
          ></v-img>
        </router-link>
        <v-toolbar-title class="font-weight-medium" id="headerText">{{
          titleProp
        }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <div v-if="bHasSearchBar && !twoLines" class="pt-8">
          <searchBar v-model="searchResults" />
        </div>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <p v-if="showName" class="my-3 mr-5 text-no-wrap text-truncate">
          Hallo, {{ name }}
        </p>
        <v-icon class="pr-3" id="logoutIcon" @click="logOut">mdi-logout</v-icon>
      </v-toolbar>
    </v-app-bar>

    <v-app-bar
      fixed
      app
      class="mt-14"
      color="toolbar"
      flat
      v-if="bHasSearchBar && twoLines"
    >
      <v-toolbar v-if="bHasSearchBar" color="toolbar" flat id="toolbar">
        <v-spacer></v-spacer>
        <div class="pt-5">
          <searchBar v-model="searchResults" />
        </div>

        <v-spacer></v-spacer>
      </v-toolbar>
    </v-app-bar>
  </div>
</template>

<script>
import searchBar from "./SearchBar.vue";

const axios = require("axios");

export default {
  name: "headerComponent",
  props: ["titleProp", "bHasSearchBar"],

  data() {
    return {
      name: localStorage.getItem("name"),
      searchResults: {},
    };
  },

  computed: {
    showName() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return true;
        default:
          return true;
      }
    },
    twoLines() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
    marginHelper() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          if (this.bHasSearchBar && this.twoLines) return "mb-16";
          else if (this.$route.path.search("/stock/model/") >= 0) return "mb-16";
          else return "mb-0";
        case "sm":
          if (this.bHasSearchBar && this.twoLines) return "mb-16";
          else if (this.$route.path.search("/stock/model/") >=0) return "mb-16";
          else return "mb-0";
        default:
          return "";
      }
    },
  },

  components: {
    searchBar,
  },
  methods: {
    logOut: function () {
      this.$store.dispatch("logoutUser");
    },
  },
  mounted() {
    
  if (this.$store.state.accessToken == null) {
      this.$store.dispatch("logoutUser");
      return
    }

    console.log(localStorage.getItem("mail"))

    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "users/search/?id=" +
          localStorage.getItem("mail")
      )
      .then((response) => {
        this.name =
          response.data[0].first_name + " " + response.data[0].last_name;
        localStorage.setItem(
          "name",
          response.data[0].first_name + " " + response.data[0].last_name
        );
        localStorage.setItem("id", response.data[0].id);
        localStorage.setItem("perm", response.data[0].permission);

        localStorage.setItem("data", response.data[0].is_staff);

        this.$forceUpdate();
      })
      .catch((error) => console.log(error));
  },
  updated() {
    const now = Math.floor(Date.now() / 1000);
      let accessToken = atob(this.$store.state.accessToken.split(".")[1]);
      if (JSON.parse(accessToken).exp < now) {
        this.$store.dispatch("logoutUser");
        window.location.reload();
        return
      }
    if (this.bHasSearchBar) {
      this.$emit("input", this.searchResults);
    }
    
  },
};
</script>
