<template>
  <div id="assistant">
    <vehicleNavBar v-model="updateTabs" />
  </div>
</template>

<script>
const axios = require("axios");
import vehicleNavBar from "../components/VehicleNavBar.vue";

export default {
  name: "assistant",

  data() {
    return {
      updateTabs: false,
    };
  },

  watch: {
    updateTabs: function (val) {
      if (val == true) {
        this.$emit("input", true);
        this.updateTabs = false;
      }
    },
  },

  components: {
    vehicleNavBar,
  },

  methods: {
    goBack() {
      this.$router.push("/stock/vehicle/" + this.vin + "/overview");
    },
  },

  mounted() {
    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "vehicles/" +
          localStorage.getItem("selectedVehicle") + "/"
      )
      .then((response) => {
        if (response.data.bFinishedPricing == true) this.bIsEditable = false;

        if (response.data.bFinishedReport == true) {
          this.$router.push(
            "/stock/vehicle/" +
              localStorage.getItem("selectedVehicle") +
              "/overview"
          );
          this.$emit("input", true);
        }
      })
      .catch((error) => console.log(error));
  },
};
</script>