<template>
  <div id="modelOverview">
    <v-container fluid class="pa-2">
      <v-row fluid>
        <p class="ml-8 mt-4" v-if="models.length == 0">
          Keine Einträge unter dieser Filteroption vorhanden!
        </p>

        <v-col
          :cols="amount"
          v-for="model in models"
          :key="model.name"
          class="pb-12 pt-2"
        >
          <modelCard
            @click.native="
              cardClick()
            "
            :name="model.name"
            :imageFile="model.imageFile"
            :count="model.count"
            v-model="currentModel"
            :needAssessment="model.needAssessmentCount > 0"
            :needPricing="model.needPricingCount > 0"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="loading" opacity="0.1">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import modelCard from "../components/ModelCard.vue";
const axios = require("axios");

export default {
  name: "modelOverview",
  props: [
    "bShowAllModels",
    "bShowPricingNeeded",
    "bShowAssessmentNeeded",
    "bShowFinished",
    "bShowInHouse",
    "bHasChanged",
  ],

  data() {
    return {
      models: {},
      currentModel: undefined,
      loading: false,
    };
  },

  watch: {
    bHasChanged: function () {
      this.loading = true;
      this.models = {};
      axios //authenticated get request
        .get(
          axios.defaults.baseURL +
            "model/list/?price=" +
            this.bShowPricingNeeded +
            "&report=" +
            this.bShowAssessmentNeeded +
            "&all=" +
            this.bShowAllModels +
            "&finished=" +
            this.bShowFinished +
            "&house=" +
            this.bShowInHouse
        )
        .then((response) => {
          this.models = response.data;

          setTimeout(() => {
            this.loading = false;
            this.$forceUpdate();
          }, 500);
          //this.loading = false
        });
    },
  },

  components: {
    modelCard,
  },

  computed: {
    amount() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 3;
      }
    },
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    cardClick() {
      this.$emit("input", this.currentModel);

      localStorage.setItem("selectedModel", this.currentModel);
      this.$router.push("/stock/model/" + this.currentModel);
    },
  },

  mounted() {
    this.loading = true;
    localStorage.setItem("selectedModel", "");
    axios //authenticated get request
      .get(
        axios.defaults.baseURL +
          "model/list/?price=" +
          this.bShowPricingNeeded +
          "&report=" +
          this.bShowAssessmentNeeded +
          "&all=" +
          this.bShowAllModels +
          "&finished=" +
          this.bShowFinished + 
          "&house=" +
          this.bShowInHouse
      )
      .then((response) => {
        this.models = response.data;
        this.loading = false;   
      });
  },
};
</script>